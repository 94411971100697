/** Dependencies **/
import React from "react";

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedDataVizTab } from "./../../../reducers/selectedDataVizTab";

/** Helpers **/
import { getPicto } from './../../../helpers/pictos';

function SERPTab ( props )
{
  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get current selected tab **/
  const selectedDataVizTab = useSelector( state => state.selectedDataVizTab.value );
  
  /** Get current SERP Trend **/
  const currentSERPTrend = props.currentSERPTrend;

  /** Get last values **/
  // get last month of current period
  let lastCurrentMonth = null;  
  if( currentSERPTrend )
    lastCurrentMonth = Math.max( ...Object.keys( currentSERPTrend ).map( value => 
      Math.max( ...currentSERPTrend[value].map( value => 
        value.y > 0 ? value.x : false
      ))
    ));

  // filter currentSERPTrend to keep last month
  let values = {};
  Object.keys( currentSERPTrend ).forEach( grpSnippet => 
  {
    let lastMonthValue = currentSERPTrend[grpSnippet].filter( value => value.x === lastCurrentMonth );
    if( lastMonthValue.length > 0 )
      values[grpSnippet] = Math.round( lastMonthValue[0].y )
  });

  return (
    <div className={selectedDataVizTab === 'serp'?'dataviz-tab serp on':'dataviz-tab serp'} onClick={ () => dispatch( updateSelectedDataVizTab( 'serp' ) ) }>
      <div className="title"><h4>composition de la SERP</h4></div>
      { Object.keys( currentSERPTrend ).length > 0 ?
        <div className="values-container">
          {values.SEO ?
            <div className="values-parts-container">
              <div className="values-part top">
                {getPicto( 'SEO', {"size": "1.1rem"} )}<h5>seo</h5>
              </div>
              <div className="values-part bottom">
                <div className="value">{new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1 }).format( values.SEO )}<span className="percent">%</span></div>
              </div>
            </div>
            :false
          }
          {values.Google ?
            <div className="values-parts-container">
              <div className="values-part top">
                {getPicto( 'Google', {"size": "1.1rem"} )}<h5>goog.</h5>
              </div>
              <div className="values-part bottom">
                <div className="value">{new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1 }).format( values.Google )}<span className="percent">%</span></div>
              </div>
            </div>
            :false
          }
          {values.Ads ?
            <div className="values-parts-container">
              <div className="values-part top">
                {getPicto( 'Ads', {"size": "1.1rem"} )}<h5>ads</h5>
              </div>
              <div className="values-part bottom">
                <div className="value">{new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1 }).format( values.Ads )}<span className="percent">%</span></div>
              </div>
            </div>
            :false
          }
          {values.Snippets ?
            <div className="values-parts-container">
              <div className="values-part top">
                {getPicto( 'Snippets', {"size": "1.1rem"} )}<h5>snipp.</h5>
              </div>
              <div className="values-part bottom">
                <div className="value">{new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1 }).format( values.Snippets )}<span className="percent">%</span></div>
              </div>
            </div>
            :false
          }
        </div>
        :false
      }
    </div>      
  )
}

export default SERPTab;