/** Dependencies **/
import React, {useState, useRef} from 'react';
import ReactTooltip from 'react-tooltip';

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';

/** Components **/
import Loader from './../../Loader/Loader';

/** Helpers **/
import { callWebservice } from './../../../helpers/webservice/webserviceCaller';

function PageTypesHistory( props ) 
{
	/** Get props */
	const{
		id,
		label
	} = props;
	
	/** Define refs */
	const tooltipPageTypesHistory = useRef( null );

	/** Get state from redux store **/
  const selectedInstance = useSelector( state => state.selectedInstance.value );
	const instanceConfig = useSelector( state => state.config.value );

  /** Init state **/
	const [history, setHistory] = useState( null );

  /** Instance dispatch object **/
	const dispatch = useDispatch();

	/** Get page types datas from config file */
  const pageTypes = instanceConfig.pageTypes;

	/**
	 * Return data for keyword 12 months trend
	 * @param {string} keyword 
	 * @param {int} id 
	 * @returns data for trend
	 */
	const loadPageTypes = () => 
	{
		if( label.trim() !== '' )
		{
			callWebservice(
				id,
				id,
				'get-page-type',
				dispatch,
				selectedInstance,
				{
					urls: [label]					
				},
				{ function: 'setHistory' }
			);
		}
	}

	return (
		<ReactTooltip 
			id={id}
			ref={tooltipPageTypesHistory}
			className="tooltip grey"
			effect='solid'
			delayHide={100}
			delayShow={0}
			delayUpdate={100}
			afterShow={loadPageTypes}
			afterHide={() => {
				tooltipPageTypesHistory.current.tooltipRef.style.left = null;
				tooltipPageTypesHistory.current.tooltipRef.style.top = null;
			}}
			globalEventOff={'click'}
			isCapture={true}
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        // get width of tooltip
        let width = node.offsetWidth;
        
        return {
          left: - width / 2 + 275,
          top: -70
        };
      }}
		>
			<div className='tooltip-container history'>
				<Loader 
					loaderID={id}
					loaderStyle={{width:'15', stroke:'#e43e21'}} 
					callBackFcts={{
						setHistory: setHistory
					}}
					globalCallBack={undefined} 
				/>
				<h4>Historique des changements de type</h4>
				{						
					(
						history !== null 
						&& history[label]
					) ?
						<ul>
							{
								history[label][0].date !== null ?
									history[label].map( ( history, index ) => 
										<li key={index}>
											{history.date.toString().substring( 6, 8 )} / {history.date.toString().substring( 4, 6 )}  / {history.date.toString().substring( 0, 4 )} 
											&nbsp;-&nbsp;{history.user_first_name} {history.user_last_name} 
											&nbsp;:&nbsp;{
												history.updated_pagetype_id === null ? 
													'À définir'
													: pageTypes.filter( pageType => parseInt( pageType.id ) === parseInt( history.updated_pagetype_id ) )[0]['label']
											}
										</li>
									)
									: false
							}
							{
								history[label][0].automatic_pagetype_id !== null ? 
									<React.Fragment>										
										{history[label][0].date !== null ? <br /> : false}
										<li>Type de Page suggéré : { pageTypes.filter( pageType => parseInt( pageType.id ) === parseInt( history[label][0].automatic_pagetype_id ) )[0]['label'] }</li>
									</React.Fragment>
									: false
							}
						</ul>
					: <ul>
							<li>Aucun type de page enregistré</li>
						</ul>
				}				
			</div>
		</ReactTooltip>
	);
}

export default PageTypesHistory;