/** Dependencies **/
import React from "react";
import ReactTooltip from 'react-tooltip';

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedHeaderFilters } from './../../../reducers/selectedHeaderFilters';

/** Components **/
import DefaultSortToolTip from './../SortToolTips/DefaultSortToolTip';
import RangeFilter from './Filters/RangeFilter';

function RangeFilterToolTip ( props )
{
  /** Get props **/
  const id = props.column.id;
  const title = props.column.filterTitle;

  /** Get state from redux store **/
  const appPathName = useSelector( state => state.appPathName.value );

  /** Instance dispatch object **/
  const dispatch = useDispatch();
  
  /** Set preselect filters and key to access to data **/
  let preselectFilters = [];

  // modify id value if starts with snippets
  let currentID = id;
  if( id.startsWith( 'snippets' ) )
    currentID = 'snippets';

  switch ( currentID ) {
    case 'volume':
      preselectFilters = [
        {value: '0|9', label: 'moins de 10'},
        {value: '10|100', label: '10 ▶︎ 100'},
        {value: '100|1000', label: '100 ▶︎ 1k'},
        {value: '1000|10000', label: '1k ▶︎ 10k'},
        {value: '10000|100000', label: '10k ▶︎ 100k'},
        {value: '100000', label: 'plus de 100k'}      
      ];
      break;
    case 'snippets':
      preselectFilters = [
        {value: '10|100', label: '>= 10%' },
        {value: '25|100', label: '>= 25%' },
        {value: '50|100', label: '>= 50%' }
      ];
      break;
    case 'impressions':    
      preselectFilters = [
        {value: '0|100', label: '0 ▶︎ 100'},
        {value: '101|1000', label: '100 ▶︎ 1k'},
        {value: '1001|10000', label: '1k ▶︎ 10k'},
        {value: '10001', label: 'supérieur à +10k'}
      ];
      break;
    case 'clicks':    
      preselectFilters = [
        {value: '0|10', label: '0 ▶︎ 10'},
        {value: '11|50', label: '10 ▶︎ 50'},
        {value: '51|100', label: '50 ▶︎ 100'},
        {value: '101|500', label: '100 ▶︎ 500'},
        {value: '501|1000', label: '500 ▶︎ 1000'},
        {value: '1001', label: 'supérieur à +1000'}
      ];
      break;
    case 'ctr':
      preselectFilters = [
        {value: '1', label: '>= 1%' },
        {value: '5', label: '>= 5%' },
        {value: '10', label: '>= 10%' },
        {value: '25', label: '>= 25%' },
        {value: '33', label: '>= 33%' },
        {value: '50', label: '>= 50%' }  
      ];
      break;
    case 'position':
      preselectFilters = [
        {value: '1|3', label: '<= 3' },
        {value: '1|5', label: '<= 5' },
        {value: '1|10', label: '<= 10' },
        {value: '1|20', label: '<= 20' },
        {value: '1|30', label: '<= 30' }
      ];
      break;
      case 'kdifficulty':
        preselectFilters = [
          {value: '10|100', label: '>= 10%' },
          {value: '25|100', label: '>= 25%' },
          {value: '50|100', label: '>= 50%' }
        ];
        break;
    default:
      break;
  }

  return (
    <ReactTooltip 
      id={'filter-'+id}
      className="tooltip red"
      effect='solid'
      delayHide={100}
      delayShow={100}
      delayUpdate={500}
      globalEventOff='click'
      arrowColor="#e43e21"
      offset={id === 'gsc.position' || id === 'gsc.ctr' || id === 'kdifficulty' ? {left: 300} : {}}
      isCapture={true}
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        // get width of tooltip
        let width = node.offsetWidth;

        // set offset
        let offsetX = 0;
        if( 
          appPathName === 'keywords' 
          && id === 'gsc.position' 
        )
          offsetX = -80;
        else if( 
          appPathName === 'keywords'
          && id === 'kdifficulty'
        )
          offsetX = -136;
        else if( 
          appPathName === 'categories'
          && id === 'gsc.ctr'
        )
          offsetX = -114;
        else if( 
          appPathName === 'categories'
          && id === 'gsc.position'
        )
          offsetX = -237;
        else if( 
          appPathName === 'urls'
          && id === 'gsc.position'
        )
          offsetX = -80;
        
        return {
          left: currentTarget.offsetLeft - (width / 2) + offsetX,
          top: 17
        };
      }}
    >
      <div className="filter-container">
        <DefaultSortToolTip column={props.column} id={id} />  
        <div className="filters">
          <div className="title"><h4>Filtrer</h4></div>
          <div className="filter range">              
            <RangeFilter 
              {...props} 
              title={title}
              fieldName={ 'range-' + id } 
              typeFilter={ 'range' }
              preselectFilters={preselectFilters} 
              unit={ id === 'ctr' ? '%' : '' }
            /> 
          </div>      
          <div className="confirm-container">
            <button className="reset" onClick={ () => dispatch( updateSelectedHeaderFilters( { filterName: id, value: null } ) )}>annuler</button>
          </div>
        </div>        
      </div>
    </ReactTooltip>
  )
}

export default RangeFilterToolTip;