/** Components **/
import DefaultRangeFilterToolTip from './../../../components/Table/FilterToolTips/DefaultRangeFilterToolTip';
import TableHeaderTitleWithPicto from './../../../components/Table/TableHeaders/TitleWithPicto';
import DefaultSumTotalCell from './../../../components/Table/TableTotalCells/DefaultSumTotalCell';
import TableCellVolume from './../../../components/Table/TableCells/Volume';

export const VolumeColumn = ( appPathName, widthColumns ) => 
{
  return {
    accessor: "volume",
    width: widthColumns['volume'],
    title: "volume",
    className: "col-volume",
    Filter: DefaultRangeFilterToolTip,
    filter: 'multiFieldsCustomFilter',
    filterTitle: 'volumes de recherche',    
    sortDescFirst: true,
    sortType: 'valueSort',
    Header: props =>
      <TableHeaderTitleWithPicto 
        {...props.getToggleAllPageRowsSelectedProps()} 
        id={props.column.id} 
        title={props.column.title} 
      />,
    Total: props => 
      <DefaultSumTotalCell 
        id={props.column.id} 
      />,
    Cell: props =>
      <TableCellVolume 
        id={props.row.id} 
        keywords={
          appPathName === 'urls' ?
            props.row.original['keywords-assigned']
            : props.row.original.keywords
        } 
        context={appPathName}
        values={props.value} 
      />
  }
}