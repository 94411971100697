/** Dependencies **/
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

/** Redux **/
import { useSelector } from 'react-redux';

/** Components **/
import ExpectedURLAdminAddKeywords from './../TableTooltips/ExpectedURLAdminAddKeywords';

/** Helpers **/
import { getStylesSelectMenu } from './../../../helpers/ui';

/** JSON */
import globalConfig from './../../../assets/json/config.json';

function Actions( props ) 
{
  /** Get props */
  const {
    id,
    setDecisionsToParentFct,
    resetField,
    resetFieldFct
  } = props;  

  /** Init state **/
  const [action, setAction] = useState( null );
  const [expectedUrl, setExpectedUrl] = useState( { value: null, type: null } );

  /** Get state from redux store **/
  const appPathName = useSelector( state => state.appPathName.value );  
  
  /** Get actions datas from config file */
  const actions = globalConfig.barometer.action[appPathName];

  /** Loading options table actions from redux values **/
  let optionsTableAction = [];
  if( actions instanceof Array )
  {
    actions.forEach( action => {
      optionsTableAction.push({
        value: action.value,
        label: action.label.toUpperCase()
      })
    });
  }

  /** Set decisions values for parent */
  useEffect( () => 
  {
    const decisionsValues = {
      action: action,
      expectedUrl: { value: expectedUrl.value, type: expectedUrl.type }
    }
    setDecisionsToParentFct( decisionsValues );

  }, [
    action,
    expectedUrl
  ])

  /** Reset input value */
  useEffect( () => 
  {
    // reset action and expected url
    setAction( null );
    setExpectedUrl( { value: null, type: null } );

    // set reset field to false
    resetFieldFct( false );
        
  }, [ resetField ] );

  return (
    <div className="action-container pointer">

      <div
        data-tip={'actionsHistory-' + id} 
        data-for={'actionsHistory-' + id} 
        data-arrow-color="#e43e21" 
        data-place="left"
      >
        <Select 
          className="react-select"
          classNamePrefix="react-select"
          options={optionsTableAction}
          value={ action }
          styles={getStylesSelectMenu()}
          placeholder={"Pour action"}
          components={{
            IndicatorSeparator: () => null            
          }}
          onChange={ value => setAction( value ) }
          isClearable
        />
      </div>
      
      <div
        className='expectedUrl-container'
        data-tip={'expectedUrl-' + id} 
        data-for={'expectedUrl-' + id} 
        data-arrow-color="#e43e21" 
        data-place="left"
      >
        <span 
          className={ 
            expectedUrl.value === null ? 
              'expectedUrl-selector off' 
              : 'expectedUrl-selector' 
          } 
          title={expectedUrl.value !== null ? expectedUrl.value : 'URL à renseigner' }
        >
          {expectedUrl.value === null ? 
            'URL à renseigner' 
            : expectedUrl.value.slice( -1 ) === '/' ? 
                <React.Fragment>{expectedUrl.value.slice( 0, -1 )}<span className="bidi-override">/</span></React.Fragment>
                : expectedUrl.value
            + ( expectedUrl.type === 'newurl' ? ' ( N )' : '' )
          }
        </span> 
        <ExpectedURLAdminAddKeywords 
          id={'expectedUrl-' + id} 
          expectedUrl={expectedUrl.value}
          typeExpectedUrl={expectedUrl.type}
          setExpectedUrlToParentFct={setExpectedUrl}
        />
      </div>
    </div>
  );
}

export default Actions;