/** Dependencies **/
import React from "react";

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedHeaderFilters } from './../../../../reducers/selectedHeaderFilters';

/**
 * Define a default UI for Range Fitler
 * @param {object} {column: { filterValue, preFilteredRows, setFilter }...}
 * @returns ReactDOM with input
 */
function SelectFilter ( props )
{
  /** Get props **/
  const title = props.title;
  const fieldName = props.fieldName;
  const typeFilter = props.typeFilter;
  const optionsValues = props.optionsValues;
  const translateLabels = props.translateLabels;
  const id = props.column.id;  

  /** Instance dispatch object **/
  const dispatch = useDispatch();

  /** Get state from redux store **/
  const selectedHeaderFilters = useSelector( state => state.selectedHeaderFilters.value );

  /** Valid filter  **/
  const updateFilter = ( event ) => 
  {
    const value = event.currentTarget.value;
    const label = event.currentTarget.textContent;

    // deep copy of selectedHeaderFilter
    const selectedFilters = JSON.parse( JSON.stringify( selectedHeaderFilters ) );

    let payload = { 
      value: { 
        result: [value], 
        type: typeFilter,
        field: fieldName 
      }, 
      label: label, 
      type: 'text' 
    };

    if( selectedFilters[id] )
    {
      // get current selected header filter with fieldName    
      let currentSelectedHeaderFilterSnippet = selectedFilters[id].filter( filter => 
        filter.value.field === fieldName
      );

      if( currentSelectedHeaderFilterSnippet.length === 1 )
      {
        // get current array values
        let currentValues = currentSelectedHeaderFilterSnippet[0].value.result;

        // set payload.value.result
        if( !currentValues.includes( value ) )
          payload.value.result = [...currentValues, value];
        else {
          currentValues.splice( currentValues.indexOf( value ), 1 );
          payload.value.result = [...currentValues];
        } 

        // set payload.label
        if( payload.value.result.length > 1 )
          payload.label = fieldName;
        else
          payload.label = label;

        // set value to null if no current snippet filter selected        
        if( payload.value.result.length === 0 )
          payload = { value: null, field: fieldName };
      }
    }

    // save value into redux store
    dispatch( updateSelectedHeaderFilters( { filterName: id, value: payload } ));   
  }

  return (
    <React.Fragment>
      { title !== '' ? <h5>{title}</h5> : '' }
      <ul className="preselect">
        {optionsValues.map( ( option, i ) => (
          <li key={i}>
            <button 
              className={
                (
                  selectedHeaderFilters[id] 
                  && selectedHeaderFilters[id].filter( selectedFilter => 
                    selectedFilter.value.field === fieldName
                    && selectedFilter.value.result
                    && selectedFilter.value.result.includes( option.value ) 
                  ).length > 0
                ) ? "on" : ""
              } 
              onClick={ e => updateFilter( e ) } 
              value={option.value}
            >{ 
              translateLabels !== undefined 
              && translateLabels[option.label]?.label ? 
                translateLabels[option.label].label 
                : option.label 
            }</button>
          </li>
        ))}
      </ul>
    </React.Fragment>
  )
}

export default SelectFilter;