/** Dependencies **/
import React, { useState, useRef, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';

/** Components **/
import Loader from './../../Loader/Loader';

/** Helpers */
import { callWebservice } from './../../../helpers/webservice/webserviceCaller';
import {
  getSuggestionValue,
	onSuggestionsFetchRequested,
	renderSuggestion
} from './../../../helpers/autosuggest';

/** SCSS **/
import './Keywords.scss';

function Keywords( props ) 
{
  const {
    setKeywordToParentFct,
    resetField,
    resetFieldFct
  } = props;

  // Define ref
  const inputElement = useRef( null );

  /** Init state */
  const [inputValue, setInputValue] = useState( '' );
  const [suggestions, setSuggestions] = useState( [] );
  const [gscKeywords, setGscKeywords] = useState( [] );

  /** Get state from redux store **/
  const datas = useSelector( state => state.datas.value );
  const selectedFilters = useSelector( state => state.selectedFilters.value );
  const selectedInstance = useSelector( state => state.selectedInstance.value );

  /** Instance dispatch object **/
  const dispatch = useDispatch();

  /** Return gsc keywords to auto suggestion */
	const fetchRequestedGscKeywords = value => 
	{
    // filter on keyword wich are not already followed
		return gscKeywords.filter( keyword => 
      keyword.includes( value ) 
    ).filter( keyword => 
      !datas.globalResult.map( result => result.label ).includes( keyword ) 
    ).slice( 0, 10 );
	}

  /**
	 * Load GSC urls
	 */
	const loadGscUrls = () => 
	{
		// GSC
    if( selectedFilters.devLoc !== undefined )
		{  
			// GSC current details
			callWebservice(
				'add-keyword',
				'add-keyword-gscKeywords',
				'gsc',
				dispatch,
				selectedInstance,
				{
					cols: ["DISTINCT keyword"],
					where: {
						locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
						devices: [selectedFilters.devLoc.deviceValue]
					}
				},
				{ function: 'setGscKeywords' }
			);
		} else
			setGscKeywords( [] );
	}

  // set Autosuggest input props
  const inputProps = {
    placeholder: 'Nouveau mot-clé',
    value: inputValue || '',
    type: 'text',    
    onChange: ( e, { newValue } ) => setInputValue( newValue )
  };

  /** Load GSC */
  useEffect( () => loadGscUrls(), [ selectedFilters.devLoc ] );

  /** Set input value */
  useEffect( () => setKeywordToParentFct( inputValue ), [ inputValue ] );

  /** Reset input value */
  useEffect( () => 
  {
    // reset input value
    setInputValue( '' );

    // set reset field to false
    resetFieldFct( false );
        
  }, [ resetField ] );

  return (
    <div className="keyword">
      <Loader 
        loaderID={'add-keyword'} 
        loaderStyle={{ width: '15', stroke: '#e43e21' }} 
        callBackFcts={{
          setGscKeywords: results => setGscKeywords( results.map( result => result.keyword ) )
        }}
        globalCallBack={ undefined } 
      /> 
      <div className="label-container">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={ values => onSuggestionsFetchRequested( values, fetchRequestedGscKeywords, setSuggestions ) }
          alwaysRenderSuggestions={false}
          getSuggestionValue={ suggestion => getSuggestionValue( suggestion ) }
          renderSuggestion={ suggestions => renderSuggestion( suggestions, inputValue ) }
          inputProps={inputProps}
          ref={inputElement}
        />
      </div>
    </div>
  );
}

export default Keywords;