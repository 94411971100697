/** Dependencies **/
import React, {useState, useEffect} from 'react';
import Select, {components} from 'react-select';

/** Class object */
import wsActions from './../../../helpers/webservice/wsActions.class.js';

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { updateDatas } from './../../../reducers/datas';
import { updateSelectedHeaderFilters } from './../../../reducers/selectedHeaderFilters';
import { updateLoadersTriggers } from './../../../reducers/loadersTriggers';

/** Helpers **/
import { getCurrentDate } from './../../../helpers/functions';
import { getStylesSelectMenu } from './../../../helpers/ui';
import { getPicto } from './../../../helpers/pictos';
import { getItem } from './../../../services/LocaleStorage';
import { updateCategoriesState, updateActionState } from './../../../helpers/barometer/table';

/** Components **/
import ActionsHistory from './../TableTooltips/ActionsHistory';
import CommentsHistory from './../TableTooltips/CommentsHistory';
import ExpectedURL from './../TableTooltips/ExpectedURL';
import Loader from './../../Loader/Loader';

/** JSON */
import globalConfig from './../../../assets/json/config.json';

function Actions( props ) 
{
  /** Get props */
  const {
    id,
    label,
    keywords,
    automaticActionID,
    updatedActionID,
    lastUpdate,
    usersIDs,
    comments,
    automaticExpectedUrl,
    updatedExpectedUrl,
    typeExpectedUrl
  } = props;  

  let {
    categories
  } = props;

  /** Define action loader ID */
  const actionLoaderID = "action" + id;

  /** Init state **/
  const [updatedActionSelectedID, setUpdatedActionSelectedID] = useState( updatedActionID );
  const [actionSelectedLastUpdate, setActionSelectedLastUpdate] = useState( null );
  const [actionSelectedUsersIDs, setActionSelectedUsersIDs] = useState( null );

  /** Instance dispatch object **/
	const dispatch = useDispatch();
  
  /** Get state from redux store */
  const datas = useSelector( state => state.datas.value );
  const appPathName = useSelector( state => state.appPathName.value );  
  const selectedFilters = useSelector( state => state.selectedFilters.value );
  const selectedInstance = useSelector( state => state.selectedInstance.value );

  /** Get actions datas from config file */
  const actions = globalConfig.barometer.action[appPathName];

  /** Get current date */
  const currentDate = getCurrentDate( "YYYYMMDDHHmmss" );

  /** Init categories with N.C. if undefined */
  if( categories[0] === undefined )
    categories = [ 'N.C.', 'N.C.', 'N.C.' ];

  /** Loading options table actions from redux values **/
  let optionsTableAction = [];
  if( actions instanceof Array )
  {
    actions.forEach( action => {
      optionsTableAction.push({
        value: action.value,
        label: action.label.toUpperCase()
      })
    });
  }

  /** Get current expected url */
  const currentExpectedUrl = updatedExpectedUrl !== null ?
    updatedExpectedUrl
  : automaticExpectedUrl !== null ?
    automaticExpectedUrl
  : null;

  /** Instance Webservice Actions class */
  const wsActionsClass = new wsActions(
    selectedFilters,
    actionLoaderID,
    datas.globalResult,
    dispatch,
    selectedInstance
  );

  /** Call function when status select change **/
  const handleChange = ( values ) => 
  {
    if( updatedActionID !== parseInt( values.value ) )
    {
      /** Update Status of current keyword */
      wsActionsClass.addOrUpdateActions(
        [{
          label: label,
          actionSelectedLastUpdate: actionSelectedLastUpdate,
          actionSelectedUsersIDs: actionSelectedUsersIDs
        }],
        values.value !== null ? parseInt( values.value ) : null,
        appPathName === 'admin-add-keywords' ? 'keywords': appPathName
      );

      /** Actions after updating status of current keywords FOR keyword and gsc context */
      if( 
        appPathName === 'keywords' 
        || appPathName === 'gsc-keywords'
      ){
        // If New status
        if( updatedActionID === null )
          wsActionsClass.actionsForNewStatus( 
            values.value, 
            label, 
            categories,
            actionSelectedLastUpdate,
            actionSelectedUsersIDs
          );

        // If Update status
        else
          wsActionsClass.actionsForUpdateStatus( 
            values.value,
            updatedActionID,
            label,
            categories,
            actionSelectedLastUpdate,
            actionSelectedUsersIDs
          );
      }
    }
  }

  /**
   * Dispatch filter on expected url with current expected url and relocate to url page
   */
  const filterOnUrl = ( url ) => 
  {
    // apply filter on expected urls
    if( url !== null )
    {
      dispatch( 
        updateSelectedHeaderFilters( { 
          filterName: 'action',
          value: {
            type: 'text',
            label: url,
            option: 'exactly',
            usefor: 'filter',
            value: {
              field: 'action-text',
              type: 'string-url-expected',
              result: url
            } 
          }
        }) 
      ); 
    }
  }  

  /** Set callback functions */
  const globalCallBack = ( results, datas ) => 
  {
    let updatedDatas = datas;

    // get actions
    const actionsValues = actions.map( action => parseInt( action.value ) );

    // get updated datas with new action states    
    const categoryActionState = results.find( result => actionsValues.includes( result.action_id ) );    
    if( categoryActionState !== undefined )
      updatedDatas = updateActionState( categoryActionState, updatedDatas );

    // get updated datas with new categories
    const categoryResult = results.find( result => result.categories !== undefined );
    if( categoryResult !== undefined )
      updatedDatas = updateCategoriesState( categoryResult, updatedDatas );

    dispatch( updateDatas( { 'globalResult': updatedDatas } ) );

    // update categories if gsc keywords context
    if( appPathName === 'gsc-keywords' )
      dispatch( updateLoadersTriggers( 'catFilter' ) );
  }

  // update menu if mass action used 
  useEffect(() => 
  {    
    // update action ID
    if( updatedActionID !== undefined )
      setUpdatedActionSelectedID( updatedActionID );

    // update action date
    if( lastUpdate !== undefined )
      setActionSelectedLastUpdate( lastUpdate );

    // update list users ids
    if( usersIDs !== undefined )
      setActionSelectedUsersIDs( usersIDs );

  }, [props]);

  return (
    <div className="action-container pointer">

      <Loader 
        loaderID={ actionLoaderID } 
        loaderStyle={{ width:'15', stroke:'#e43e21' }}
        globalCallBack={ results => globalCallBack( results, datas.globalResult ) } 
      />

      <div
        data-tip={'actionsHistory-' + id} 
        data-for={'actionsHistory-' + id} 
        data-arrow-color="#e43e21" 
        data-place="left"
      >
        <Select 
          className="react-select"
          classNamePrefix="react-select"
          options={optionsTableAction}
          styles={getStylesSelectMenu()}
          placeholder={"Pour action"}
          value={
            updatedActionSelectedID !== null ?
              optionsTableAction.filter( int => parseInt( int.value ) === updatedActionSelectedID )
            : automaticActionID !== null ?
              optionsTableAction.filter( int => parseInt( int.value ) === automaticActionID )
            : null
          }
          components={{
            IndicatorSeparator: () => null,
            Option: props => 
              <components.Option {...props}>
                {props.data.label}
                {
                  props.data.value
                  && parseInt( props.data.value ) === automaticActionID ? 
                    getPicto( 'Gear', { size: '1rem' } ) 
                  : false
                }
              </components.Option>,
            ValueContainer: ({ children, ...props }) => 
              <components.ValueContainer {...props}>
                {children}
                <div className='picto'>
                  {
                    updatedActionSelectedID !== null ?
                      getPicto( 'GenderNeutral', { width: '1.1rem', height: '1.1rem', fill: 'white' } ) 
                    : automaticActionID !== null ?
                      getPicto( 'Gear', { size: '0.8rem' } ) 
                    : false
                  }
                </div>
              </components.ValueContainer>
          }}
          onChange={ ( values ) => handleChange( values ) }
        />
        <ActionsHistory 
          id={'actionsHistory-' + id} 
          label={label} 
        />
      </div>
      
      {
        appPathName !== 'gsc-keywords'
        && appPathName !== 'admin-add-keywords' ?
          <div
            data-tip={'commentsHistory-' + id} 
            data-for={'commentsHistory-' + id} 
            data-arrow-color="#e43e21" 
            data-place="left"
          >
            {
              getPicto( 
                'MoreHorizontal', 
                {
                  size:"1rem",
                  className: comments === true ? "comment-selector selected" : "comment-selector",
                  viewBox: '6 6 12 12'
                }
              )
            }
            <CommentsHistory 
              id={'commentsHistory-' + id} 
              label={label}
              date={currentDate}
              accountID={getItem( 'userID' )}
            />
          </div>
          : false
      }

      {
        appPathName !== 'urls' ?
          <div
            className='expectedUrl-container'
            data-tip={'expectedUrl-' + id} 
            data-for={'expectedUrl-' + id} 
            data-arrow-color="#e43e21" 
            data-place="left"
          >
            <span 
              className={ 
                currentExpectedUrl === null ? 
                  'expectedUrl-selector off' 
                  : 'expectedUrl-selector' 
              } 
              title={currentExpectedUrl !== null ? currentExpectedUrl : 'URL à renseigner' }
              onClick={ () => filterOnUrl( currentExpectedUrl ) }
            >
              {currentExpectedUrl === null ? 
                'URL à renseigner' 
                : currentExpectedUrl.slice( -1 ) === '/' ? 
                    <React.Fragment>{currentExpectedUrl.slice( 0, -1 )}<span className="bidi-override">/</span></React.Fragment>
                    : currentExpectedUrl
                + ( typeExpectedUrl === 'newurl' ? ' ( N )' : '' )
              }
            </span> 
            <ExpectedURL 
              id={'expectedUrl-' + id} 
              keywords={keywords} 
              automaticExpectedUrl={automaticExpectedUrl}
              updatedExpectedUrl={updatedExpectedUrl}
              date={currentDate}
              accountID={getItem( 'userID' )}
              typeExpectedUrl={typeExpectedUrl}
            />
          </div>
          : false
      }
    </div>
  );
}

export default Actions;