/** Dependencies **/
import React, {useEffect, useState} from 'react';
import Select from 'react-select';

/** Helpers **/
import { getStylesSelectMenu } from './../../../helpers/ui';

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { addHiddenColumns, removeHiddenColumns } from './../../../reducers/hiddenColumns';

/** SCSS **/
import './MyPerfSelect.scss';

function MyPerfSelect( props ) 
{
  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get state from redux store **/
  const hiddenColumns = useSelector( state => state.hiddenColumns.value );
  const instanceConfig = useSelector( state => state.config.value );  

  // Get groups
  const groups = instanceConfig.groups;

  /** Loading options table actions from redux values **/
  let optionsGroups = [];
  if ( groups instanceof Array ) {
    groups.forEach( group => {
      optionsGroups.push({
        value: group.value,
        label: group.label.toUpperCase()
      })
    });

    // remove me (first result) of array
    optionsGroups = optionsGroups.slice( 1 );
  }

  /** Init state **/
  // Set default selected group with first result of options
  const [currentSelectedGroups, setCurrentSelectedGroup] = useState( optionsGroups[0] );

  const handleChange = ( value ) => 
  {
    // set column to hide
    let columnToHide = null;
    if( currentSelectedGroups )
      columnToHide = 'perf' + currentSelectedGroups.value;

    // set column to show
    const columnToShow = 'perf' + value.value;

    // Update hidden columns
    if( columnToHide )
      dispatch( addHiddenColumns( [columnToHide] ));

    // Update show columns
    dispatch( removeHiddenColumns( [columnToShow] ));

    // Set current selected group
    setCurrentSelectedGroup( value );
  }

  useEffect( () => 
  {
    // Connect select current visible group with filter column menu
    if( hiddenColumns.length > 0 )
    {
      // Transform options values to match with accessor of columns table
      const groupsAccessors = optionsGroups.map( group => 'perf' + group.value );

      // Get group which is visible
      const visibleGroup = groupsAccessors.filter( accessor => !hiddenColumns.includes( accessor ) );
      if( visibleGroup.length === 1 )
      {
        // Get option values
        const currentSelectedGroup = optionsGroups.filter( group => group.value === visibleGroup[0].replace( 'perf', '' ) )[0];
        setCurrentSelectedGroup( currentSelectedGroup );
      } else {
        setCurrentSelectedGroup( null );
      }
    }
  }, [hiddenColumns])

  return (
    <div className={ currentSelectedGroups === null ? 'select-groups without-group' : 'select-groups' }>
      <h4>ma visibilité <br />seo + snippets</h4>
      { currentSelectedGroups !== null ?
        <React.Fragment>
          <span>vs</span>
          <Select
            className="react-select"
            classNamePrefix="react-select"
            options={optionsGroups}
            styles={getStylesSelectMenu()}
            placeholder='GROUPE'
            value={ currentSelectedGroups }
            components={{
              IndicatorSeparator: () => null
            }}
            onChange={ value => handleChange( value ) }
          />
        </React.Fragment>
        : false
      }
    </div>
  );
}

export default MyPerfSelect;