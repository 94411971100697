/** Redux */
import { combineReducers } from 'redux';
import { configureStore, createAction } from '@reduxjs/toolkit';

/** Reducers */
import configReducer from './reducers/config';
import selectedFiltersReducer from './reducers/selectedFilters';
import datasReducer from './reducers/datas';
import togglerReducer from './reducers/toggler';
import filtersDatasReducer from './reducers/filtersDatas';
import loadersReducer from './reducers/loaders';
import loadersTriggersReducer from './reducers/loadersTriggers';
import selectedRowIDReducer from './reducers/selectedRowID';
import listCategoryDispReducer from './reducers/listCategoryDisp';
import selectedHeaderFiltersReducer from './reducers/selectedHeaderFilters';
import hiddenColumnsReducer from './reducers/hiddenColumns';
import selectedDataVizTabReducer from './reducers/selectedDataVizTab';
import levelCategoryReducer from './reducers/levelCategory';
import appPathNameReducer from './reducers/appPathName';
import enabledInstancesReducer from './reducers/enabledInstances';
import selectedInstanceReducer from './reducers/selectedInstance';
import selectedViewTypeDataVizReducer from './reducers/selectedViewTypeDataViz';

export const revertAll = createAction( 'REVERT_ALL' );

const store = configureStore({
	reducer: combineReducers({
		config: configReducer,
		selectedFilters: selectedFiltersReducer,
		datas:  datasReducer,
		togglers:  togglerReducer,
		filtersDatas:  filtersDatasReducer,
		loaders: loadersReducer,
		loadersTriggers: loadersTriggersReducer,
		selectedRowsID: selectedRowIDReducer,
		listCategoryDisp: listCategoryDispReducer,
		selectedHeaderFilters: selectedHeaderFiltersReducer,
		hiddenColumns: hiddenColumnsReducer,
		selectedDataVizTab: selectedDataVizTabReducer,
		levelCategory: levelCategoryReducer,
		appPathName: appPathNameReducer,
		enabledInstances: enabledInstancesReducer,
		selectedInstance: selectedInstanceReducer,
		selectedViewTypeDataViz: selectedViewTypeDataVizReducer
	})
});

export default store;