/** Dependencies **/
import React from 'react';

/** Redux **/
import { useSelector } from 'react-redux';

/** Helpers **/
import { stringToObjectPath } from './../../../helpers/functions';
import { getIsIgnore } from './../../../helpers/datas';

function DefaultSumTotalCell( props ) 
{
  /** Get props **/
  const id = props.id;

  /** Get state from redux store **/
  const datas = useSelector( state => state.datas.value );

  // filter datas with non ignore value
  let nonIgnoreDatas = null;
  if( datas.filterResult )
    nonIgnoreDatas = datas.filterResult.filter( item => !getIsIgnore( item ) );

  // get value summary
  let sumVol = null;
  if( nonIgnoreDatas )
    sumVol = nonIgnoreDatas.filter( item => 
      stringToObjectPath( item, id ) 
      && stringToObjectPath( item, id ).value
      && stringToObjectPath( item, id ).value !== null
    ).map( item => 
      stringToObjectPath( item, id ).value 
    ).reduce( (a, b) => a + parseInt( b ), 0 );

  // get variation summary
  let sumVariation = null;
  if( nonIgnoreDatas )
    sumVariation = nonIgnoreDatas.filter( item => 
      stringToObjectPath( item, id )
      && stringToObjectPath( item, id ).variation
      && stringToObjectPath( item, id ).variation !== null
    ).map( item => 
      stringToObjectPath( item, id ).variation 
    ).reduce( (a, b) => a + parseInt( b ), 0 );
  
  return (
    <React.Fragment>
      {(
        sumVol !== null
        && !isNaN( sumVol )
        && sumVol > 0
      ) ? 
        <div>{ new Intl.NumberFormat( 'fr-FR' ).format( sumVol )}</div>
        : false
      }
      {(
        sumVariation !== null
        && !isNaN( sumVariation )
        && sumVariation !== 0
      ) ? 
        <div className='variation'>{ new Intl.NumberFormat( 'fr-FR', { signDisplay: 'always' } ).format( sumVariation ) }</div> 
        : false 
      }
    </React.Fragment>
  );
}

export default DefaultSumTotalCell;