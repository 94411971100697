/** Dependencies **/
import React from 'react';

/** Redux **/
import { useSelector } from 'react-redux';

/** Components **/
import GSCDetails from './../TableTooltips/GSCDetails';
import GSCDetailsUrl from './../TableTooltips/GSCDetailsUrl';

function GSC( props ) 
{
  // get props
  const {
    id,
    label,
    values,
    accessor
  } = props;

  /** Get state from redux store */
  const appPathName = useSelector( state => state.appPathName.value );  

  // set gscID
  const gscID = 'gsc-' + accessor + '-' + id;

  // get value
  let value = null;
  if( 
    values
    && values.value !== null
  )
    value = values.value;

  // get variation
  let variation = null;
  if(
    values 
    && values.variation !== null
  )
    variation = values.variation;

  // get percent
  let percent = null;
  if( 
    values
    && values.percent !== null
  )
    percent = values.percent;

  // set positiv and negativ width for hr design
  let positiveWidth = null;
  let negativeWidth = null;
  if( percent !== null && percent > 0 )
  {
    positiveWidth = percent;
    negativeWidth = 100 - percent;
  }

  return (
    <React.Fragment>
      { ( positiveWidth !== null && negativeWidth !== null ) || variation !== null || value !== null  ?
        <div 
          className="gsc-container pointer"
          data-tip={gscID} 
          data-for={gscID} 
          data-arrow-color="#e43e21"         
          data-place="bottom"        
        >
          <div className='hover-area'>&nbsp;</div>

          { positiveWidth !== null && negativeWidth !== null ?
            <div className="line-container">
              <hr className={ ( positiveWidth === 0 ? 'none' : '' ) + ' positive-line'} style={{ width: positiveWidth + "%" }} />          
              <hr className={ ( negativeWidth === 0 ? 'none' : '' ) + ' negative-line'} style={{ width: negativeWidth + "%" }} />
            </div>
          : false}

          { value !== null && value > 0 ? 
            <p className='value'>{
              new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1 }).format( 
                ( accessor === 'ctr' ? value : value ) )
              }
              {accessor === 'ctr' ? '%' : ''}
            </p>
          : false}
          
          { variation !== null && variation !== 0 ? 
            <p className='variation'>{new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1, style: "decimal", signDisplay: 'always' }).format( variation )}</p>
          : false}

          { appPathName === 'keywords' 
            || appPathName === 'gsc-keywords' ?
              <GSCDetails
                id={gscID} 
                accessor={accessor} 
                label={label}
              />
            : appPathName === 'urls' ?
              <GSCDetailsUrl
                id={gscID} 
                accessor={accessor} 
                label={label}
              />
            : false
          }
        </div>
        : false
      }
    </React.Fragment>
  );
}

export default GSC;