/** Dependencies **/
import React from 'react';
import { useNavigate } from "react-router-dom";

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedHeaderFilters } from './../../../reducers/selectedHeaderFilters';

/** Components **/
import TableCellCheckbox from './Checkbox';

/** Helpers */
import { 
  goTo, 
  getCategories 
} from './../../../helpers/barometer/barometer';

function Keywords( props ) 
{
  /** Get props **/
  let keyword = props.value;
  let id = props.id;
  let category1 = props.category1;
  let category2 = props.category2;

  /** Get state from redux store **/
  const filtersDatas = useSelector( state => state.filtersDatas.value );

  /** Instance dispatch object **/
  const dispatch = useDispatch();

  /** Instance navigate object */
  const navigate = useNavigate();

  /**
   * Dispatch filter on keyword with current keyword
   */
  const filterOnKeyword = () => 
  {
    dispatch( 
      updateSelectedHeaderFilters( { 
        filterName: 'label', 
        value: {
          type: 'text',
          label: keyword,
          option: 'exactly',
          usefor: 'filter',
          value: {
            field: 'label-text',
            type: 'string-label',
            result: keyword
          }
        }
      }) 
    ); 
  }

  const filterOnCategoryToCategories = ( categories ) => 
  {
    // got to categories
    goTo( 
      categories, 
      1, 
      filtersDatas, 
      // process.env.PUBLIC_URL + '/categories', 
      null,
      dispatch, 
      navigate 
    )
  }

  return (
    <div className="keyword-container">
      <div className='keyword'>
        <TableCellCheckbox {...props} />
        <label htmlFor={'keyword-' + id}>&nbsp;</label>
        <div className='label' title={keyword} onClick={filterOnKeyword}>{keyword}</div>
      </div>
      {(
        category1 !== undefined 
        && category1 !== '' 
        && category1 !== '-' 
        && category2 !== undefined 
        && category2 !== '' 
        && category2 !== '-'
      )?
      <div className='categories'>
        {category1 !== undefined && category1 !== '' && category1 !== '-' ? 
          <div 
            className='label' 
            title={category1} 
            data-value={category1} 
            onClick={ () => filterOnCategoryToCategories( getCategories( filtersDatas.categories, category1 ) ) }
          >{'> '}{category1}</div>
          : false
        }
        {category2 !== undefined && category2 !== '' && category2 !== '-' ? 
          <div 
            className='label' 
            title={category2}
            onClick={ () => filterOnCategoryToCategories( getCategories( filtersDatas.categories, category1, category2 ) ) }            
          >{'>> '}{category2}</div>
          : false 
        }
      </div>
      :''}
    </div>
  );
}

export default Keywords;