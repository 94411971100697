/** Components **/
import DefaultDateRangeFilterToolTip from './../../../components/Table/FilterToolTips/DefaultDateRangeFilterToolTip';
import TableHeaderTitleWithPicto from './../../../components/Table/TableHeaders/TitleWithPicto';
import TableCellGSCIdentifierDate from './../../../components/Table/TableCells/GSCIdentifierDate';

export const GscIdentifierDateColumn = ( widthColumns ) => 
{
  return {
    accessor: "gsc.identifierDate",
    width: widthColumns["gsc.identifierDate"],
    title: "Date d'ident.",
    className: "col-identifier-date col-center",
    Filter: DefaultDateRangeFilterToolTip,
    filter: 'multiFieldsCustomFilter',
    filterTitle: 'Date d\'identification',
    sortDescFirst: false,          
    sortType: 'dateSort',
    Header: props => 
      <TableHeaderTitleWithPicto id={props.column.id} title={props.column.title} />,
    Cell: props => 
      <TableCellGSCIdentifierDate 
        gscIdentifierDate={props.value}
      />
  }
}