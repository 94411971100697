/** Components **/
import TableCellEventType from './../../../components/Table/TableCells/EventType';

export const EventColumn = ( widthColumns, instanceConfig ) => 
{
  const events = instanceConfig.events;
  
  return {
    accessor: "eventID",
    width: widthColumns['eventID'],
    title: "événement",
    className: "col-label",
    disableSortBy: true,
    disableFilters: true,
    Header: props => "événement",
    Cell: props => 
      <TableCellEventType 
        {...props.row.getToggleRowSelectedProps()} 
        label={ 
          props?.cell?.row?.values?.eventTypeID !== undefined
          && props?.cell?.row?.values?.eventSubTypeID !== undefined
          && events[props.cell.row.values.eventTypeID]?.subTypes[props.cell.row.values.eventSubTypeID] !== undefined
          && events[props.cell.row.values.eventTypeID]?.subTypes[props.cell.row.values.eventSubTypeID]?.events[props.value]?.label !== undefined ? 
            events[props.cell.row.values.eventTypeID].subTypes[props.cell.row.values.eventSubTypeID].events[props.value]?.label
            : null
        }
      />
  }
}