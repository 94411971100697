/** Dependencies **/
import React from 'react';
import moment from 'moment';

function KeywordCreatedDate( props ) 
{
  // get value of identifier date
  let createdDate = null
  if( props?.createdDate !== undefined )
    createdDate = props.createdDate;

  return (
    <div className="created-date-container">
      { createdDate !== null ?
        <p className='value'>{ moment( createdDate.toString(), 'YYYYMMDD' ).format( 'DD - MMM - YY' ) }</p>
        : false
      } 
    </div>
  );
}

export default KeywordCreatedDate;