/** Components **/
import TableCellEventType from './../../../components/Table/TableCells/EventType';

export const EventSubTypeColumn = ( widthColumns, instanceConfig ) => 
{
  const events = instanceConfig.events;

  return {
    accessor: "eventSubTypeID",
    width: widthColumns['eventSubTypeID'],
    title: "Sous-type d'événement",
    className: "col-label",
    disableSortBy: true,
    disableFilters: true,
    Header: props => "sous-type d'événement",
    Cell: props => 
      <TableCellEventType 
        {...props.row.getToggleRowSelectedProps()} 
        label={ 
          props?.cell?.row?.values?.eventTypeID !== undefined
          && events[props.cell.row.values.eventTypeID].subTypes[props.value]?.label !== undefined ? 
            events[props.cell.row.values.eventTypeID].subTypes[props.value]?.label 
            : null
        }
      />
  }
}