/** Dependencies **/
import React, { useEffect, useState, useMemo } from 'react';
import { 
  useTable, 
  useSortBy, 
  useRowSelect, 
  useExpanded, 
  usePagination, 
  useFilters 
} from 'react-table';
import Sticky from 'react-sticky-el';

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedRowsID } from './../../reducers/selectedRowID';
import { updateDatas } from './../../reducers/datas';
import { loadHiddenColumns } from './../../reducers/hiddenColumns';
import { updateSelectedHeaderFilters } from './../../reducers/selectedHeaderFilters';

/** Helpers **/
import { getPicto } from './../../helpers/pictos';
import { getCustomColumns } from './../../helpers/table/customColumns';
import { getCustomFilters } from './../../helpers/table/customFilters';
import { getCustomSorts } from './../../helpers/table/customSorts';
import { 
  buildRemindersFilter,
  fireSortClickOnHeaderTitle
} from './../../helpers/barometer/table';

/** Components **/
import DefaultStringFilterToolTip from './FilterToolTips/DefaultStringFilterToolTip';
import AddKeywordRow from './Rows/AddKeyword';

/** JSON */
import globalConfig from './../../assets/json/config.json';

/** SCSS **/
import './Table.scss';
import './TableKeywords.scss';
import './TableThematics.scss';
import './TableUrls.scss';
import './TableGscKeywords.scss';
import './TableAdminAddKeywords.scss';

function Table( props ) 
{
  const {
    displayTotal,
    displayAddKeyword
  } = props; 

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get state from redux store **/
  const selectedFilters = useSelector( state => state.selectedFilters.value );
  const selectedHeaderFilters = useSelector( state => state.selectedHeaderFilters.value );
  const hiddenColumns = useSelector( state => state.hiddenColumns.value );
  const appPathName = useSelector( state => state.appPathName.value );
  const instanceConfig = useSelector( state => state.config.value );

  /** Init state **/
  const [skipPageReset, setSkipPageReset] = useState( false );

  /** Get datas from config file **/
  const hidden_columns = globalConfig.barometer.hidden_columns;
  const actions_states = globalConfig.barometer.action[appPathName];

  /** Set header to table **/
  const columns = useMemo(
    () => (
      appPathName
      && instanceConfig
    ) ? getCustomColumns( appPathName, instanceConfig ) : [],
    [appPathName]
  )
  
  /** Set all custom filter **/
  const filterTypes = useMemo(
    () => getCustomFilters(), 
    []
  )

  /** Set all custom sort **/
  const sortTypes = useMemo(
    () => getCustomSorts(), 
    []
  )

  /** Set default sort **/
  const defaultSort = useMemo(
    () => ([{
      id: props.sortField,
      desc: props.sortDesc
    }]), 
    []
  )

  /** Set default filter **/
  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultStringFilterToolTip,
    }), 
    []
  );

  /** Set datas to table **/
  const datas = useMemo(
    () => props.datas,
    [props.datas]
  );
  
  /** Instance Table **/  
  const tableInstance = useTable(
    { 
      data: datas,
      columns: columns,
      defaultColumn: defaultColumn,
      filterTypes: filterTypes,
      sortTypes: sortTypes,
      disableSortRemove: true,      
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        sortBy: defaultSort
      },
      manualPagination: false,
      autoResetPage: skipPageReset,
      autoResetFilters: skipPageReset,
      autoResetSortBy: skipPageReset,
      loading: true
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect    
  );
  
  /** Build UI from Table Instance **/
  const {
    getTableProps,
    getTableBodyProps, 
    headerGroups,
    footerGroups,
    prepareRow,
    // used by pagination    
    page,
    pageCount,
    nextPage,
    previousPage,
    gotoPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    state: { pageIndex, pageSize, selectedRowIds },
    // use by selected redux store
    rows,
    // use by datas redux store for filters datas
    filteredRows,
    setAllFilters
  } = tableInstance;

  /** Update selectedRows in redux store **/
  useEffect( () => 
  {
    let selectedRows = [];
    for (const [key] of Object.entries( selectedRowIds )) {
      if( rows[rows.findIndex( row => key === row.id )] )
        selectedRows.push({
          id: key,
          keywords: rows[rows.findIndex( row => key === row.id )].original.keywords,
          categories: [
            rows[rows.findIndex( row => key === row.id )].original.category1,
            rows[rows.findIndex( row => key === row.id )].original.category2,
            rows[rows.findIndex( row => key === row.id )].original.category3
          ],
          actionID: rows[rows.findIndex( row => key === row.id )].original.action ? 
            rows[rows.findIndex( row => key === row.id )].original.action.updatedActionID !== null ?
              rows[rows.findIndex( row => key === row.id )].original.action.updatedActionID
              : rows[rows.findIndex( row => key === row.id )].original.action.automaticActionID !== null ?
              rows[rows.findIndex( row => key === row.id )].original.action.automaticActionID
              : undefined
            : undefined,
          lastUpdate: rows[rows.findIndex( row => key === row.id )].original.action ? 
            rows[rows.findIndex( row => key === row.id )].original.action.lastUpdate 
            : undefined,
          usersIDs: rows[rows.findIndex( row => key === row.id )].original.action ? 
            rows[rows.findIndex( row => key === row.id )].original.action.usersIDs 
            : undefined
        })
    }
    
    dispatch( updateSelectedRowsID( selectedRows ) );

  },[selectedRowIds])

  /** Update filter datas in redux store **/
  useEffect( () => 
  {
    if( filteredRows instanceof Array )
      dispatch( updateDatas( {'filterResult': filteredRows.map( row => row.original )} ) );

  },[filteredRows]);

  /** Update hidden columns in table **/
  useEffect( () => 
  {
    if( hiddenColumns )
      tableInstance.setHiddenColumns( hiddenColumns );

  },[hiddenColumns]);

  /** Update hidden columns with context */
  useEffect( () => 
  {
    // set hidden columns array
    let hiddenColumns = hidden_columns[appPathName];

    if( appPathName !== '' )
      dispatch( loadHiddenColumns( hiddenColumns ));

  },[appPathName, selectedFilters.currentPeriod]);

  /** Set SkipPageReset flag to true to not reload table and keep sort, filter and current page **/
  useEffect(() => 
  {
    setSkipPageReset( true );
  },[datas]);
  
  /** Set SkipPageReset flag to false when datas updated to reload table when category filter is updated **/
  useEffect(() => 
  {
    // After the table has updated, always remove the flag
    setSkipPageReset( false );
  });

  /** Go to page 0 if rows > 0 but current page is out of total page */
  useEffect(() => 
  {
    if( 
      page 
      && page.length === 0 
      && rows 
      && rows.length > 0 
    )
      gotoPage( 0 );      
      
  }, [rows])

  /** Go to page 0 if top nav filters changed */
  useEffect( () => 
  {
    gotoPage( 0 );
  }, [selectedFilters])

  /** Apply filters when selectedHeaderFilter updated **/
  useEffect(() => 
  {
    // init array where all filters case will be save
    let filtersToSet = [];
    Object.keys( selectedHeaderFilters ).forEach( id => 
    {
      filtersToSet = [...filtersToSet, {
        id: id, 
        value: selectedHeaderFilters[id]
      }];
    });

    setAllFilters( filtersToSet );

  },[selectedHeaderFilters]);

  /** Apply default filter on non ignore rows */
  useEffect( () => 
  {
    if( actions_states !== undefined )
    {
      // set payload with all states except Ignore
      const payload = { 
        value: { 
          result: [
            ...[''], // add '' for non defined values
            ...actions_states.filter( action => action.value !== '3' && action.value !== '9' ).map( action => action.value )
          ], 
          type: 'select-action',
          field: 'actions' 
        }, 
        label: 'Action', 
        type: 'text' 
      };
  
      // dispatch filter payload for action filter
      dispatch( updateSelectedHeaderFilters( { filterName: 'action', value: payload } ));
    }

  }, []);

  return (
    <div 
      className={
        appPathName === 'categories' ? 
          "table thematics" 
        : appPathName === 'urls' ?
          "table urls"
        : appPathName === 'gsc-keywords' ?
          "table keywords gsc-keywords"
        : appPathName === 'admin-add-keywords' ?
          "table keywords admin-add-keywords"
        : "table keywords"
      }
    >
      <Sticky className="sticky-container" topOffset={-118}>
        <table className='table-header' {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps([{
                    className: column.className, 
                    width: column.width !== undefined ? column.width + '%' : ''
                  }])}> 
                    <div 
                      className={( column.canSort || column.canFilter ) ? "column-header-container pointer" : "column-header-container"}
                      onClick={ ( e ) => ( column.canSort || column.canFilter ) ? fireSortClickOnHeaderTitle( e ) : false }
                    >
                      <div className="column-header-title">
                        { column.render('Header') }
                      </div>
                      {( column.canSort || column.canFilter )
                        ?
                        <React.Fragment>
                          <button 
                            className='sort-filter-toggler'
                            data-place='bottom'
                            data-for={'filter-' + column.id} 
                            data-tip
                            data-event='click'
                          >
                            {(column.id === 'serpviewer')
                              ? false
                              : getPicto( 'MoreVerticalSquare' )
                            }
                          </button>
                          { column.render( 'Filter' ) }
                        </React.Fragment>
                        : false
                      }
                    </div>
                  </th>
                ))}
              </tr> 
            ))}
            
            {/* Reminder filter and sorted column with first row of headerGroups */}
            <tr>
              {headerGroups[0].headers.map( column => (
                <th {...column.getHeaderProps()}>
                  <div className="column-header-container">
                    {buildRemindersFilter( column.headers, selectedHeaderFilters ).map( reminder => reminder )}                  
                  </div>
                </th>
              ))}
            </tr> 

            {/* Total row */}
            { displayTotal ? 
              <tr className='totalrow' {...footerGroups[0].getFooterGroupProps()}>
                {footerGroups[0].headers.map(column => (
                  <th {...column.getFooterProps([{
                    className: column.className
                  }])}>
                    {column.Total ? column.render( 'Total' ) : ''}
                  </th>
                ))}              
              </tr>
              : null
            }

            {/* Add Keyword Row */}
            { displayAddKeyword ? 
              <AddKeywordRow columnsWidth={ headerGroups[1].headers.map( header => header.width ) } />
              : null
            }

          </thead>
        </table>
      </Sticky>

      <table className='table-body'>
        <tbody {...getTableBodyProps()}>
          {page.length > 0 ?
            page.map( ( row, i ) => 
            {
              prepareRow( row );
              return (
                <tr 
                  {...row.getRowProps()} 
                  className={
                    appPathName === 'categories' && row.canExpand ? 'parentRow' : ''
                  }
                >
                  {row.cells.map( cell => {
                    return (
                      <td {...cell.getCellProps([
                          {
                            className: cell.column.className,
                            style: cell.column.style,
                            width: cell.column.width !== null ? cell.column.width + '%' : ''
                          },
                        ])
                      }>
                        { cell.render( 'Cell' ) }
                      </td>
                    )
                  })}
                </tr>
              )
            })
          :<tr>
            <td className='noresult' colSpan={16}>
              <strong>Aucun résultat</strong>
            </td>
          </tr>
          }
        </tbody>
      </table>

      <div className="pagination-container">
        <div className="results">{( pageIndex * pageSize )} - { !canNextPage ? rows.length : (( pageIndex * pageSize ) + pageSize )} résultats sur {rows.length}</div>
        <div className="pagination-controller">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>&lt;&lt;</button>
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>&lt;</button>
          <button onClick={() => nextPage()} disabled={!canNextPage}>&gt;</button>
          <button onClick={() => gotoPage(pageCount-1)} disabled={!canNextPage}>&gt;&gt;</button>
          &nbsp;&nbsp;
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map( pageSize => 
              <option key={pageSize} value={pageSize}>
                {pageSize} / page
              </option>
            )}
          </select>
        </div>
      </div>
      
    </div>
  )
}

export default Table;