/** Dependencies **/
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form"
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';
import { updateDatas } from './../../reducers/datas';

/** Components */
import Loader from './../Loader/Loader';

/** Helpers **/
import { callWebservice } from './../../helpers/webservice/webserviceCaller';
import { getStylesSelectMenu } from './../../helpers/ui'
import { getPicto } from "../../helpers/pictos";

/** SCSS **/
import './Forms.scss';
import './AddEventsForm.scss';

function AddEventsForm() 
{
  const {
    control,
    register,
    handleSubmit,
    resetField,
    setValue,
    formState: { errors },
  } = useForm()

  /** Instance FileReader object */
  const fileReader = new FileReader();

  /** Init state **/
  const [currentType, setCurrentType] = useState( null );
  const [currentSubType, setCurrentSubType] = useState( null );
  const [currentEvent, setCurrentEvent] = useState( null );
  const [customFieldsValues, setCustomFieldsValues] = useState( [] );
  const [endDateVisible, setEndDateVisible] = useState( false );
  const [scope, setScope] = useState( 'website' );
  const [currentUrls, setCurrentUrls] = useState( [] );
  const [csvFile, setCsvFile] = useState( null );

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get state from redux store **/
	const selectedInstance = useSelector( state => state.selectedInstance.value );
  const instanceConfig = useSelector( state => state.config.value );  
  const datas = useSelector( state => state.datas.value );

  /** set callback function for each loader **/
  const callbackUpdatedDatas = ( result, id ) => dispatch( updateDatas( { 'globalResult': [...datas.globalResult, ...result.events ] } ) );

  /**
   * Load csv file
   * @param {string} file 
   */
  const loadCsvFile = file => 
  {
    fileReader.onload = event => setCsvFile( event.target.result );
    fileReader.readAsText( file )
  }

  /**
   * On submit function callback
   * @param {array} data 
   */
  const onSubmit = data =>
  {
    // fomat dates
    data.startDate = parseInt( data.startDate.replaceAll( '-', '' ) );
    data.endDate = parseInt( data.endDate.replaceAll( '-', '' ) );

    // add 
    callWebservice(
      'add-events-form',
      'add-events-form',
      'add-event',
      dispatch,
      selectedInstance,
      { events: [ data ] },
      { function: 'callbackUpdatedDatas' }
    );
  }

  const onScopeChanged = value => 
  {
    resetField( 'scopeValues' );
    setCurrentUrls( [] );
    setCsvFile( null );
    setScope( value );
  }

  return (
    <ReactTooltip 
      id={'add-event-form'}
      className="tooltip grey"
      effect='solid'
      place='bottom'
      delayHide={100}
      delayShow={100}
      delayUpdate={500}
      globalEventOff='click'
      arrowColor="transparent"
      isCapture={true}
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        return {
          left: 100,
          top: 270
        };
      }}
    >
      <Loader 
        loaderID='add-events-form' 
        loaderStyle={{ width: '15', stroke: '#e43e21' }}   
        callBackFcts={{
          callbackUpdatedDatas: callbackUpdatedDatas
        }}      
        globalCallBack={ undefined } 
      /> 

      <form onSubmit={ handleSubmit( onSubmit ) }>

        {/* Date */}
        <div className="date">
          <h4>Date</h4>
          <div className="content">
            <div className="start-date">
              <label>Date de début</label>
              <input 
                type="date"
                { ...register( 'startDate', { required: true } ) } 
              />
            </div>
            <div className="end-date">
              <input 
                type="checkbox" 
                id="endDate"
                onChange={ () => endDateVisible === true ? setEndDateVisible( false ) : setEndDateVisible( true ) }
              />
              <label htmlFor="endDate" className="checkbox">Date de fin</label>
              <input 
                type="date"
                className={ endDateVisible === true ? 'on' : '' }
                { ...register( 'endDate' ) } 
              />
            </div>
          </div>
        </div>

        {/* Type d'événements */}
        <div>
          <h4>Type d'événements</h4>
          <Controller
            name="eventTypeID"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className='react-select'
                classNamePrefix="react-select"
                options={ Object.keys( instanceConfig.events ).map( eventTypeID => (
                  { 
                    label: instanceConfig.events[eventTypeID].label, 
                    value: instanceConfig.events[eventTypeID].id
                  }
                ))}
                styles={ getStylesSelectMenu() }  
                value={ currentType }
                components={{
                  IndicatorSeparator: () => null
                }}
                onChange={ payload => 
                {
                  field.onChange( payload.value );
                  setCurrentType( payload );
                  setCurrentSubType( null );
                  setCurrentEvent( null );
                }}
              />      
            )}  
            rules={{ required: true }}
          />            
        </div>

        {/* Sous Type d'événements */}
        <div>
          <h4>Sous-type d'événements</h4>
          <Controller
            name="eventSubTypeID"
            control={control}
            render={({ field }) => (
              <Select
                {...field}  
                className='react-select'
                classNamePrefix="react-select"
                options={ 
                  (
                    Object.keys( instanceConfig.events ).length > 0 
                    && currentType?.value !== undefined
                    && instanceConfig.events[currentType.value] 
                  ) ? 
                    Object.keys( instanceConfig.events[currentType.value].subTypes ).map( subTypeID => (                
                      { 
                        label: instanceConfig.events[currentType.value].subTypes[subTypeID].label, 
                        value: instanceConfig.events[currentType.value].subTypes[subTypeID].id
                      }
                    ))
                  : []
                }
                styles={ getStylesSelectMenu() }
                value={ currentSubType }
                components={{
                  IndicatorSeparator: () => null
                }}
                onChange={ payload => 
                {
                  field.onChange( payload.value )
                  setCurrentSubType( payload );
                  setCurrentEvent( null );
                }}
              />      
            )}  
            rules={{ required: true }}
          />              
        </div>

        {/* Événements */}
        <div>
          <h4>Événements</h4>  
          <Controller
            name="eventID"
            control={control}
            render={({ field }) => (
              <Select
                {...field}  
                className='react-select'
                classNamePrefix="react-select"
                options={ 
                  (
                    Object.keys( instanceConfig.events ).length > 0 
                    && currentType?.value !== undefined
                    && instanceConfig.events[currentType.value] 
                    && currentSubType?.value !== undefined
                    && instanceConfig.events[currentType.value].subTypes[currentSubType.value] 
                  ) ? 
                    Object.keys( instanceConfig.events[currentType.value].subTypes[currentSubType.value].events ).map( eventID => (
                      { 
                        label: instanceConfig.events[currentType.value].subTypes[currentSubType.value].events[eventID].label, 
                        value: instanceConfig.events[currentType.value].subTypes[currentSubType.value].events[eventID].id
                      }
                    ))
                  : []
                }
                styles={ getStylesSelectMenu() }
                value={ currentEvent }
                components={{
                  IndicatorSeparator: () => null
                }}
                onChange={ payload => 
                {
                  resetField( 'customFields' );
                  field.onChange( payload.value );
                  setCurrentEvent( payload ) }          
                }
              />      
            )}  
            rules={{ required: true }}
          />       
        </div>

        {/* Champs contextuels */}
        <div className="custom-fields">
          <h5>Champs contextuels événements</h5>
          { 
            (
              currentType?.value !== undefined
              && currentSubType?.value !== undefined
              && currentEvent?.value != undefined
              && instanceConfig.events[currentType?.value].subTypes[currentSubType?.value].events[currentEvent?.value]
            ) ? 
              instanceConfig.events[currentType?.value].subTypes[currentSubType?.value].events[currentEvent?.value].customFields.map( ( customField, index ) => 
              { 
                switch ( customField.customFieldType ) {
                  case 'string':
                  case 'integer':
                    return <div key={index}>
                      <label>{ customField.customFieldLabel }</label>
                      <input 
                        { ...register( 'customFields.ID_' + customField.customFieldID, { required: true } ) } 
                        type="text" 
                        onChange={ e => 
                        {
                          // clone values
                          const customFieldsValuesClone = [...customFieldsValues];
                          customFieldsValuesClone[index] = e.currentTarget.value;

                          // update custom field values
                          setCustomFieldsValues( customFieldsValuesClone );
                        }}
                        value={ customFieldsValues[index] ? customFieldsValues[index] : '' } 
                      />
                    </div>
                    break;  
                  case 'predefined-multiple':
                    return <div key={index}>
                      <label>{ customField.customFieldLabel }</label>                      
                      <Controller
                        name={ 'customFields.ID_' + customField.customFieldID }
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field} 
                            className='react-select'
                            classNamePrefix="react-select"
                            options={ customField.customFieldDefaultValue ? customField.customFieldDefaultValue.map( value => ({ label: value, value: value })) : [] }
                            styles={ getStylesSelectMenu() }
                            value={ { label: customFieldsValues[index], value: customFieldsValues[index] } }
                            components={{
                              IndicatorSeparator: () => null
                            }}
                            onChange={ payload => 
                            {
                              field.onChange( payload.value );

                              // clone values
                              const customFieldsValuesClone = [...customFieldsValues];
                              customFieldsValuesClone[index] = payload.value

                              // update custom field values
                              setCustomFieldsValues( customFieldsValuesClone );
                            }}       
                          />  
                        )}  
                        rules={{ required: true }}
                      />    
                    </div>
                    break;
                  case 'date':
                    return <div key={index}>
                      <label>{ customField.customFieldLabel }</label><br />
                      <input 
                        { ...register( 'customFields.ID_' + customField.customFieldID, { required: true } ) } 
                        type="date" 
                        onChange={ e => 
                        {
                          // clone values
                          const customFieldsValuesClone = [...customFieldsValues];
                          customFieldsValuesClone[index] = e.currentTarget.value;

                          // update custom field values
                          setCustomFieldsValues( customFieldsValuesClone );
                        }}
                        value={ customFieldsValues[index] ? customFieldsValues[index] : '' } 
                      />
                    </div>
                    break;
                  default:
                    break;
                }
              }) 
              : null 
          }
          
        </div>

        {/* Périmètre */}
        <div className="scope">
          <h4>Périmètre</h4>
          
          <div>
            <input 
              { ...register( 'scopeType', { onChange: e => onScopeChanged( e.target.value ) } ) } 
              type="radio"
              name="scopeType"
              id="scope1"
              value="website"              
            />
            <label htmlFor="scope1">Tout le site</label>
          </div>

          <div>
            <input 
              { ...register( 'scopeType', { onChange: e => onScopeChanged( e.target.value ) } ) } 
              type="radio"
              name="scopeType"
              id="scope2"
              value="pageType"
            />
            <label htmlFor="scope2">Type de pages</label>
            <div>
              <Controller
                name={ 'scopeValues' }
                control={control}
                render={({ field }) => (
                  <Select
                    {...field} 
                    className={ scope === 'pageType' ? 'react-select on' : 'react-select' }
                    classNamePrefix="react-select"
                    isMulti
                    options={ instanceConfig.pageTypes ? instanceConfig.pageTypes.map( pageType => ({ label: pageType.label, value: pageType.label }) ) : [] }
                    styles={ getStylesSelectMenu() }
                    components={{
                      IndicatorSeparator: () => null
                    }}
                  />
                )}  
              />    
            </div>
          </div>

          <div className="urls">
            <input 
              { ...register( 'scopeType', { onChange: e => onScopeChanged( e.target.value ) } ) } 
              type="radio"
              name="scopeType"
              id="scope3"
              value="urls"
            />
            <label htmlFor="scope3">
              URLs
              <button 
                type="button"
                className={ scope === 'urls' ? 'add-url on' : 'add-url' }
                onClick={ () => 
                {
                  const currentUrlsClone = [...currentUrls];

                  // push new value to urls
                  currentUrlsClone.push( { 
                    id: ( currentUrlsClone.length > 0 ? Math.max( ...currentUrlsClone.map( url => url.id ) ) + 1 : 0 ), 
                    value: '' 
                  });                  
                  setCurrentUrls( currentUrlsClone );
                }}
              >+</button>
            </label>
            <div 
              className={ scope === 'urls' ? 'list on' : 'list' }
            >
              { currentUrls.map( ( url, index ) => 
                <div className="item" key={index}>
                  <button 
                    type="button"
                    onClick={ () => 
                    {
                      const currentUrlsClone = [...currentUrls];
                      setCurrentUrls( currentUrlsClone.filter( urlClone => urlClone.id !== url.id ) );
                    }}
                    className='bin'
                  >{getPicto( 'Trash', { size: '1rem' } )}</button>
                  <input 
                    { ...register( 'scopeValues.' + index, { onChange: e => setScope( e.target.value ) } ) } 
                    type="text" 
                    id={ url.id }
                    value={ currentUrls.find( ({ id }) => id === url.id ).value }
                    onChange={ e => 
                    {
                      const currentUrlsClone = [...currentUrls];
                      const index = currentUrlsClone.findIndex( urlClone => urlClone === currentUrlsClone.find( ({ id }) => id === url.id ) );

                      // replace value
                      currentUrlsClone[index].value = e.currentTarget.value;

                      setCurrentUrls( currentUrlsClone );
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          <div>
            <input 
              { ...register( 'scopeType', { onChange: e => onScopeChanged( e.target.value ) } ) } 
              type="radio"
              name="scopeType"
              id="scope4"
              value="file"
              />
            <label htmlFor="scope4">Upload fichier</label>
            <input 
              className={ scope === 'file' ? 'on' : '' }
              type="file"
              accept=".csv"
              onChange={ e => loadCsvFile( e.target.files[0] )}
            />
            { csvFile !== null && typeof csvFile === 'string' && csvFile !== '' ?
                <ul>
                  { csvFile.slice( csvFile.indexOf( '\n' ) + 1 ).split( '\n' ).map( ( url, index ) => 
                    <li
                      key={index} 
                      { ...setValue( 'scopeValues.' + index, url ) }
                    >{ url }</li>
                  ) }
                </ul>
                : null
            }
          </div>
        </div>

        {/* Commentaire */}
        <div className="comment">
          <h4>Commentaires</h4>
          <textarea
            { ...register( 'comment' ) } 
            rows="5"
          ></textarea>
        </div>

        {/* errors will return when field validation fails  */}
        {errors.exampleRequired && <span>This field is required</span>}

        <input type="submit" value={'Valider'} />
      </form>
    </ReactTooltip>    
  );
}

export default AddEventsForm;