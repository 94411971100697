/** Dependencies **/
import React, {useState, useRef} from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';
import { updateDatas } from './../../../reducers/datas'

/** Components **/
import Loader from './../../Loader/Loader';

/** Helpers **/
import { callWebservice } from './../../../helpers/webservice/webserviceCaller';
import { updateCommentState } from '../../../helpers/barometer/table';
import { getPicto } from './../../../helpers/pictos';
import { getItem } from './../../../services/LocaleStorage';

function CommentsHistory( props ) 
{
	// Get props
	const commentHistoryID = props.id;
  const label = props.label;
	const date = props.date;
	const accountID = props.accountID;

	// Define ref
	const tooltipCommentsHistory = useRef( null );

  /** Init state **/
	const [history, setHistory] = useState( null );
	const [newContent, setNewContent] = useState( '' );
	const [removeCommentID, setRemoveCommentID] = useState( null );
	const [displayAlert, setDisplayAlert] = useState( false );

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get state from redux store **/
  const datas = useSelector( state => state.datas.value );
  const appPathName = useSelector( state => state.appPathName.value );  
	const selectedFilters = useSelector( state => state.selectedFilters.value );
	const selectedInstance = useSelector( state => state.selectedInstance.value );

	/**
	 * Load comments history
	 */
	// main function
	const loadCommentsHistory = () => 
	{
		if( label.trim() !== '' )
		{
			callWebservice(
				commentHistoryID,
				commentHistoryID,
				'get-comments',
				dispatch,
				selectedInstance,
				{
					entries: [label],
					where: {
						locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
						devices: [selectedFilters.devLoc.deviceValue]
					},
					context: appPathName
				},
				{ function: 'setHistory' }
			);
		}
	}
	
	/**
	 * Publish comment
	 */
	// main function
	const publishComment = () => 
	{
		if( newContent !== '' )
		{
			callWebservice(
				commentHistoryID,
				commentHistoryID,
				'add-comment',
				dispatch,
				selectedInstance,
				{
					entries: [label],
					content: newContent,
					currentDate: date,
					user_id: accountID,
					location: selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue,
					device: selectedFilters.devLoc.deviceValue,
					context: appPathName
				},
				{ function: 'publishCallBack' }
			);
		}
	}

	// publish comment callback
	const publishCallBack = ( result ) => 
	{
		// reset textarea
		setNewContent( '' );

		// reload comments to display new one
		loadCommentsHistory();

		// update global table datas to turn on comments button
		dispatch( updateDatas( { 'globalResult': updateCommentState( result, datas.globalResult ) } ) );
	}

	/**
	 * Remove comment with id
	 * @param {int} id 
	 */
	const removeComment = ( id ) => 
	{
		// set remove comment id
		setRemoveCommentID( id );

		// set alert
		setDisplayAlert( true );
	}

	// Remove confirm false
	const removeResetClick = () => 
  {
    // close popin
    setDisplayAlert( false );
  }

	// Remove confirm true
  const removeConfirmClick = () => 
  {
    // open popin
    setDisplayAlert( false );

		callWebservice(
			commentHistoryID,
			commentHistoryID,
			'remove-comment',
			dispatch,
			selectedInstance,
			{
				entries: [label],
				comment_id: removeCommentID,
				context: appPathName
			},
			{ function: 'removeCallBack' }
		);
  }
	
	// remove callback
	const removeCallBack = ( result ) => 
	{
		// reload comments to display new one
		loadCommentsHistory();

		// update global table datas to turn on comments button
		dispatch( updateDatas( { 'globalResult': updateCommentState( {...result, ...{ nbComments: history[label] }}, datas.globalResult ) } ) );
	}

	return (
		<ReactTooltip 
			id={props.id}
			ref={tooltipCommentsHistory}
			className="tooltip grey comment"
			effect='solid'
			delayHide={200}
			delayShow={0}
			delayUpdate={100}
			afterShow={loadCommentsHistory}
			afterHide={() => {
				tooltipCommentsHistory.current.tooltipRef.style.left = null;
				tooltipCommentsHistory.current.tooltipRef.style.top = null;
			}}
			globalEventOff={'click'}
			isCapture={true}
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        // get width of tooltip
        let width = node.offsetWidth;
        
        return {
          left: - width / 2 - 170,
          top: -127
        };
      }}
		>
			<div className='tooltip-container comment'>

				<Loader 
					loaderID={commentHistoryID} 
					loaderStyle={{ width: '15', stroke: '#e43e21' }} 
					callBackFcts={{
						setHistory: setHistory,
						publishCallBack: publishCallBack,
						removeCallBack: removeCallBack
					}}
					globalCallBack={ undefined } 
				/> 
				
				<h4>Commentaires</h4>

				<div className='publish-container'>
					<textarea 
						onChange={ ( e ) => setNewContent( e.currentTarget.value ) } 
						className='newcontent' 
						placeholder='Saisissez votre commentaire' 
						value={newContent}
					/>
					<button onClick={publishComment} className='publishcontent'>Publier</button>
				</div>

				{(
					history !== null 
					&& history[label]
				) ?
					history[label].map( ( data, index ) =>
						<div className='comment-container' key={index}>
							<p className='who'>{data['first_name'] + ' ' + data['last_name'] + ' le ' + moment( data['date'], 'YYYYMMDD' ).format( 'DD-MM-YYYY' )}</p>
							<div className='content'>
								<p>{data['content']}</p>
								{parseInt( getItem( 'userID' ) ) === data['account_id'] ?
									<button onClick={() => removeComment( data['id'] )}>{getPicto( 'Trash', { size: '1rem' } )}</button>
									: false
								}
							</div>
						</div>							
					):
					false
				}

				{displayAlert === true ?
					<div className='alert'>
						<div className="description">
							{getPicto('ExclamationTriangleFill', { size: "5rem" })}
							<p>ATTENTION !<br /><br />Êtes vous sûr de vouloir supprimer ce commentaire ?</p>
						</div>
						<div className="confirm-container">
							<button className="reset" onClick={() => removeResetClick()}>annuler</button>
							<button className="confirm" onClick={() => removeConfirmClick()}>OK, je confirme</button>
						</div>
					</div>
					: false
				}	

			</div>
		</ReactTooltip>
	);
}

export default CommentsHistory;