/** Dependencies **/
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

/** Class object */
import wsKeywords from './../../../helpers/webservice/wsKeywords.class.js';

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';

/** Components **/
import TableCellCheckbox from './Checkbox';

/** Helpers */
import { togglerClassName } from './../../../helpers/functions';
import { getUpdateOrAutoValue } from './../../../helpers/datas';
import { getPicto } from './../../../helpers/pictos';
import { 
  goTo, 
  getCategories 
} from './../../../helpers/barometer/barometer';

function KeywordsAdminAddKeywords( props ) 
{
  /** Get props **/
  const id = props.id;
  const label = props.value;
  const category1 = props.category1;
  const category2 = props.category2;
  const category3 = props.category3;
  const action = props.action;

  /** Init state */
  const [newLabel, setNewLabel] = useState( '' );

  /** Get state from redux store **/
  const datas = useSelector( state => state.datas.value );
  const filtersDatas = useSelector( state => state.filtersDatas.value );
  const selectedFilters = useSelector( state => state.selectedFilters.value );
  const selectedInstance = useSelector( state => state.selectedInstance.value );

  /** Instance dispatch object **/
  const dispatch = useDispatch();

  /** Instance navigate object */
  const navigate = useNavigate();

  /** Instance wbKeyword object */
  const wsKeywordsObj = new wsKeywords(
    selectedFilters,
    'global',
    dispatch,
    selectedInstance
  )

  /**
   * Valid update keyword
   */
  const validUpdateKeyword = e => 
  {
    // remove class on
    togglerClassName( e.currentTarget, 'on' );
              
    // update keyword value in DB
    updateKeyword() ;
  }

  /**
   * Dispatch update keyword function
   */
  const updateKeyword = () => 
  {
    if( 
      newLabel.trim() !== ''
      && newLabel !== label 
      && !datas.globalResult.map( result => result.label ).includes( newLabel )
    ){
      // update all tables with keyword plain text for key unique key
      wsKeywordsObj.updateKeywords( 
        [ label ],
        newLabel,
        [ category1, category2, category3 ],
        getUpdateOrAutoValue( action, 'actionID' ) === 3 ? 'volume|gsc' : 'volume|serp|gsc',
        'updateLabel'
      );
    }
  }

  /**
   * Cancel deleted keyword
   */
  const resetDeleteKeyword = () => 
  {
    // hide delete validator
    togglerClassName( document.getElementById( 'delete-validator-container-' + id ), 'on' );
  }

  /**
   * Confirm deleted keyword
   */
  const confirmDeleteKeyword = () => 
  {
    // hide delete validator
    togglerClassName( document.getElementById( 'delete-validator-container-' + id ), 'on' );

    wsKeywordsObj.delKeywords( 
      [ newLabel ],
      'updateLabel'
    );
  }

  const filterOnCategoryToCategories = ( categories ) => 
  {
    // got to categories
    goTo( 
      categories, 
      1, 
      filtersDatas, 
      // process.env.PUBLIC_URL + '/categories', 
      null,
      dispatch, 
      navigate 
    )
  }

  /**
   * Update new label with props when has changed
   */
  useEffect( () => setNewLabel( props.value ), [ props.value ] );

  return (
    <div className="keyword-container">
      <div className='delete-validator-container' id={ 'delete-validator-container-' + id }>
        <div className="description">
          {getPicto('ExclamationTriangleFill', { size: "2rem" })}
          <p>Vous vous apprêtez à supprimer le mot clé <b>{newLabel}</b>, cette opération est irréversible ! </p>
        </div>
        <div className="confirm-container">
          <button className="reset" onClick={ () => resetDeleteKeyword() }>annuler</button>          
          <button className="confirm" onClick={ () => confirmDeleteKeyword() }>OK</button>
        </div>
      </div>
      <div className='keyword'>        
        <TableCellCheckbox {...props} />        
        <label htmlFor={'keyword-' + id}>&nbsp;</label>
        <button 
          onClick={ () => togglerClassName( document.getElementById( 'delete-validator-container-' + id ), 'on' ) } 
          className='bin'
        >{getPicto( 'Trash', { size: '1rem' } )}</button>
        <div className='label-container'>
          <div 
            className='label' 
            title={newLabel} 
            onClick={ e => 
            {
              // display update field
              togglerClassName( e.currentTarget.nextSibling, 'on' ) 

              // put focus on update field
              e.currentTarget.nextSibling.focus();
            }}
          >{newLabel}</div>
          <input 
            type="text" 
            onChange={ e => setNewLabel( e.currentTarget.value ) }
            onBlur={ e => validUpdateKeyword( e ) }
            onKeyDown={ e => e.key === 'Enter' ? e.currentTarget.blur() : null }
            value={newLabel} 
          />          
        </div>
      </div>
      {(
        category1 !== undefined 
        && category1 !== '' 
        && category1 !== '-' 
        && category2 !== undefined 
        && category2 !== '' 
        && category2 !== '-'
      )?
      <div className='categories'>
        {category1 !== undefined && category1 !== '' && category1 !== '-' ? 
          <div 
            className='label' 
            title={category1} 
            data-value={category1} 
            onClick={ () => category1 !== 'N.C.' ? filterOnCategoryToCategories( getCategories( filtersDatas.categories, category1 ) ) : false }
          >{'> '}{category1}</div>
          : false
        }
        {category2 !== undefined && category2 !== '' && category2 !== '-' ? 
          <div 
            className='label' 
            title={category2}
            onClick={ () => category2 !== 'N.C.' ? filterOnCategoryToCategories( getCategories( filtersDatas.categories, category1, category2 ) ) : false }            
          >{'>> '}{category2}</div>
          : false 
        }
      </div>
      :''}
    </div>
  );
}

export default KeywordsAdminAddKeywords;