/** Dependencies **/
import React from 'react';

/** Helpers **/
import { getPicto } from './../../../helpers/pictos.js';

function SERPLink( props ) 
{
  /** Get props **/
  let serpHtmlLinkUrl = props.serpHtmlLinkUrl;

  return (
    <div className="serp-viewer-container">
      { serpHtmlLinkUrl !== '' ?
          <button onClick={ () => window.open( serpHtmlLinkUrl, '_blank' ) }>{getPicto( 'Google Viewer' )}</button>
        : false
      }
     </div>
  );
}

export default SERPLink;