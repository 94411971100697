/** Dependencies **/
import React from "react";

/** Helpers **/
import { getPicto } from "./../../../helpers/pictos";

function DefaultSortToolTip ( props )
{
  /** Get props */
  const isSorted = props.column.isSorted;
  const sortType = props.column.sortType;
  const isSortedDesc = props.column.isSortedDesc;

  /** Define label of sort buttons **/
  let label1 = 'Valeurs T de réf.';
  let label2 = 'Variat. en valeurs vs T de comp.';
  if( 
    props.id 
    && props.id.startsWith( 'snippet' )
  ){
    label1 = 'Val. dernier mois T de ref.';
    label2 = 'Valeur dernier mois T. de comp.';
  }

  const updateSortValue = ( sortType ) => 
  {
    props.column.sortType = sortType;
    props.column.toggleSortBy( true );
  }

  return (
    <div className="sort">
      <div className="title"><h4>Trier</h4></div>
      <ul className="preselect sort-type">
        <li>
          <button 
            className={ sortType === 'valueSort' && isSorted === true ? 'on' : '' } 
            onClick={ () => updateSortValue( 'valueSort' ) }
          >{label1}</button>
        </li>
        <li>
          <button 
            className={ sortType === 'variationSort' && isSorted === true ? 'on' : '' } 
            onClick={ () => updateSortValue( 'variationSort' ) }
          >{label2}</button>
        </li>
      </ul>
      <ul className="preselect">
        <li>
          <button 
            className={ isSortedDesc === false && isSorted === true ? 'on reverse' : 'reverse' } 
            onClick={ () => props.column.toggleSortBy( false ) }
          >{getPicto( 'Sort', { size:"1.3rem", transform:"rotate(180)" } )}Du plus petit au plus grand</button>
        </li>
        <li>
          <button 
            className={ isSortedDesc === true && isSorted === true ? 'on reverse' : 'reverse' } 
            onClick={ () => props.column.toggleSortBy( true ) }
          >{getPicto( 'Sort', { size:"1.3rem" } )}Du plus grand au plus petit</button>
        </li>
      </ul>
    </div>
  )
}

export default DefaultSortToolTip;