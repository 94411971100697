/** Dependencies **/
import React, {useState} from 'react';
import { FullScreen } from "react-full-screen";
import { 
  VictoryChart, 
  VictoryLine, 
  VictoryAxis, 
  VictoryGroup,
  VictoryScatter,
  createContainer
} from 'victory';

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';
import { onToggler, offToggler } from './../../../reducers/toggler';

/** Helpers **/
import { rewriteTrendObjectData } from './../../../helpers/barometer/charts.js';

/** Components **/
import TogglerRadioOption from './../DataVizOptions/TogglerRadioOption';
import TogglerCheckboxOption from './../DataVizOptions/TogglerCheckboxOption';
import DataVizTooltipGSC from './../DataVizTooltips/DataVizTooltipGSC';

function DataVizGSC( props, ref ) 
{
  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get state from redux store **/
  const togglers = useSelector( state => state.togglers.value ); 

  /** Define FullScreenHandle and globalTrend ref **/
  const chartFullScreenHandle = props.fullscreenhandle;

  /** Init state **/
  const [gscCheckDisplay, setGSCCheckDisplay] = useState( { impressions: true, ctr: true, position: true, clicks: true } );
    
  /** Create Custom Victory Container for cursor and voronoi **/
  const VictoryCursorVoronoiContainer = createContainer( "voronoi", "cursor" );

  /** Init datas for chart **/
  let currentGSCTrend = [];
  let compareGSCTrend = [];

  // Rewrite array datas
  if( 
    props.currentGSCTrend instanceof Object 
    && Object.keys( props.currentGSCTrend ).length > 0
  ){
    // Rewrite Current GSC Trend datas
    currentGSCTrend = rewriteTrendObjectData( 
      props.currentGSCTrend, 
      togglers['radio-view-option'] ? 'month' : 'week'
    );

    // Rewrite Compare GSC Trend datas
    if( 
      props.compareGSCTrend instanceof Object 
      && Object.keys( props.compareGSCTrend ).length > 0
    )
      compareGSCTrend = rewriteTrendObjectData( 
        props.compareGSCTrend, 
        togglers['radio-view-option'] ? 'month' : 'week'
      );
  };

  // update ctr value to transform to percent
  Object.keys( currentGSCTrend ).map( type => type === 'ctr' ? currentGSCTrend[type].map( value => value.y *= 100 ) : false);
  Object.keys( compareGSCTrend ).map( type => type === 'ctr' ? compareGSCTrend[type].map( value => value.y *= 100 ) : false);

  // update position to 101 if null
  Object.keys( currentGSCTrend ).map( type => type === 'position' ? currentGSCTrend[type].map( value => value.y = value.y === null ?  101 : value.y ) : false);
  Object.keys( compareGSCTrend ).map( type => type === 'position' ? compareGSCTrend[type].map( value => value.y = value.y === null ?  101 : value.y ) : false);

  // Find maxima for normalizing data
  const maxima = Object.keys( currentGSCTrend ).map( dataset => 
    Math.max( ...currentGSCTrend[dataset].map( d => d.y ) )
  );
  
  // Define props for chart
  const colors = {
    impressions: "#71B8F2", 
    ctr: "#61B458",
    clicks: "#A16CB4",
    position: "#FF9D00"
  };

  /**
   * 
   * @param {String} value GSC checkbox
   * @param {DOMELement} element checkbox DOMElement
   */
  const callBackCheckTogglerFn = ( value, element, only=false ) => 
  {
    // get id of element
    const elementID = element.id;

    // clone grpDomainDisplay
    const gscCheckDisplayClone = {...gscCheckDisplay};

    // if only selected => set all checkboxes to false
    if( only === true )      
      Object.keys( gscCheckDisplayClone ).map( label => gscCheckDisplayClone[label] = false )

    if( elementID )
    {
      // set new value
      gscCheckDisplayClone[elementID] = ( value === true && only === false ) ? false : true;

      // update gscCheckDisplay object
      setGSCCheckDisplay( gscCheckDisplayClone );
    }
  }

  return (
    <React.Fragment>
      <div className='dataviz-options-container'>
        <TogglerCheckboxOption 
          id="impressions"
          togglerState={gscCheckDisplay['impressions']} 
          callBackTogglerFn={ callBackCheckTogglerFn } 
          label='impressions' 
          borderColor='#71B8F2'
          backgroundColor='#355B7A'
        />
        <TogglerCheckboxOption 
          id="ctr"
          togglerState={gscCheckDisplay['ctr']} 
          callBackTogglerFn={ callBackCheckTogglerFn } 
          label='ctr' 
          borderColor='#61B458'
          backgroundColor='#34622F'
        />
        <TogglerCheckboxOption 
          id="position"
          togglerState={gscCheckDisplay['position']} 
          callBackTogglerFn={ callBackCheckTogglerFn } 
          label='position' 
          borderColor='#FF9D00'
          backgroundColor='#9B6001'
        />
        <TogglerCheckboxOption 
          id="clicks"
          togglerState={gscCheckDisplay['clicks']} 
          callBackTogglerFn={ callBackCheckTogglerFn } 
          label='clics' 
          borderColor='#A16CB4'
          backgroundColor='#4E305A'
        />
        <span className='separator'>&nbsp;</span>
        <TogglerRadioOption 
          id="radio-compare-option" 
          callBackTogglerFn={ element => dispatch( 
            togglers['radio-compare-option'] ? 
              offToggler( element.id ) 
              : onToggler( element.id ) 
            )
          }
          label="afficher la période de comparaison"
        />
        <span className='separator'>&nbsp;</span>
        <TogglerRadioOption 
          id="radio-view-option" 
          callBackTogglerFn={ element => dispatch( 
            togglers['radio-view-option'] ? 
              offToggler( element.id ) 
              : onToggler( element.id ) 
            )
          } 
          label="Sem. / Mois"
        />
      </div>
      <div className='dataviz-container'>      
        <div className='dataviz-chart gsc' ref={ref}>
          <FullScreen handle={chartFullScreenHandle}>
            {Object.keys( currentGSCTrend ).length > 0 ?
              <VictoryChart
                width={1300}
                height={250}
                minDomain={{y: 0}}
                domainPadding={{x: [30, 30], y: 40}}
                padding={{ 
                  top: 
                    Object.keys( gscCheckDisplay ).filter( item => gscCheckDisplay[item] ).length === 1 
                    && Object.keys( gscCheckDisplay ).filter( item => gscCheckDisplay[item] )[0] === 'position' ? 
                      30 : 0, 
                  bottom: 
                    Object.keys( gscCheckDisplay ).filter( item => gscCheckDisplay[item] ).length === 1 
                    && Object.keys( gscCheckDisplay ).filter( item => gscCheckDisplay[item] )[0] === 'position' ? 
                      0 : 30, 
                  left: 
                    Object.keys( gscCheckDisplay ).filter( item => gscCheckDisplay[item] ).length === 1 ? 
                      30 : 0, 
                  right: 20 
                }}
                containerComponent={
                  <VictoryCursorVoronoiContainer 
                    voronoiBlacklist={["currentLine", "compareLine"]}
                    voronoiDimension="x"
                    cursorDimension="x"
                    labels={ () => ' ' } // space value needed to display label component
                    labelComponent={ <DataVizTooltipGSC /> }  
                  />
                }
              >
                <VictoryAxis
                  tickValues={currentGSCTrend[Object.keys( currentGSCTrend )[0]].map( elem => elem.tickValue )}
                  style={{
                    tickLabels: {
                      fontSize: 6.5,
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      fill: "#626262",
                      angle: -45       
                    },
                    grid: {
                      stroke: '#f5f2f2', 
                      strokeWidth: 1
                    }
                  }}
                  orientation={
                    Object.keys( gscCheckDisplay ).filter( item => gscCheckDisplay[item] ).length === 1
                    && Object.keys( gscCheckDisplay ).filter( item => gscCheckDisplay[item] )[0] === 'position' ? 
                    'top' : 'bottom'
                  }
                />
                { Object.keys( gscCheckDisplay ).filter( item => gscCheckDisplay[item] ).length === 1 ?
                  <VictoryAxis dependentAxis
                    tickFormat={ t => 
                      {
                        let tickFormat = '';

                        tickFormat = t >= 1000 ? 
                          new Intl.NumberFormat( 'fr-FR' ).format( t / 1000 ) + ' k' 
                        : new Intl.NumberFormat( 'fr-FR' ).format( t );

                        tickFormat += Object.keys( gscCheckDisplay ).filter( item => gscCheckDisplay[item] )[0] === 'ctr' ?
                          '%' 
                        : ''

                        return tickFormat;
                      }
                    }
                    offsetX={30}
                    style={{
                      tickLabels: {
                        fontSize: 13,
                        fontFamily: 'Roboto',
                        fontWeight: 300,
                        fill: '#626262'
                      },
                      grid: {
                        stroke: '#f5f2f2', 
                        strokeWidth: 1
                      }
                    }}
                    invertAxis={Object.keys( gscCheckDisplay ).filter( item => gscCheckDisplay[item] )[0] === 'position' ? true : false}                    
                  />
                  : false
                }
                { Object.keys( currentGSCTrend ).map( ( key, index ) =>
                  gscCheckDisplay[key] ?
                    <VictoryGroup key={index}>
                      <VictoryScatter
                        name={"currentScatter" + index}
                        data={currentGSCTrend[key]}
                        style={{
                          data: {
                            fill: colors[key],
                            stroke: colors[key]
                          }
                        }}
                        size={({ active }) => active ? 5 : 3 } 
                        // normalize data
                        y={
                          datum => maxima[index] > 0 ? 
                            Object.keys( gscCheckDisplay ).filter( item => gscCheckDisplay[item] ).length === 1 ? 
                              datum.y
                            : datum.y / maxima[index] 
                          : 0
                        }
                      />
                      <VictoryLine
                        name="currentLine"
                        data={currentGSCTrend[key]}
                        style={{ 
                          data: { 
                            stroke: colors[key]
                          }
                        }}
                        // normalize data
                        y={
                          datum => maxima[index] > 0 ? 
                            Object.keys( gscCheckDisplay ).filter( item => gscCheckDisplay[item] ).length === 1 ? 
                              datum.y
                            : datum.y / maxima[index] 
                          : 0
                        }
                      />
                    </VictoryGroup>
                  : false
                )}
                { togglers['radio-compare-option'] && Object.keys( compareGSCTrend ).length > 0 ?
                  Object.keys( compareGSCTrend ).map( ( key, index ) =>
                    gscCheckDisplay[key] ?
                      <VictoryGroup key={index}>
                        <VictoryScatter
                          name={"compareScatter" + index}
                          data={compareGSCTrend[key]}
                          style={{
                            data: {
                              fill: colors[key],
                              stroke: colors[key],
                              strokeDasharray: 3,
                            }
                          }}
                          size={({ active }) => active ? 5 : 3}  
                          // normalize data
                          y={
                            datum => maxima[index] > 0 ? 
                              Object.keys( gscCheckDisplay ).filter( item => gscCheckDisplay[item] ).length === 1 ? 
                                datum.y
                              : datum.y / maxima[index] 
                            : 0
                          }
                        />
                        <VictoryLine
                          name="compareLine"
                          data={compareGSCTrend[key]}
                          style={{ 
                            data: { 
                              stroke: colors[key],
                              strokeDasharray: 3,
                              strokeWidth: 1
                            }
                          }}
                          // normalize data
                          y={
                            datum => maxima[index] > 0 ? 
                              Object.keys( gscCheckDisplay ).filter( item => gscCheckDisplay[item] ).length === 1 ? 
                                datum.y
                              : datum.y / maxima[index] 
                            : 0
                          }
                        />
                      </VictoryGroup>
                      :false
                    )
                  :false
                }
              </VictoryChart>
              :false
            }
          </FullScreen>
        </div>
      </div>
    </React.Fragment>
	);
}

export default React.forwardRef( DataVizGSC );