/** Dependencies **/
import {useEffect, useState} from 'react';
import Select from 'react-select';

/** Redux **/
import { useSelector } from 'react-redux';

/** Helpers **/
import { 
  getStylesSelectMenu
} from './../../../helpers/ui';

function CategoriesEditor( props ) 
{
  /** Get props */
  const {
    id,
    setCategoriesToParentFct,
    resetField,
    resetFieldFct
  } = props;

  /** Get state from redux store **/
  const filtersDatas = useSelector( state => state.filtersDatas.value );
  
  /** Init state **/
  const [currentCategories1, setCurrentCategories1] = useState( [] );
  const [currentCategories2, setCurrentCategories2] = useState( [] );
  const [currentCategories3, setCurrentCategories3] = useState( [] );
  const [currentCategory1, setCurrentCategory1] = useState( { label: 'N.C.', value: 'N.C.' } );
  const [currentCategory2, setCurrentCategory2] = useState( { label: "N.C.", value: "N.C." } );
  const [currentCategory3, setCurrentCategory3] = useState( { label: "N.C.", value: "N.C." } );
  const [newCategory1, setNewCategory1] = useState( null );
  const [newCategory2, setNewCategory2] = useState( null );
  const [newCategory3, setNewCategory3] = useState( null );
  const [hideClassName, setHideClassName] = useState( 'hide' );

  /**
   * Update values of categories in UI
   * @param {Objects} value {label:..., value:...}
   * @param {int} level 
   */
  const updateCategory = ( value, level ) => 
  {
    switch ( level ) {
      case 1:
        // update category1 with value and reset category2 and category3 or set to Nouvelle categorie if first one is it
        setCurrentCategory1( value );
        setCurrentCategory2( value.value === null ? value : null );
        setCurrentCategory3( value.value === null ? value : null );

        // reset new categories
        setNewCategory1( null );
        setNewCategory2( null );
        setNewCategory3( null );
        break;
      case 2:
        // update category2 with value and reset category3 or set to Nouvelle categorie if first one is it
        setCurrentCategory2( value );
        setCurrentCategory3( value.value === null ? value : null );

        // reset new categories
        setNewCategory2( null );
        setNewCategory3( null );
        break;
      case 3:
        // update category3 with value
        setCurrentCategory3( value );

        // reset new categories
        setNewCategory3( null );
        break;    
      default:
        break;
    }

    // display or hide new categories fields
    if( 
      (
        value instanceof Array
        && value.filter( value => 
          value instanceof Object
          && value?.value !== undefined
          && (
            value.value === null 
            || (
              typeof value.value === 'string'
              && value.value.trim() === ''
            )
          )
        ).length > 0
      ) || (
        value instanceof Object
        && value?.value !== undefined
        && (
          value.value === null 
          || (
            typeof value.value === 'string'
            && value.value.trim() === ''
          )
        )
      )
    )
      setHideClassName( '' );
    else
      setHideClassName( 'hide' );
  }

  /**
   * 
   * @param {Object} categories { currentCategory : { label:..., value:...}, newCategory : { label:..., value:... } }
   * @returns Object { label:..., value:... }
   */
  const getCategorieValue = categories => 
  { 
    let result = { label: null, value: null };

    if( 
      categories?.currentCategory?.value !== undefined
      && categories.currentCategory.value !== null 
      && categories.currentCategory.value.trim() !== '' 
    )
      result = categories.currentCategory;    
    else if( 
      categories?.newCategory?.value !== undefined
      && categories.newCategory.value !== null 
      && categories.newCategory.value.trim() !== '' 
    )
      result = categories.newCategory;

    return result;
  }

  /** Get and update categories */
  useEffect( () => 
  {
    // set current categories
    if( filtersDatas.categories )
    {
      // get categories 1
      setCurrentCategories1([
        ...[{ label: 'Nouvelle Catégorie', value: null }], 
        ...Object.keys( filtersDatas.categories ).sort().map( cat => ({ label: cat, value: cat }) ) 
      ]);

      // get categories 2
      if( 
        currentCategory1 !== null 
        && filtersDatas.categories[currentCategory1.label]?.cat2 !== undefined
      )
        setCurrentCategories2([
          ...[{ label: 'Nouvelle Catégorie', value: null }], 
          ...Object.keys( filtersDatas.categories[currentCategory1.label].cat2 ).sort().map( cat => ({ label: cat, value: cat }) ) 
        ]);
      else
        setCurrentCategories2([ { label: 'Nouvelle Catégorie', value: null } ])

      // get categories 3
      if( 
        currentCategory1 !== null 
        && filtersDatas.categories[currentCategory1.label]?.cat2 !== undefined
        && currentCategory2 !== null
        && filtersDatas.categories[currentCategory1.label].cat2[currentCategory2.label]?.cat3 !== undefined
      )
        setCurrentCategories3([
          ...[{ label: 'Nouvelle Catégorie', value: null }], 
          ...Object.keys( filtersDatas.categories[currentCategory1.label].cat2[currentCategory2.label].cat3 ).sort().map( cat => ({ label: cat, value: cat }) ) 
        ]);   
      else
        setCurrentCategories3([ { label: 'Nouvelle Catégorie', value: null } ])   
    }
  }, [
    currentCategory1,
    currentCategory2,
    currentCategory3,
    filtersDatas.categories
  ])

  /** Send categories values to parent */
  useEffect( () => 
  {
    // set categories
    setCategoriesToParentFct([
      getCategorieValue( { currentCategory: currentCategory1, newCategory: newCategory1 } ),
      getCategorieValue( { currentCategory: currentCategory2, newCategory: newCategory2 } ),
      getCategorieValue( { currentCategory: currentCategory3, newCategory: newCategory3 } )
    ]);

  }, [
    currentCategory1,
    currentCategory2,
    currentCategory3,
    newCategory1,
    newCategory2,
    newCategory3
  ]);

  /** Reset categories fields */
  useEffect( () => 
  {
    // reset action and expected url
    setCurrentCategory1( { label: "N.C.", value: "N.C." } );
    setCurrentCategory2( { label: "N.C.", value: "N.C." } );
    setCurrentCategory3( { label: "N.C.", value: "N.C." } );
    setNewCategory1( null );
    setNewCategory2( null );
    setNewCategory3( null );

    // hide free fields if displayed
    setHideClassName( 'hide' );

    // set reset field to false
    resetFieldFct( false );
        
  }, [ resetField ] );

  return (
    <div className="categories-editor-container">
      <div className='categories-editor'>
        <div className='category-editor'>
          <Select 
            className="react-select"
            classNamePrefix="react-select"
            options={ currentCategories1.length > 0 ? currentCategories1 : [] }
            styles={getStylesSelectMenu()}
            placeholder={ 'Categorie 1' }
            value={ currentCategory1 }
            components={{
              IndicatorSeparator: () => null
            }}
            onChange={ value => updateCategory( value, 1 ) }
          />
          <input 
            type='text'
            id={ 'cat1-new-' + id }
            className={ 'free-field ' + hideClassName }
            onChange={ e => setNewCategory1( e.target.value.trim() !== '' ? {label: e.target.value, value: e.target.value } : null )} 
            value={ newCategory1 !== null ? newCategory1.value : currentCategory1?.value !== undefined && currentCategory1.value !== null ? currentCategory1.value : '' } 
          />        
        </div>
        <div className='category-editor'>
          <Select 
            className="react-select"
            classNamePrefix="react-select"
            options={ currentCategories2.length > 0 ? currentCategories2 : [] }
            styles={getStylesSelectMenu()}
            placeholder={ 'Categorie 2' }
            value={ currentCategory2 }
            components={{
              IndicatorSeparator: () => null
            }}
            onChange={ value => updateCategory( value, 2 ) }
          />
          <input 
            type='text' 
            id={ 'cat2-new-' + id }
            className={ 'free-field ' + hideClassName }
            onChange={ e => setNewCategory2( e.target.value.trim() !== '' ? {label: e.target.value, value: e.target.value } : null )} 
            value={ newCategory2 !== null ? newCategory2.value : currentCategory2?.value !== undefined && currentCategory2.value !== null ? currentCategory2.value : '' } 
          />
        </div>
        <div className='category-editor'>
          <Select 
            className="react-select"
            classNamePrefix="react-select"
            options={ currentCategories3.length > 0 ? currentCategories3 : [] }
            styles={getStylesSelectMenu()}
            placeholder={ 'Categorie 3' }
            value={ currentCategory3 }
            components={{
              IndicatorSeparator: () => null
            }}
            onChange={ value => updateCategory( value, 3 ) }
          />
          <input 
            type='text' 
            id={ 'cat3-new-' + id }
            className={ 'free-field ' + hideClassName }
            onChange={ e => setNewCategory3( e.target.value.trim() !== '' ? {label: e.target.value, value: e.target.value } : null )} 
            value={ newCategory3 !== null ? newCategory3.value : currentCategory3?.value !== undefined && currentCategory3.value !== null ? currentCategory3.value : '' } 
          />        
        </div>
      </div>
    </div>  
  );
}

export default CategoriesEditor;