/** Dependencies **/
import React, { useState, useEffect } from 'react';
import Sticky from 'react-sticky-el'

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { updateDatas } from './../../../reducers/datas';
import { updateFiltersDatas } from './../../../reducers/filtersDatas';
import { updateLoadersTriggers } from './../../../reducers/loadersTriggers';

/** Helpers **/
import { callWebservice } from './../../../helpers/webservice/webserviceCaller';
import { updateActionState, updateCategoriesState, updateLabelState, } from './../../../helpers/barometer/table';
import { updateSelectedHeaderFilters } from './../../../reducers/selectedHeaderFilters';

/** Components **/
import Table from './../../Table/Table';
import Loader from './../../Loader/Loader';
import TopNavCategory from './../../NavFilters/TopNavCategory';
import TopNavDevLoc from './../../NavFilters/TopNavDevLoc';
import AddEventsForm from './../../Forms/AddEventsForm';

/** SCSS **/
import './Admin.scss';

function Admin( props ) 
{
  /** Get props */
  const context = props.context;

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get state from redux store **/
  const selectedFilters = useSelector( state => state.selectedFilters.value );
  const datas = useSelector( state => state.datas.value );
  const selectedInstance = useSelector( state => state.selectedInstance.value );
  const loadersTriggers = useSelector( state => state.loadersTriggers.value );

  /** Init state **/
  const [table, setTable] = useState( null );
  const [resetTrigger, setResetTrigger] = useState( false );

  /** set callback function for each loader **/
  const callbackResultsLoader = ( result, id ) => dispatch( updateDatas( { [id]: result } ) );

  /** set callback function to dispatch result of filters nav data request **/
  const callbackFiltersLoader = ( result ) => dispatch( updateFiltersDatas( result ) );

  /** set callback function to dispatch result of categories **/
  const callbackUpdateCategories = ( result ) => 
  {
    dispatch( updateDatas( { 'globalResult': updateCategoriesState( result, datas.globalResult ) } ) )
    dispatch( updateLoadersTriggers( 'catFilter' ) );
  };

  /** Set callback function to dispatch result for mass action */
  const callbackUpdateActionState = ( result ) =>  dispatch( updateDatas( { 'globalResult': updateActionState( result, datas.globalResult ) } ) );

  /** Set callback function to dispatch result for update keyword / label */
  const callbackUpdateLabel = ( result ) => dispatch( updateDatas( { 'globalResult': updateLabelState( result, datas.globalResult ) } ) );

  /** Load and update category menu current period */  
  useEffect(() => 
  {
    if( 
      selectedFilters.devLoc !== undefined
      && selectedInstance !== undefined
    ){
      // Categories of menu
      callWebservice(
        'global',
        'filters',
        'filters',
        dispatch,
        selectedInstance,
        {
          where: {
            locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
            devices: [selectedFilters.devLoc.deviceValue]
          },
          context: context
        },
        { function: 'filtersLoader' },
        'Mise à jour des catégories'
      );
    }
  }, [ 
    selectedFilters.devLoc,
    context,
    selectedInstance,
    loadersTriggers['catFilter']
  ]);

  /** Load and update data table **/
  useEffect(() => 
  { 
    if( 
      selectedFilters.devLoc !== undefined
      && selectedInstance !== undefined
    ){
      /** Global Result **/
      callWebservice(
        'global',
        'globalResult',
        'data-table',
        dispatch,
        selectedInstance,
        {
          where: {
            categoriesFilter: selectedFilters.categoriesFilter,
            locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
            devices: [selectedFilters.devLoc.deviceValue]
          },
          context: context
        },
        {
          function: 'resultsLoader',
          params: [ 'globalResult' ]
        },
        'Chargement des données'
      );
    }
  }, [
    selectedFilters, 
    context
  ] );

  /** Display Table when data loaded */
  useEffect(() =>
  {
    if( datas.globalResult && resetTrigger === true )
      setTable( 
        <Table 
          datas={ datas.globalResult } 
          sortField="label" 
          sortDesc={false} 
          displayTotal={false}
          displayAddKeyword={ context === 'admin-add-keywords' ? true : false }
        />
      );

  }, [ datas.globalResult, resetTrigger ]);

  /** Reset values if context has changed */
  useEffect( () => 
  {
    // reset datas to null if context has changed
    callbackResultsLoader( null, 'globalResult' );
    callbackResultsLoader( null, 'filterResult' );

    // reset table and dataviz component to null if context has changed
    setTable( null );

    // remove all columns filters
    dispatch( updateSelectedHeaderFilters( { filterName: null, value: null } ) );

    // set reset trigger to on
    setResetTrigger( true );

  }, [ context ]);

  return (
    <React.Fragment>

      <Loader 
        loaderID="global" 
        loaderStyle={{
          width:'66', 
          stroke:'#e43e21', 
          viewBox:'-2 -2 42 42'
        }} 
        callBackFcts={{
          resultsLoader: callbackResultsLoader,
          updateActionState: callbackUpdateActionState,
          filtersLoader: callbackFiltersLoader,
          updateCategories: callbackUpdateCategories,
          updateLabel: callbackUpdateLabel
        }}
        globalCallBack={undefined}
      />

      {/* Top docker */}
      <Sticky className="sticky-container">
        <div className="top-docker">
          <div className="top-docker-title">
            <h2>
              { 
                context === 'admin-add-keywords' ?
                  'Gestion des mots clés'
                : context === 'admin-add-events' ?
                  'Gestion des événements'
                : null                
              }
            </h2>
          </div>
          <div className="top-docker-filters">
            <TopNavCategory />
            <TopNavDevLoc />
          </div>
        </div>
      </Sticky>

      {/* Dashbord Container */}
      <div className="dashboard-container">
        <div className="table-container">
          
          {/** Add Events Form **/}
          <button 
            className="add-event-form" 
            type="button" 
            data-for={'add-event-form'} 
            data-tip
            data-event='click'
            onClick={(event) => {
              // let element = document.getElementById( event.currentTarget.getAttribute('data-for') );
              // if( element !== undefined )
              //   ReactTooltip.show( element );
            }}
          >
            Ajouter un événement
          </button>
          <AddEventsForm />

          {/* Table */}
          {table}
        </div>   
      </div>
    
    </React.Fragment>
  )
}
  
export default Admin;