/** Dependencies **/
import React from "react";
import ReactTooltip from 'react-tooltip';

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedHeaderFilters } from './../../../reducers/selectedHeaderFilters';

/** Components */
import SelectFilter from './Filters/SelectFilter';

/** JSON */
import globalConfig from './../../../assets/json/config.json';

// This is a custom filter UI for selecting
// a unique option from a list
function DefaultSelectFilterToolTip({
  column: { id, filterTitle },
}) 
{
  /** Get state from redux store **/
	const instanceConfig = useSelector( state => state.config.value );

  /** Instance dispatch object **/
  const dispatch = useDispatch();

  /** Get from config **/
  let values = [];
  switch ( id ) {
    case 'pageType':
      values = instanceConfig['pageTypes'].map( type => ( { value: type.id, label: type.label } ) )
      break;  
    default:
      values = globalConfig.barometer[id];
      break;
  }

  /** Calculate the options for filtering **/
  const optionsValues = React.useMemo(() => 
  {
    const options = new Set();
    values.forEach( value => {
      options.add( { value: value.value, label: value.label } );
    })
    return [...options.values()]
  }, [values]);

  // Render a multi-select box
  return (
    <ReactTooltip 
      id={'filter-' + id}
      className="tooltip red"
      effect='solid'      
      delayHide={100}
      delayShow={100}
      delayUpdate={500}
      globalEventOff='click'
      arrowColor="#e43e21"
      offset={{top: 0, left: 100}}
      isCapture={true}
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        // get width of tooltip
        let width = node.offsetWidth;

        // set offset
        let offsetX = 0;
        if( id === 'action' )
          offsetX = -97;

        return {
          left: currentTarget.offsetLeft - (width / 2) + offsetX,
          top: 17
        };
      }}
    >
      <div className={"filter select " + id}>
        <div className="title">{ filterTitle !== '' ? <h4>{ filterTitle }</h4> : '' }</div>
        <SelectFilter
          column={{ id: id }}
          title="" 
          fieldName={id}
          typeFilter={ 'select-' + id }
          optionsValues={optionsValues} 
        />
      </div>

      <div className="confirm-container">
        <button className="reset" onClick={ () => dispatch( updateSelectedHeaderFilters( { filterName: id, value: null } ) )}>annuler</button>
      </div>
    </ReactTooltip>
  )
}

export default DefaultSelectFilterToolTip;