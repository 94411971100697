/** Dependencies **/
import React, {useState, useRef} from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';

/** Components **/
import DataVizChartVolumeTrend from './DataVizCharts/VolumeTrend';
import Loader from './../../Loader/Loader';

/** Helpers **/
import { callWebservice } from './../../../helpers/webservice/webserviceCaller';
import { getInitDateRange } from './../../../helpers/datas'

function VolumeTrend( props ) 
{
	// Get props
	const trendID = "trend-" + props.id;
  const keywords = props.keywords;
	const context = props.context;

	// Define ref
	const tooltipTrend = useRef( null );

  /** Init state **/
  const [datasVizTrend, setDatasVizTrend] = useState( null );

  /** Instance dispatch object **/
	const dispatch = useDispatch();

	/** Get state from redux store **/
  const selectedFilters = useSelector( state => state.selectedFilters.value );
	const selectedInstance = useSelector( state => state.selectedInstance.value );

	/**
	 * Return data for keyword 12 months trend
	 * @param {string} keyword 
	 * @param {int} id 
	 * @returns data for trend
	 */
	const loadTrend = () => 
	{
		if( keywords.length > 0 )
		{
			// get init date range
			let currentPeriod = getInitDateRange( 'currentPeriod' );

			// substring days of current period
			currentPeriod.startdate = moment( currentPeriod.startdate ).startOf( 'month' ).format( 'YYYYMMDD' );
			currentPeriod.enddate = moment( currentPeriod.enddate ).endOf( 'month' ).format( 'YYYYMMDD' );

			callWebservice(
				trendID,
				trendID,
				'volume-trend',
				dispatch,
				selectedInstance,
				{
					period: currentPeriod,
					where: {
						keywords: keywords, 
            locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue]            
          }
				},
				{ function: 'setDatasVizTrend' }
			);
		}
	}

	return (
		<ReactTooltip 
			id={props.id}
			ref={tooltipTrend}
			className="tooltip grey"
			effect='solid'
			delayHide={100}
			delayShow={0}
			delayUpdate={100}
			afterShow={loadTrend}
			afterHide={() => {
				tooltipTrend.current.tooltipRef.style.left = null;
				tooltipTrend.current.tooltipRef.style.top = null;
			}}
			globalEventOff={'click'}
			isCapture={true}
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        // get width of tooltip
        let width = node.offsetWidth;
        
        return {
          left: - width / 2 + 50,
          top: context === 'categories' ? 40 : 50
        };
      }}
		>
			<Loader 
				loaderID={trendID} 
				loaderStyle={{width:'15', stroke:'#e43e21'}} 
				callBackFcts={{
					setDatasVizTrend: setDatasVizTrend
				}}
				globalCallBack={undefined}
			/>
			<h4>Tendance sur les 12 derniers mois</h4>	
			{ datasVizTrend !== null && datasVizTrend.length > 0 ? 
				<DataVizChartVolumeTrend datas={datasVizTrend} />
				: 'Aucune tendance disponible'
			}
		</ReactTooltip>
	);
}

export default VolumeTrend;