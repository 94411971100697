/** Components **/
import TableCellEventType from './../../../components/Table/TableCells/EventType';

export const EventTypeColumn = ( widthColumns, instanceConfig ) => 
{
  const events = instanceConfig.events;

  return {
    accessor: "eventTypeID",
    width: widthColumns['eventTypeID'],
    title: "type d'événement",
    className: "col-label",
    disableSortBy: true,
    disableFilters: true,
    Header: props => "type d'événement",
    Cell: props => 
      <TableCellEventType 
        {...props.row.getToggleRowSelectedProps()} 
        label={ events[props.value]?.label !== undefined ? events[props.value].label : null}
      />
  }
}