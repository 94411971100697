  /** Dependencies **/
import React, {useState, useRef} from 'react';
import ReactTooltip from 'react-tooltip';

/** Redux **/
import { useSelector } from 'react-redux';

/** Helpers **/
import { getPicto } from './../../../helpers/pictos';

/** JSON */
import globalConfig from './../../../assets/json/config.json';

function SnippetDetailsCategory( props ) 
{
	// Get props
  const accessor = props.accessor;
  const id = props.id;
	const snippetID = accessor + '-' + id;
  const keywords = props.keywords;
  const labelCategory = [
    props.category1, 
    props.category2, 
    props.category3
  ].filter( cat => cat !== null ).join( '|' );

  // Define ref
	const tooltipSnippet = useRef( null );

  /** Init state */
  const [popinContent, setPopinContent] = useState( null );

  /** Get state from redux store */
  const snippetsDetails = useSelector( state => state.datas.value.currentSnippetsDetails );

  /** Get snippet type of current snippet group */
  // get groups of snippets from config file
  let snippetsGroups = globalConfig.barometer.snippet_grps;  
  let snippets = globalConfig.barometer.snippets;

  // Get current group snippets values
  const currentSnippetsGroupsName = Object.keys( snippetsGroups ).filter( grpSnippet => 
    snippetsGroups[grpSnippet].accessor === accessor 
  )[0];
  
  // Get title
  const title = snippetsGroups[currentSnippetsGroupsName].title;
  
	// Set popin content
  const buildPopinContent = () => 
  {
    let content = null;

    // get datas and sort by position and typesnippets
    const datas = snippetsDetails.filter( detail => 
      ( 
        detail.label.startsWith( labelCategory + '|' ) 
        || detail.label === labelCategory 
      ) 
      && detail.grpSnippet === currentSnippetsGroupsName
    );

    // get appear hit for each snippet
    let appearSnippets = {};
    datas.forEach( data => 
    {
      if( appearSnippets[data.typeSnippet] )
      {
        if( !appearSnippets[data.typeSnippet].keywords.includes( data.keyword ) ){
          appearSnippets[data.typeSnippet].keywords = [...appearSnippets[data.typeSnippet].keywords, data.keyword];
          appearSnippets[data.typeSnippet].count ++;
        }
      } else {
        appearSnippets[data.typeSnippet] = {
          keywords: [data.keyword],
          count: 1
        } 
      }
    });

    // set content
    content = Object.keys( appearSnippets ).map( ( typeSnippet, index ) => 
      snippets[typeSnippet] ?             
        <tr key={index}>
          <td className="type-snippet">
            {getPicto( typeSnippet, snippets[typeSnippet].tooltip_picto_options )}
            {
              snippets[typeSnippet].label 
              + ' : ' 
              + new Intl.NumberFormat( 'fr-FR', { maximumFractionDigits: 1, style: "percent" }).format( appearSnippets[typeSnippet].count / keywords.length )
            }
          </td>
        </tr>
        : false
    )

    // Set popin content
    setPopinContent( content );
  }

	return (
		<ReactTooltip 
			id={snippetID}
			ref={tooltipSnippet}
			className="tooltip grey"
			effect='solid'
			delayHide={100}
			delayShow={0}
			delayUpdate={100}			
			afterHide={ () => {
				tooltipSnippet.current.tooltipRef.style.left = null;
				tooltipSnippet.current.tooltipRef.style.top = null;
			}}
      afterShow={buildPopinContent}
			globalEventOff={'click'}
      isCapture={true}
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        // get width of tooltip
        let width = node.offsetWidth;
        
        return {
          left: - width / 2 + 50,
          top: 40
        };
      }}
		>
      <div className='tooltip-container'>
        <table>
          <thead>
            <tr>
              <th colSpan="2">
                <h4>{getPicto( currentSnippetsGroupsName, snippetsGroups[currentSnippetsGroupsName].header_picto_options )}{title}</h4>
              </th>
            </tr>
          </thead>
          <tbody>
            {popinContent}
          </tbody>
        </table>        
      </div>
		</ReactTooltip>			
	);
}

export default SnippetDetailsCategory;