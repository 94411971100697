/** Dependencies **/
import React, { useEffect, useState } from "react";

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedHeaderFilters } from './../../../../reducers/selectedHeaderFilters';

/**
 * Define a default UI for Range Filter
 * @param {object} {column: { filterValue, preFilteredRows, setFilter }...}
 * @returns ReactDOM with input
 */
function RangeFilter ( props )
{
  /** Get props **/
  const title = props.title;
  const preselectFilters = props.preselectFilters;
  const fieldName = props.fieldName;
  const typeFilter = props.typeFilter;
  const customFieldsDisp = props.customFieldsDisp;
  const unit = props.unit;
  const id = props.column.id;
  const preFilteredRows = props.column.preFilteredRows;    

  /** Instance dispatch object **/
  const dispatch = useDispatch();

  /** Get state from redux store **/
  const selectedHeaderFilters = useSelector( state => state.selectedHeaderFilters.value );

  // Define status for custom values
  const [customValues, setCustomValues] = useState( [] );
  
  /** Update min and max values **/  
  const [min, max] = React.useMemo(() => 
  {
    let min = '';
    let max = '';

    if( customFieldsDisp !== false )
    {
      const values = preFilteredRows.map( row => 
      {
        let results = null;
        if( row.values[id] )
        {
          if( typeFilter === 'range-presence-seo' )
          {
            if( row.values[id]['position'] )
            {
              results = row.values[id]['position'].map( row => 
                parseInt( row.substring( row.indexOf( '|' ) + 1, row.length ) ) <= 100 
                ? parseInt( row.substring( row.indexOf( '|' ) + 1, row.length ) )
                : false
              ).filter( row => row !== false );
            }

          } else if( typeFilter === 'range-avg-position' ) {
            results = row?.original?.avgPosition !== undefined ? row.original.avgPosition : 101;
          } else if( typeFilter === 'range-nb-keywords' ) {
            results = row?.original?.keywords !== undefined ? row.original.keywords.length : 0;
          } else {
            results = row.values[id]['value'] !== undefined ? row.values[id]['value'] : 0;
          }
        }

        return results;

      }).flat( 1 );

      min = Math.min( ...values, min );
      max = Math.max( ...values, max ); 
    }

    return [min, max];
    
  }, [preFilteredRows]);

  /** Update range values from preselected value of button **/
  const updateRange = ( elem ) =>
  {
    let values = elem.currentTarget.value.split( '|' );
    let label = elem.currentTarget.textContent;

    // get result after split on |
    let result = null;
    if( values.length === 1 ){
      if( parseInt( values[0] ) < 0 )
        result = [min, parseInt( values[0] )];
      else
        result = [parseInt( values[0] ), max];
    } else
      result = [parseInt( values[0] ), parseInt( values[1] )];

    // get selected label if exist
    if( 
      selectedHeaderFilters[id]
      && selectedHeaderFilters[id] instanceof Array
      && selectedHeaderFilters[id].filter( filter => filter.label === label ).length === 1
    )
      // reset current id filter
      dispatch( updateSelectedHeaderFilters( { 
        filterName: id, 
        value: {
          value: null, 
          type: typeFilter,
          field: fieldName
        }
      }));
    else
      // save value into redux store
      dispatch( updateSelectedHeaderFilters( { 
        filterName: id, 
        value: {
          value: {
            result: result, 
            type: typeFilter,
            field: fieldName
          }, 
          label: label, 
          type: 'text'
        }
      }))
  } 

  /** Valid custom fields  **/
  const validCustom = () => 
  {
    if( 
      customValues.length === 2 
      && customValues[0] !== undefined 
      && customValues[1] !== undefined 
    )
      dispatch( updateSelectedHeaderFilters( { 
        filterName: id, 
        value: {
          value: {
            result: customValues, 
            type: typeFilter,
            field: fieldName
          }, 
          label: customValues[0] + ' ▶︎ ' + customValues[1], 
          type: 'text'
        }
      })); 
    else
      dispatch( updateSelectedHeaderFilters( { filterName: id, value: null } ) );
  }

  /** Set custom values for custom input fields **/
  useEffect(() => 
  { 
    let currentValuesFilter = undefined;
    if( 
      selectedHeaderFilters[id] !== undefined 
      && selectedHeaderFilters[id] instanceof Array 
    )
      currentValuesFilter = selectedHeaderFilters[id].filter( filter => 
        filter.value 
        && filter.value.field 
        && filter.value.field === fieldName
      );

    if( 
      currentValuesFilter !== undefined 
      && currentValuesFilter.length === 1 
    )
      setCustomValues([
        currentValuesFilter[0].value.result[0],
        currentValuesFilter[0].value.result[1]
      ])

  }, [selectedHeaderFilters, min, max]);

  return (
    <React.Fragment>

      {title !== '' ? <h5>{title}</h5> : ''}

      {preselectFilters.length > 0 ? 
        <ul className="preselect">
          {preselectFilters.map( (filter, index) => {
            if( filter.value !== 'hr' )
              return (
                <li key={index}>
                  <button 
                    className={( 
                      selectedHeaderFilters[id] 
                      && selectedHeaderFilters[id] instanceof Array
                      && selectedHeaderFilters[id].filter( selectedFilter => 
                        selectedFilter.label === filter.label ).length === 1
                      ) ? "on" : ""
                    } 
                    onClick={ e => updateRange( e )} 
                    value={filter.value}
                  >
                    {filter.label}
                  </button>
                </li>
              );
            else
              return <hr key={index}/>;
          })}
        </ul>
        :''
      }

      {customFieldsDisp !== false ?
        <div className="input-container">
          <div className="first-input-container">
            <label>entre</label>
            <input
              type="number"
              min={min}
              max={max}
              onChange={e => {
                const val = e.target.value;
                setCustomValues((old = []) => [val ? parseInt(val, 10) : undefined, old[1] ? old[1] : max]);
              }}
              placeholder={ min + ( unit !== undefined ? unit : '' )}
              value={ customValues[0] !== undefined ? customValues[0] : '' }
            />
          </div>
          <div className="second-input-container">
            <label>et</label>
            <input
              type="number"
              min={min}
              max={max}
              onChange={e => {
                const val = e.target.value;
                setCustomValues((old = []) => [old[0] ? old[0] : min, val ? parseInt(val, 10) : undefined]);
              }}
              placeholder={ max + ( unit !== undefined ? unit : '' )}
              value={ customValues[1] !== undefined ? customValues[1] : '' }
            />
          </div>
          <div className="confirm-container">
            <button className="confirm" onClick={validCustom}>OK</button>
          </div>
        </div>      
        : false
      }

    </React.Fragment>
  )
}

export default RangeFilter;