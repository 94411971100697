/** Dependencies **/
import React from 'react';

/** Helpers **/
import { getPicto } from './../../../helpers/pictos.js';

/** JSON */
import globalConfig from './../../../assets/json/config.json';

function TitleWithPicto( props ) {

  /** Get props **/
  let id = props.id;

  /** Get picto name **/
  let name = globalConfig.barometer.picto_with_titles[id].name;
  let options = globalConfig.barometer.picto_with_titles[id].options;

  /** Get title **/
  let title = props.title;

  return (
    <React.Fragment>
      {getPicto( name, options )}<h4>{title}</h4>
    </React.Fragment>
  );
}

export default TitleWithPicto;