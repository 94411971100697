/** Dependencies **/
import React from "react";
import ReactTooltip from 'react-tooltip';

/** Redux **/
import { useDispatch } from 'react-redux';
import { updateSelectedHeaderFilters } from './../../../reducers/selectedHeaderFilters';

/** Components */
import SimpleSortToolTip from './../SortToolTips/SimpleSortToolTip';
import DateFilter from './Filters/DateFilter';

// This is a custom filter UI for selecting a date range
function DefaultDateRangeFilterToolTip( props )
{
  /** Get props */
  const {
    filterTitle,
    column
  } = props;

  const id = column.id;

  /** Instance dispatch object **/
  const dispatch = useDispatch();

  return (
    <ReactTooltip 
      id={'filter-' + id}
      className="tooltip red"
      effect='solid'      
      delayHide={100}
      delayShow={100}
      delayUpdate={500}
      globalEventOff='click'
      arrowColor="#e43e21"
      offset={{top: 0, left: 100}}
      isCapture={true}
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        // get width of tooltip
        let width = node.offsetWidth;

        // set offset
        let offsetX = 0;
        if( id === 'action' )
          offsetX = -97;

        return {
          left: currentTarget.offsetLeft - (width / 2) + offsetX,
          top: 17
        };
      }}
    >
      <div className="filter-container">
        <SimpleSortToolTip column={column} id={id} />  
        <div className="filters">
          <div className="title"><h4>Filtrer</h4></div>
          <div className="filter range-date">
            <DateFilter
              id={id}
              label={filterTitle}
              title={filterTitle}
              fieldName={id}
              filterType='range-date'
            />
          </div>
          <div className="confirm-container">
            <button className="reset" onClick={ () => dispatch( updateSelectedHeaderFilters( { filterName: id, value: null } ) )}>annuler</button>
          </div>
        </div>        
      </div>
    </ReactTooltip>
  )
}

export default DefaultDateRangeFilterToolTip;