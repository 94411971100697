/** Dependencies **/
import React, {useState, useEffect} from 'react';
import { FullScreen } from "react-full-screen";

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';
import { onToggler, offToggler } from './../../../reducers/toggler';

/** Components **/
import TogglerRadioOption from './../DataVizOptions/TogglerRadioOption';
import TogglerCheckboxOption from './../DataVizOptions/TogglerCheckboxOption';
import TogglerButtonOption from './../DataVizOptions/TogglerButtonOption';

import DataVizPerfVis from './DataVizPerf/DataVizPerfVis';
import DataVizPerfPos from './DataVizPerf/DataVizPerfPos';
import DataVizPerfAvgPos from './DataVizPerf/DataVizPerfAvgPos';

function DataVizPerf( props, ref ) 
{
  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Define FullScreenHandle and globalTrend ref **/
  const chartFullScreenHandle = props.fullscreenhandle;
  
  /** Get state from redux store */
  const appPathName = useSelector( state => state.appPathName.value );  
  const instanceConfig = useSelector( state => state.config.value );  
  const togglers = useSelector( state => state.togglers.value ); 

  /** Init state **/
  const [checkTogglerValues, setCheckTogglerValues] = useState( {} );
  const [viewSelector, setViewSelector] = useState( appPathName === 'urls' ? 'pos' : 'vis' );
  const [currentColor, setCurrentColor] = useState( null );  
  const [compareColor, setCompareColor] = useState( null );  

  /** Get config values - limit to me if context === url **/
  const grpDomains = appPathName === 'urls' ? 
    instanceConfig.groups.filter( grpDomain => grpDomain.value === 'me' )
    : instanceConfig.groups;
   
  /** Init set display state for groups from config **/
  useEffect( () => 
  {    
    /** init check toggler values */
    let checkTogglerValues = [];

    // init grpDomain with true value from config file
    checkTogglerValues['grpDomainDisplay'] = {};
    grpDomains.forEach( grp => {
      checkTogglerValues['grpDomainDisplay'] = {...checkTogglerValues['grpDomainDisplay'], [grp.value]: true };
    });

    // init page range 
    checkTogglerValues['pageRangesDisplay'] = {
      top1: true,
      top23: true,
      top410: true,
      page2: true,
      page35: true,
      page610: true,
      nb_unranked_keywords: true
    };

    // set value
    setCheckTogglerValues( checkTogglerValues );

    /** Init colors */
    // get selected grp
    const selectedGrp = grpDomains.filter( grpdomain => 
      grpdomain.value === Object.keys( checkTogglerValues['grpDomainDisplay'] ).filter( grp => 
        checkTogglerValues['grpDomainDisplay'][grp] 
      )[0]
    )[0];

    // set current and compare color
    setCurrentColor( selectedGrp.currentColor ? selectedGrp.currentColor : null );
    setCompareColor( selectedGrp.currentColor ? selectedGrp.compareColor : null );

  }, [] );
  
  /**
   * 
   * @param {String} value group name
   * @param {DOMELement} element checkbox DOMElement
   */
  const callBackCheckTogglerFn = ( value, element, only=false ) => 
  {
    // get id of element
    const elementID = element.id;

    // get group ID element
    const groupID = element.getAttribute( 'groupid' );

    // clone grp checkbox values
    const checkTogglerValuesClone = {...checkTogglerValues };

    // if only selected => set all checkboxes to false
    if( only === true )      
      Object.keys( checkTogglerValuesClone[groupID] ).map( label => checkTogglerValuesClone[groupID][label] = false )

    if( elementID )
    {
      // set new value in current object
      checkTogglerValuesClone[groupID][elementID] = ( value === true && only === false ) ? false : true;

      // update check toggler array
      setCheckTogglerValues( checkTogglerValuesClone );
    }
  };

  return (
    <React.Fragment>
      <div className='dataviz-options-container'>
        {
          viewSelector === 'pos'
          && checkTogglerValues['grpDomainDisplay'] 
          && Object.values( checkTogglerValues['grpDomainDisplay'] ).filter( grpVisible => grpVisible ).length === 1 
          && currentColor !== null 
          && compareColor !== null ?
            <React.Fragment>
              <TogglerCheckboxOption 
                id='top1'
                groupID='pageRangesDisplay'
                togglerState={checkTogglerValues['pageRangesDisplay'] ? checkTogglerValues['pageRangesDisplay']['top1'] : false} 
                callBackTogglerFn={ callBackCheckTogglerFn } 
                label='Top 1' 
                borderColor={currentColor}
                backgroundColor={compareColor}
              />
              <TogglerCheckboxOption 
                id='top23'
                groupID='pageRangesDisplay'
                togglerState={checkTogglerValues['pageRangesDisplay'] ? checkTogglerValues['pageRangesDisplay']['top23'] : false} 
                callBackTogglerFn={ callBackCheckTogglerFn } 
                label='Top 2-3' 
                borderColor={currentColor}
                backgroundColor={compareColor}
              />
              <TogglerCheckboxOption 
                id='top410'
                groupID='pageRangesDisplay'
                togglerState={checkTogglerValues['pageRangesDisplay'] ? checkTogglerValues['pageRangesDisplay']['top410'] : false} 
                callBackTogglerFn={ callBackCheckTogglerFn } 
                label='Top 4-10' 
                borderColor={currentColor}
                backgroundColor={compareColor}
              />
              <TogglerCheckboxOption 
                id='page2'
                groupID='pageRangesDisplay'
                togglerState={checkTogglerValues['pageRangesDisplay'] ? checkTogglerValues['pageRangesDisplay']['page2'] : false} 
                callBackTogglerFn={ callBackCheckTogglerFn } 
                label='Page 2' 
                borderColor={currentColor}
                backgroundColor={compareColor}
              />
              <TogglerCheckboxOption 
                id='page35'
                groupID='pageRangesDisplay'
                togglerState={checkTogglerValues['pageRangesDisplay'] ? checkTogglerValues['pageRangesDisplay']['page35'] : false} 
                callBackTogglerFn={ callBackCheckTogglerFn } 
                label='Page 3 à 5' 
                borderColor={currentColor}
                backgroundColor={compareColor}
              />
              <TogglerCheckboxOption 
                id='page610'
                groupID='pageRangesDisplay'
                togglerState={checkTogglerValues['pageRangesDisplay'] ? checkTogglerValues['pageRangesDisplay']['page610'] : false} 
                callBackTogglerFn={ callBackCheckTogglerFn } 
                label='Page 6 à 10' 
                borderColor={currentColor}
                backgroundColor={compareColor}
              />
              <TogglerCheckboxOption 
                id='nb_unranked_keywords'
                groupID='pageRangesDisplay'
                togglerState={checkTogglerValues['pageRangesDisplay'] ? checkTogglerValues['pageRangesDisplay']['nb_unranked_keywords'] : false} 
                callBackTogglerFn={ callBackCheckTogglerFn } 
                label='Non classés' 
                borderColor={currentColor}
                backgroundColor={compareColor}
              />
              <span className='separator'>&nbsp;</span>
            </React.Fragment>
          : false
        }
        { grpDomains.length > 1 ?
            grpDomains.map( ( grp, index ) => 
              <React.Fragment key={index}>
                <TogglerCheckboxOption                   
                  id={grp.value}
                  groupID='grpDomainDisplay'
                  togglerState={checkTogglerValues['grpDomainDisplay'] ? checkTogglerValues['grpDomainDisplay'][grp.value] : false} 
                  callBackTogglerFn={ callBackCheckTogglerFn } 
                  label={grp.label} 
                  borderColor={grp.currentColor}
                  backgroundColor={grp.compareColor}
                />
                <span className='separator'>&nbsp;</span>
              </React.Fragment>
            ):
            false
        }        
        <TogglerButtonOption
          togglerState={viewSelector} 
          callBackTogglerFn={setViewSelector} 
          values={
            appPathName === 'urls' ?
              ['pos', 'avgpos']
            : ['vis', 'pos', 'avgpos']
          }
          labels={
            appPathName === 'urls' ?
              ['position seo', 'position moy.']
            : ['tx occup.', 'position seo', 'position moy.']
          }
        />
        <span className='separator'>&nbsp;</span>
        <TogglerRadioOption 
          id="radio-compare-option" 
          callBackTogglerFn={ element => dispatch( 
            togglers['radio-compare-option'] ? 
              offToggler( element.id ) 
              : onToggler( element.id ) 
            )
          }
          label="afficher la période de comparaison"
        />
        <span className='separator'>&nbsp;</span>
        <TogglerRadioOption 
          id="radio-view-option" 
          callBackTogglerFn={ element => dispatch( 
            togglers['radio-view-option'] ? 
              offToggler( element.id ) 
              : onToggler( element.id ) 
            )
          } 
          label="Sem. / Mois"
        />
      </div>
      <div className='dataviz-container'>
        <div className='dataviz-chart perf' ref={ref}>
          <FullScreen handle={chartFullScreenHandle}>
            { viewSelector === 'vis' ?
              <DataVizPerfVis 
                currentPerfTrend={props.currentPerfTrend}
                comparePerfTrend={props.comparePerfTrend}
                grpDomainDisplay={checkTogglerValues['grpDomainDisplay'] ? checkTogglerValues['grpDomainDisplay'] : {}}
                compareDisplay={togglers['radio-compare-option']}
              />
              : viewSelector === 'pos' ?
              <DataVizPerfPos 
                currentPerfTrend={props.currentPerfTrend}
                comparePerfTrend={props.comparePerfTrend}
                grpDomainDisplay={checkTogglerValues['grpDomainDisplay'] ? checkTogglerValues['grpDomainDisplay'] : {}}
                pageRangesDisplay={checkTogglerValues['pageRangesDisplay'] ? checkTogglerValues['pageRangesDisplay'] : {}}
                compareDisplay={togglers['radio-compare-option']}
              />
              : 
              <DataVizPerfAvgPos 
                currentPerfTrend={props.currentPerfTrend}
                comparePerfTrend={props.comparePerfTrend}
                grpDomainDisplay={checkTogglerValues['grpDomainDisplay'] ? checkTogglerValues['grpDomainDisplay'] : {}}
                compareDisplay={togglers['radio-compare-option']}
              />
            }          
          </FullScreen>
        </div>
      </div>
    </React.Fragment>
	);
}

export default React.forwardRef( DataVizPerf );