/** Components **/
import DefaultSelectFilterToolTip from './../../../components/Table/FilterToolTips/DefaultSelectFilterToolTip';
import TableHeaderTitleWithPicto from './../../../components/Table/TableHeaders/TitleWithPicto';
import TableCellPageTypes from './../../../components/Table/TableCells/PageTypes';

export const PageTypeColumn = ( widthColumns ) => 
{
  return {
    accessor: "pageType",
    width: widthColumns["pageType"],
    title: "Type de page",
    className: "col-page-type col-center",
    Filter: DefaultSelectFilterToolTip,
    filter: 'multiFieldsCustomFilter',
    filterTitle: "Filtrer",
    sortDescFirst: true,
    sortType: 'pageTypeSort',
    Header: props => 
      <TableHeaderTitleWithPicto 
        id={props.column.id} 
        title={props.column.title} 
      />,
    Total: '',
    Cell: props => 
      <TableCellPageTypes 
        {...props.row.getToggleRowSelectedProps()} 
        id={props.row.id} 
        label={props.row.original.label}
        lastUpdate={
          props?.value?.lastUpdate !== undefined ? 
            props.value.lastUpdate 
            : null
        } 
        usersIDs={
          props?.value?.usersIDs !== undefined ? 
            props.value.usersIDs 
            : null
        }
        automaticPageTypeID={
          props?.value?.automaticPageTypeID !== undefined 
          && props.value.automaticPageTypeID !== null ? 
            props.value.automaticPageTypeID 
            : null
        } 
        updatedPageTypeID={
          props?.value?.updatedPageTypeID !== undefined 
          && props.value.updatedPageTypeID !== null ? 
            props.value.updatedPageTypeID 
            : null
        }
      />
  }
}