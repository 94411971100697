/** Dependencies **/
import React, { useEffect, useState } from 'react';
import moment from 'moment';

/** Redux **/
import { useSelector } from 'react-redux';

/** Helpers **/
import { getLastSnippetsUpdateDate } from './../../../helpers/datas';

function SERP() 
{
  /** Get state from redux store **/
  const datas = useSelector( state => state.datas.value );
  const togglers = useSelector( state => state.togglers.value ); 

  /** Init state **/
  const [lastSnippetsUpdateDate, setLastSnippetsUpdateDate] = useState( null );

  /** Display last and next snippets update date */
  useEffect(() => 
  {
    if( datas.currentSERPTrend )
    {
      // get last snippets update date from datas
      let lastDate = getLastSnippetsUpdateDate( datas );

      // set value with month
      if( lastDate !== null && Math.abs( lastDate ) !== Infinity )
        setLastSnippetsUpdateDate( moment( lastDate.toString() ).format( 
          togglers['radio-view-option'] ? 'MMMM YYYY' : 'DD MMMM YYYY'
        ));
    }

  }, [ datas.currentSERPTrend ]);

  return (
    <React.Fragment>
      <h4>résultats google {lastSnippetsUpdateDate !== null ? ' - ' + lastSnippetsUpdateDate : ''}</h4>
    </React.Fragment>
  );
}

export default SERP;