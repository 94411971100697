/** Dependencies **/
import React from "react";

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedDataVizTab } from "./../../../reducers/selectedDataVizTab";

/** Helpers **/
import { getPicto } from './../../../helpers/pictos';

function VolumeTab ( props )
{
  /** Get props */
  const {
    currentVolumeTrend,
    compareVolumeTrend
  } = props;

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get current selected tab **/
  const selectedDataVizTab = useSelector( state => state.selectedDataVizTab.value );
  const classON = selectedDataVizTab === 'volume' ? 'on' : '';

  // get current trend
  let currentVolume = 0;
  if( currentVolumeTrend && currentVolumeTrend.length > 0 )
    currentVolume = currentVolumeTrend.reduce( (a, b) => a + parseInt( b.y ), 0 );

  // get compare trend
  let compareVolume = 0;
  if( compareVolumeTrend && compareVolumeTrend.length > 0 )
    compareVolume = compareVolumeTrend.reduce( (a, b) => a + parseInt( b.y ), 0 );

  // set variation
  let variationInt = null;
  if( compareVolume !== 0 ) 
    variationInt = currentVolume - compareVolume;
  
  return (
    <div className={'dataviz-tab volume ' + classON} onClick={ () => dispatch( updateSelectedDataVizTab( 'volume' ) ) }>
      <div className="title"><h4>marché</h4></div>
      <div className="values-container">
        <div className="values-parts-container">
          <div className="values-part top">{getPicto( 'BarChartAlt', {"size": "1.1rem"} )}<h5>volume</h5></div>
          <div className="values-part bottom">          
            <div className="value">{Intl.NumberFormat('fr-FR').format( currentVolume )}</div>
            {variationInt !== null ? 
              <div className="variation">&nbsp;{ variationInt > 0 ? '+' : '' }{Intl.NumberFormat('fr-FR').format( variationInt )}</div>
              : false
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default VolumeTab;