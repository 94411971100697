/** Dependencies **/
import React, { useEffect, useState } from "react";

/** Redux **/
import { useSelector } from 'react-redux';

/** Helpers **/
import { getPicto } from "./../../../helpers/pictos";

function PerfSortToolTip ( props )
{
  /** Get props */
  const isSorted = props.column.isSorted;

  /** Get state from redux store */
  const appPathName = useSelector( state => state.appPathName.value );  
  
  /** Init state **/
  const [sortType, setSortType] = useState( null );
    
  const toggleSortBy = ( value ) => 
  {
    // set default sort Type if not defined
    if( sortType === null )
    {
      if( appPathName === 'urls' )  
        setSortType( 'avgPositionSort' )
      else
        setSortType( 'bestPositionSort' );
    }

    // apply sort order
    props.column.toggleSortBy( value );
  }

  /** Sort column with new sort Type **/
  useEffect(() => 
  {
    if( sortType !== null )
    {
      // set sort type with default if not avgPositionSort of bestposition
      props.column.sortType = sortType;

      // sort column
      if( props.column.toggleSortBy )
        props.column.toggleSortBy( props.column.isSortedDesc );
    }

  }, [sortType]);

  return (
    <div className="sort">
      <div className="title"><h4>Trier</h4></div>
      <ul className="preselect sort-type">
        <li>
          <button 
            className={ ( sortType === 'bestPositionSort' || sortType === 'avgPositionSort' ) && isSorted === true ? 'on' : '' } 
            onClick={ () =>               
              appPathName === 'urls' ? 
                setSortType( 'avgPositionSort' ) 
                : setSortType( 'bestPositionSort' )
            }
          >Val. dernier mois T de ref.</button>
        </li>

        { appPathName === 'urls' ? 
          <li>
            <button 
              className={ sortType === 'nbRankedKeywordsSort' && isSorted === true ? 'on' : '' } 
              onClick={ () => setSortType( 'nbRankedKeywordsSort' ) }
            >Nb. de mots clés positionnés</button>
          </li>
          : false
        }

        { appPathName === 'keywords' ? 
          <React.Fragment>
            <li>
              <button 
                className={ sortType === 'variationPosSort' && isSorted === true ? 'on' : '' } 
                onClick={ () => setSortType( 'variationPosSort' ) }
              >Variat. en valeurs vs T de comp.</button>
            </li>
          </React.Fragment>
          : false
        }
      </ul>
      <ul className="preselect">
        <li>
          <button 
            className={ props.column.isSortedDesc === false && isSorted === true ? 'on reverse' : 'reverse' } 
            onClick={ () => toggleSortBy( false ) }
          >{getPicto( 'Sort', { size:"1.3rem", transform:"rotate(180)" } )}Du plus petit au plus grand</button>
        </li>
        <li>
          <button 
            className={ props.column.isSortedDesc === true && isSorted === true ? 'on reverse' : 'reverse' } 
            onClick={ () => toggleSortBy( true ) }
          >{getPicto( 'Sort', { size:"1.3rem" } )}Du plus grand au plus petit</button>
        </li>
      </ul>
    </div>
  )
}

export default PerfSortToolTip;