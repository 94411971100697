/** Components **/
import DefaultRangeFilterToolTip from './../../../components/Table/FilterToolTips/DefaultRangeFilterToolTip';
import TableHeaderTitleWithPicto from './../../../components/Table/TableHeaders/TitleWithPicto';
import GSCPositionTotalCell from './../../../components/Table/TableTotalCells/GSCPositionTotalCell';
import TableCellGSC from './../../../components/Table/TableCells/GSC';

export const GscPositionColumn = ( widthColumns ) => 
{
  return {
    accessor: "gsc.position",
    width: widthColumns["position"],
    title: "pos.",
    className: "col-gsc",
    Filter: DefaultRangeFilterToolTip,
    filter: 'multiFieldsCustomFilter',
    filterTitle: 'position',
    sortDescFirst: true,          
    sortType: 'valueSort',
    Header: props => 
      <TableHeaderTitleWithPicto id={props.column.id} title={props.column.title} />,
    Total: props => 
      <GSCPositionTotalCell id={props.column.id} />,
    Cell: props => 
      <TableCellGSC 
        id={props.cell.row.id}
        values={props.value} 
        accessor={props.column.id} 
        label={props.cell.row.values.label}
        keywords={props.cell.row.original.keywords}
      />
  }
}