import moment from 'moment';

/** SCSS **/
import './KeywordCreatedDate.scss';

function KeywordCreatedDate( props ) 
{
  return (
    <div className="identifier-date-container">
      <p className='value'>{ moment().format( 'DD - MMM - YY' ) }</p>
    </div>
  );
}

export default KeywordCreatedDate;