/** Components **/
import DefaultRangeFilterToolTip from './../../../components/Table/FilterToolTips/DefaultRangeFilterToolTip';
import TableHeaderTitleWithPicto from './../../../components/Table/TableHeaders/TitleWithPicto';
import DefaultSumTotalCell from '../../../components/Table/TableTotalCells/DefaultSumTotalCell';
import TableCellGSC from './../../../components/Table/TableCells/GSC';

export const GscClicksColumn = ( widthColumns ) => 
{
  return {
    accessor: "gsc.clicks",
    width: widthColumns["clicks"],
    title: "clics",
    className: "col-gsc",
    Filter: DefaultRangeFilterToolTip,
    filter: 'multiFieldsCustomFilter',
    filterTitle: 'volume de clics',
    sortDescFirst: true,
    sortType: 'valueSort',          
    Header: props => 
      <TableHeaderTitleWithPicto id={props.column.id} title={props.column.title} />,
    Total: props => 
      <DefaultSumTotalCell id={props.column.id} />,
    Cell: props => 
      <TableCellGSC 
        id={props.cell.row.id}
        values={props.value} 
        accessor={props.column.id} 
        label={props.cell.row.values.label}
        keywords={props.cell.row.original.keywords}
      />
  }
}