/** Dependencies **/
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { addHiddenColumns, removeHiddenColumns } from './../../reducers/hiddenColumns';

/** SCSS **/
import './FilterColumns.scss';

/** JSON */
import globalConfig from './../../assets/json/config.json';

function FilterColumns() 
{
  /** Instance dispatch object **/
	const dispatch = useDispatch();

  // get columns which can filtered
  const columnsFilter = globalConfig.barometer.columns_filters;  

  /** Init state **/
  const [currentHiddenColumns, setCurrentHiddenColumns] = useState( [] );
  const [currentAppPath, setCurrentAppPath] = useState( null );

  // Get state from redux store
  const hiddenColumns = useSelector( state => state.hiddenColumns.value );
  const appPathName = useSelector( state => state.appPathName.value );
    
  /**
   * check all subcolumn with value of header column
   * @param {event} e Event change
   */
  const handleColumChange = ( e ) => 
  {
    const currentInput = e.currentTarget;
    const currentCheck = currentInput.checked;
    const targetChildsList = currentInput.parentNode.querySelectorAll('ul li input');
    const targetChilds = Array.from( targetChildsList );

    // click on each input under current header column
    targetChilds.forEach( input => 
    {
      if( input.checked !== currentCheck )
        input.click();
    });
  }

  /**
   * Hide or Show current column / update state of header column class and check
   * @param {event} e Event change
   */
  const handleSubColumnChange = ( e ) => 
  {
    const currentSubCol = e.currentTarget;
    const currentCheck = currentSubCol.checked;
    const parentCol = currentSubCol.parentNode.parentNode.parentNode.firstChild;
    const totalSubCol = currentSubCol.parentNode.parentNode.childNodes;
    const totalSubColChecked = currentSubCol.parentNode.parentNode.querySelectorAll( "input:checked" );

    // update class and checked value for header column
    parentCol.className = "";
    parentCol.checked = false;
    if( totalSubColChecked.length === totalSubCol.length )
      parentCol.checked = "checked";
    else if( totalSubColChecked.length > 0 && totalSubColChecked.length < totalSubCol.length )
      parentCol.className = "partial";

    // currentSubCol.value is split on "," because it's possible to set an array in id value of config file columns_filters to group columns
    if( currentCheck === false )
      dispatch( addHiddenColumns( currentSubCol.value.split(',') ));
    else
      dispatch( removeHiddenColumns( currentSubCol.value.split(',') ));
  }

  /**
   * return object with values for classname and checked col
   * @param {array} column 
   * @returns {object} {'classname': '', 'checked': true}
   */
  const getColProps = ( subColumn ) => 
  {
    let result = {'classname': '', 'checked': false};

    // count checked values for current column
    let columnsFilterChecked = subColumn.filter( subcol => 
    {
      // subcold.id can be an array when column is a group
      if( subcol.id instanceof Array )
        return subcol.id.filter( subcol => !hiddenColumns.includes( subcol ) ).length > 0
      else 
        return ! hiddenColumns.includes( subcol.id );
    });

    // init result value
    if( columnsFilterChecked.length > 0 && columnsFilterChecked.length < subColumn.length )
      result.classname = 'partial';
    else if ( columnsFilterChecked.length > 0 && columnsFilterChecked.length === subColumn.length )
      result.checked = true;

    return result;
  }

  const checkOnly = ( event ) => 
  {
    const element = event.currentTarget;
    const value = element.value;
    const allSelectedColumns = Array.from( element.parentNode.parentNode.querySelectorAll('input[type="checkbox"]:checked') );
    const currentSelectedColumn = element.previousSibling.previousSibling;

    // click on all selected snippet button except current if already selected to unselect them
    allSelectedColumns.forEach( buttonElement => {
      if( buttonElement.value !== value )
        buttonElement.click();
    });

    // click on current button if parent li not contains class "on"
    if( !currentSelectedColumn.checked )
      currentSelectedColumn.click();
  }

  // Init and update currentAllColumns for check input in UI from redux allColumn
  useEffect( () => 
  {
    if( hiddenColumns )
      setCurrentHiddenColumns( hiddenColumns );

  },[hiddenColumns]);

  // Init and update app path for check context from redux
  useEffect( () => 
  {
    if( appPathName )
      setCurrentAppPath( appPathName );

  },[appPathName]);
  
  return (
    <ReactTooltip 
      id={'column-filter'}
      className="tooltip red"
      effect='solid'
      place='bottom'
      delayHide={100}
      delayShow={100}
      delayUpdate={500}
      globalEventOff='click'
      arrowColor="transparent"
      isCapture={true}
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        // get width of tooltip
        let width = node.offsetWidth;
        return {
          left: currentTarget.offsetLeft - (width / 2) - 81,
          top: currentTarget.offsetHeight + 5
        };
      }}
    >
      <div className="filter columns">
        <div className="title"><h4>Afficher les colonnes :</h4></div>        
        <ul>
          {currentAppPath !== null ?
            Object.keys(columnsFilter[appPathName]).map( (column, index) =>
              <li key={index}>
                <input 
                  id={"column-"+index} 
                  type="checkbox"
                  checked={getColProps( columnsFilter[appPathName][column] )['checked']}
                  className={getColProps( columnsFilter[appPathName][column] )['classname']}
                  onChange={(e) => handleColumChange(e)} 
                />
                <label htmlFor={"column-"+index}>{column}</label>
                {columnsFilter[appPathName][column].length > 0?
                  <ul>
                    {columnsFilter[appPathName][column].map( (subcolumn, subindex) => 
                      <li key={subindex}>
                        <input 
                          id={"column-"+index+"-"+subindex} 
                          type="checkbox" 
                          value={subcolumn.id} 
                          checked={
                            // subcolumn.id can be an array when itid is a group of column in config file
                            (subcolumn.id instanceof Array)?
                            (subcolumn.id.filter( subcol => !(currentHiddenColumns.includes( subcol )) ).length > 0)
                            :currentHiddenColumns.includes( subcolumn.id )?false:true
                          }
                          onChange={(e) => handleSubColumnChange( e )} 
                        />
                        <label htmlFor={"column-"+index+"-"+subindex}>{subcolumn.label}</label>
                        <button 
                          className="column-only"
                          value={subcolumn.id}
                          onClick={e => checkOnly( e )}
                        >seulement</button>
                      </li>
                    )}
                  </ul>
                :''}
              </li>
            )
            : false
          }
        </ul>
      </div>
    </ReactTooltip>
  )
}

export default FilterColumns;