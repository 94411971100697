/** Dependencies **/
import React from "react";
import { useSearchParams } from "react-router-dom";

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedHeaderFilters } from './../../../reducers/selectedHeaderFilters';

/** Helpers **/
import { getPicto } from './../../../helpers/pictos';
import { truncateString } from './../../../helpers/functions';

/** JSON */
import globalConfig from './../../../assets/json/config.json';

function StringFilterRemove ( props )
{
  /** Instance dispatch object **/
  const dispatch = useDispatch();

  /** Get from props **/
  const id = props.id;
  const value = props.value;
  const label = props.label;
  const title = props.title;

  /** Init Search Params */
  const [searchParams, setSearchParams] = useSearchParams();

  /** Get state from redux store **/
  const appPathName = useSelector( state => state.appPathName.value );

  /** Get type */
  let type = null;
  if( value.type )
    type = value.type;

  /** Set default filter label **/
  let filterLabel = value;
  if( value.result )
    filterLabel = value.result; 

  /** Set default title and truncate if it's in reminder row  **/
  let filterTitle = truncateString( title, 3, '.' );

  /** Set filter label if cases **/
  /* select type filter */
  if( type !== null && type.startsWith( 'select' ) )
  {
    // value.result is an array = 1
    if( 
      value.result instanceof Array 
      && value.result.length === 1 
    ){
      if(
        globalConfig.barometer[id] !== undefined
        && globalConfig.barometer[id] instanceof Array 
        && globalConfig.barometer[id].find( config => config.value === value.result[0] )
      )
        filterLabel = filterTitle + ' ' + globalConfig.barometer[id].find( config => config.value === value.result[0] ).label;
      else if (
        globalConfig.barometer[id] !== undefined
        && globalConfig.barometer[id][appPathName] !== undefined
        && globalConfig.barometer[id][appPathName] instanceof Array 
        && globalConfig.barometer[id][appPathName].find( config => config.value === value.result[0] )
      )
        filterLabel = filterTitle + ' ' + globalConfig.barometer[id][appPathName].find( config => config.value === value.result[0] ).label;
      else
        filterLabel = filterTitle + ' ' + value.result[0];

    // value.result is an array > 1
    } else if ( 
      value.result instanceof Array 
      && value.result.length > 1
    ){
      filterLabel = filterTitle;
    
    // value.result is a string
    } else
      filterLabel = filterTitle + ' ' + label;
      
  /* range type filter */
  } else if ( type !== null && type.startsWith( 'range' ) )
    filterLabel = filterTitle + ' ' + label.replace( '▶︎', '>' );

  /* string type filter */
  else if ( type !== null && type.startsWith( 'string' ) )
    filterLabel = filterTitle + ' ' + label;

  /** Get picto with filter type **/
  let filterTypePicto = getPicto( 'Filter', { size:"0.8rem" } );
  if( type !== null && type.startsWith( 'string' ) )
    filterTypePicto = getPicto( 'MagnifyingGlass', { size:"1rem" } );

  /**
   * Update redux filters with delete selected one
   * @param {String} id 
   */
  const handleClick = ( id ) => 
  {
    // Set value
    let removeValue = null;
    if( value.field )
      removeValue = {value: null, field: value.field};

    // reset current id filter
    dispatch( updateSelectedHeaderFilters( { filterName: id, value: removeValue } ) ); 

    // remove url filter param
    searchParams.delete( 'filters' );
    setSearchParams( searchParams );
  }

  return (
    <div className='column-header-reminder'>
      <div className="label">{ filterTypePicto ? filterTypePicto : '' }{filterLabel}</div>
      <button onClick={() => handleClick( id )}>{getPicto( 'closer', { size:"1rem" } )}</button>
    </div>
  )
}

export default StringFilterRemove;