/** Dependencies **/
import { useState } from 'react';
import Popup from 'reactjs-popup';

/** Class object */
import wsActions from './../../../helpers/webservice/wsActions.class.js';
import wsKeywords from './../../../helpers/webservice/wsKeywords.class.js';

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { updateDatas } from './../../../reducers/datas';

/** Components **/
import TableEditorCellKeywords from './../../../components/Table/TableEditorCells/Keywords';
import TableEditorCellCreatedDate from './../../../components/Table/TableEditorCells/KeywordCreatedDate';
import TableEditorCellActions from './../../../components/Table/TableEditorCells/Actions';
import TableEditorCellCategories from './../../../components/Table/TableEditorCells/Categories';

/** Helpers **/
import { getCurrentDate } from './../../../helpers/functions';
import { getPicto } from './../../../helpers/pictos';
import { callWebservice } from './../../../helpers/webservice/webserviceCaller';
import { getItem } from './../../../services/LocaleStorage';

export const AddKeyword = ( props ) => 
{
  const {
    columnsWidth
  } = props;

  /** Init state **/
  const [decisionsValues, setDecisionsValues] = useState( null );
  const [keyword, setKeyword] = useState( null );
  const [categories, setCategories] = useState( null );
  const [resetField, setResetField] = useState( false );
  const [openPopin, setOpenPopin] = useState( false );
  const [errorKeyword, setErrorKeyword] = useState( '' );

  /** Get state from redux store **/
  const datas = useSelector( state => state.datas.value );
  const selectedFilters = useSelector( state => state.selectedFilters.value );
  const selectedInstance = useSelector( state => state.selectedInstance.value );

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Instance Webservice Actions class */
  const wsActionsClass = new wsActions(
    selectedFilters,
    'global',
    datas.globalResult,
    dispatch,
    selectedInstance
  );

  /** Instance Webservice Keywords class */
  const wsKeywordsClass = new wsKeywords(
    selectedFilters,
    'global',
    dispatch,
    selectedInstance
  )

  const addKeywordDB = () => 
  {
    if( 
      keyword.trim() !== ''
      && !datas.globalResult.map( result => result.label ).includes( keyword )
      && categories.filter( category => category.value !== null ).length === 3
    ){
      // get current date
      const currentDateFormatDay = getCurrentDate( "YYYYMMDD" );
      const currentDateFormatSecond = getCurrentDate( "YYYYMMDDHHmmss" );

      /** Set entry for global result */
      const newEntry = {
        category1: categories[0].value,
        category2: categories[1].value,
        category3: categories[2].value,
        label: keyword,
        keywords: [ keyword ],
        createdDate: currentDateFormatDay
      }

      /** Add keyword */
      wsKeywordsClass.addKeywords( 
        [ keyword ], 
        categories.map( category => category.value ), 
        decisionsValues.action === null || parseInt( decisionsValues.action.value ) !== 3 ? 'volume|serp|gsc' : 'volume|gsc'        
      );

      /** Add action */
      if( decisionsValues.action !== null )
      {
        // For keywords context
        wsActionsClass.addActions(
          [ keyword ], 
          parseInt( decisionsValues.action.value ),
          'keywords'
        )

        // For gsc-keywords context
        wsActionsClass.addActions(
          [ keyword ], 
          parseInt( decisionsValues.action.value ) !== 3 ? 7 : 8,
          'gsc-keywords'
        )

        // add action to new entry
        newEntry.action = {
          lastUpdate: currentDateFormatSecond,
          usersIDs: [ getItem( 'userID' ) ],
          automaticActionID: null,
          updatedActionID: parseInt( decisionsValues.action.value )
        };
      }
        
      /** Add expected url */
      if( 
        decisionsValues.expectedUrl.value !== null 
        && decisionsValues.expectedUrl.type !== null 
      ){
        // add expected url to BD
        callWebservice(
          'global',
          'add-expected-url',
          'add-expected-url',
          dispatch,
          selectedInstance,
          {
            keywords: [ keyword ],
            expectedUrl: decisionsValues.expectedUrl.value,
            typeExpectedUrl: decisionsValues.expectedUrl.type,
            currentDate: currentDateFormatSecond,
            user_id: getItem( 'userID' ),
            device: selectedFilters.devLoc.deviceValue,
            location: selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue
          }
        );

        // add expected url to new entry
        newEntry.expectedUrl = {
          lastUpdate: currentDateFormatSecond,
          usersIDs: [ getItem( 'userID' ) ],
          automaticExpectedUrl: null,
          updatedExpectedUrl: decisionsValues.expectedUrl.value,
          type: decisionsValues.expectedUrl.type
        }
      }

      // update global result with new keyword
      dispatch( updateDatas( { globalResult: [ ...datas.globalResult, newEntry ] } ) );

      // reset field 
      setResetField( true );

    } else {

      if( keyword.trim() === '' )
        setErrorKeyword( 'Merci de renseigner un mot clé.' );
      else if( datas.globalResult.map( result => result.label ).includes( keyword ) )
        setErrorKeyword( 'Le mot clé existe déjà.' );
      else if( categories.filter( category => category.value !== null ).length !== 3 )
        setErrorKeyword( 'Le mot clé doit être catégorisé.' );

      setOpenPopin( true );
    }
  }

  return (
    <tr className='keyword-editor'>
      <th className='col-label' width={columnsWidth[0] + '%'} >
        <div className="keyword-container">
          <TableEditorCellKeywords 
            setKeywordToParentFct={ setKeyword } 
            resetField={ resetField } 
            resetFieldFct={ setResetField }
          />
          <button onClick={ addKeywordDB }>OK</button>
          <Popup 
            open={openPopin} 
            onClose={() => setOpenPopin( false )} 
            position="right center"
          >
            {getPicto( 'ExclamationTriangleFill', { size: '2rem' } )}
            <p>{ errorKeyword }</p>
          </Popup>
        </div>
      </th>
      <th className='col-created-date col-center' width={columnsWidth[1] + '%'} >
        <TableEditorCellCreatedDate />
      </th>
      <th className='col-decisions col-center' width={columnsWidth[2] + '%'} >
        <TableEditorCellActions 
          setDecisionsToParentFct={ setDecisionsValues } 
          resetField={ resetField } 
          resetFieldFct={ setResetField }
        />
      </th>
      <th className='col-categories-editor col-center' width={columnsWidth[3] + '%'} >
        <TableEditorCellCategories 
          setCategoriesToParentFct={ setCategories } 
          resetField={ resetField } 
          resetFieldFct={ setResetField }
        />
      </th>
    </tr>
  )
}

export default AddKeyword;