/** Components **/
import TableCellEventType from './../../../components/Table/TableCells/EventType';

export const EventScopeColumn = widthColumns => 
{
  return {
    accessor: "scopeType",
    width: widthColumns['scopeType'],
    title: "périmètre",
    className: "col-label",
    disableSortBy: true,
    disableFilters: true,
    Header: props => "périmètre",
    Cell: props => 
      <TableCellEventType 
        {...props.row.getToggleRowSelectedProps()} 
        label={props.value} 
      />
  }
}