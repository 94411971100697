/** Dependencies **/
import React, {useState, useRef} from 'react';
import ReactTooltip from 'react-tooltip';

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';

/** Components **/
import Loader from './../../Loader/Loader';

/** Helpers **/
import { callWebservice } from './../../../helpers/webservice/webserviceCaller';

/** JSON */
import globalConfig from './../../../assets/json/config.json';

function IntentionsHistory( props ) 
{
	const intentions = globalConfig.barometer.intention;
	
	// Get props
	const intentionHistoryID = props.id;
  const keywords = props.keywords;

	// Define ref
	const tooltipIntentionsHistory = useRef( null );

  /** Init state **/
	const [history, setHistory] = useState( null );

  /** Instance dispatch object **/
	const dispatch = useDispatch();

	/** Get state from redux store */
  const selectedFilters = useSelector( state => state.selectedFilters.value );
	const selectedInstance = useSelector( state => state.selectedInstance.value );

	/**
	 * Return data for keyword 12 months trend
	 * @param {string} keyword 
	 * @param {int} id 
	 * @returns data for trend
	 */
	const loadIntentions = () => 
	{
		if( keywords.length > 0 )
		{
			callWebservice(
				intentionHistoryID,
				intentionHistoryID,
				'get-intention',
				dispatch,
				selectedInstance,
				{
					keywords: keywords,
					where: {
            locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
            devices: [selectedFilters.devLoc.deviceValue]
          }
				},
				{ function: 'setHistory' }
			);
		}
	}

	return (
		<ReactTooltip 
			id={props.id}
			ref={tooltipIntentionsHistory}
			className="tooltip grey"
			effect='solid'
			delayHide={100}
			delayShow={0}
			delayUpdate={100}
			afterShow={loadIntentions}
			afterHide={() => {
				tooltipIntentionsHistory.current.tooltipRef.style.left = null;
				tooltipIntentionsHistory.current.tooltipRef.style.top = null;
			}}
			globalEventOff={'click'}
			isCapture={true}
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        // get width of tooltip
        let width = node.offsetWidth;
        
        return {
          left: - width / 2 + 250,
          top: -70
        };
      }}
		>
			<div className='tooltip-container history'>
				<Loader 
					loaderID={intentionHistoryID} 
					loaderStyle={{width:'15', stroke:'#e43e21'}} 
					callBackFcts={{
						setHistory: setHistory
					}}
					globalCallBack={undefined}
				/>
				<h4>Historique des changements d'intention</h4>
				{						
					(
						history !== null 
						&& history[keywords[0]]
					) ?
						<ul>
							{
								history[keywords[0]][0].date !== null ?
									history[keywords[0]].map( ( history, index ) => 
										<li key={index}>
											{history.date.toString().substring( 6, 8 )} / {history.date.toString().substring( 4, 6 )}  / {history.date.toString().substring( 0, 4 )} 
											&nbsp;-&nbsp;{history.user_first_name} {history.user_last_name} 
											&nbsp;:&nbsp;{
												history.updated_intention === null ? 
													'À définir'
													: intentions.filter( intention => intention.value === history.updated_intention )[0]['label']
											}
										</li>
									)
									: false
							}
							{
								history[keywords[0]][0].automatic_intention !== null ? 
									<React.Fragment>										
										{history[keywords[0]][0].date !== null ? <br /> : false}
										<li>Intention suggérée : {intentions.filter( intention => intention.value === history[keywords[0]][0].automatic_intention )[0]['label']}</li>
									</React.Fragment>
									: false
							}
						</ul>
					: <ul>
							<li>Aucune intention enregistrée</li>
						</ul>
				}				
			</div>
		</ReactTooltip>
	);
}

export default IntentionsHistory;