/** Components **/
import DefaultDateRangeFilterToolTip from './../../../components/Table/FilterToolTips/DefaultDateRangeFilterToolTip';
import TableHeaderTitleWithPicto from './../../../components/Table/TableHeaders/TitleWithPicto';
import TableCellKeywordCreatedDate from './../../../components/Table/TableCells/KeywordCreatedDate';

export const KeywordCreatedDateColumn = ( widthColumns ) => 
{
  return {
    accessor: "createdDate",
    width: widthColumns["createdDate"],
    title: "Date de créaton",
    className: "col-created-date col-center",
    Filter: DefaultDateRangeFilterToolTip,
    filter: 'multiFieldsCustomFilter',
    filterTitle: 'Date de création',
    sortDescFirst: false,          
    sortType: 'dateSort',
    Header: props => 
      <TableHeaderTitleWithPicto id={props.column.id} title={props.column.title} />,
    Cell: props => 
      <TableCellKeywordCreatedDate 
        createdDate={props.value}
      />
  }
}