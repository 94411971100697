/** Dependencies **/
import {useEffect, useState} from 'react';
import Select from 'react-select';

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';

/** Helpers **/
import { callWebservice } from './../../../helpers/webservice/webserviceCaller';
import { 
  getStylesSelectMenu
} from './../../../helpers/ui';

function CategoriesEditor( props ) 
{
  /** Get props */
  const {
    id,
    keyword,
    category1,
    category2,
    category3
  } = props;

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get state from redux store **/
  const selectedFilters = useSelector( state => state.selectedFilters.value );
  const filtersDatas = useSelector( state => state.filtersDatas.value );
  const datas = useSelector( state => state.datas.value );
  const selectedInstance = useSelector( state => state.selectedInstance.value ); 
  const appPathName = useSelector( state => state.appPathName.value );
  
  /** Init state **/
  const [currentCategories1, setCurrentCategories1] = useState( [] );
  const [currentCategories2, setCurrentCategories2] = useState( [] );
  const [currentCategories3, setCurrentCategories3] = useState( [] );
  const [currentCategory1, setCurrentCategory1] = useState( { label: category1, value: category1 } );
  const [currentCategory2, setCurrentCategory2] = useState( { label: category2, value: category2 } );
  const [currentCategory3, setCurrentCategory3] = useState( { label: category3, value: category3 } );
  const [newCategory1, setNewCategory1] = useState( null );
  const [newCategory2, setNewCategory2] = useState( null );
  const [newCategory3, setNewCategory3] = useState( null );
  const [disabledCategories, setDisabledCategories] = useState( [] );
  const [hideClassName, setHideClassName] = useState( 'hide' );

  /**
   * Update values of categories in UI
   * @param {Objects} value {label:..., value:...}
   * @param {int} level 
   */
  const updateCategory = ( value, level ) => 
  {
    switch ( level ) {
      case 1:
        // update category1 with value and reset category2 and category3 or set to Nouvelle categorie if first one is it
        setCurrentCategory1( value );
        setCurrentCategory2( value.value === null ? value : null );
        setCurrentCategory3( value.value === null ? value : null );
        break;
      case 2:
        // update category2 with value and reset category3 or set to Nouvelle categorie if first one is it
        setCurrentCategory2( value );
        setCurrentCategory3( value.value === null ? value : null );
        break;
      case 3:
        // update category3 with value
        setCurrentCategory3( value );

        // update DB
        if( value.value !== null )
          updateCategoryDB( [currentCategory1.value, currentCategory2.value, value.value] );
        break;    
      default:
        if( 
          value[0] !== null && value[0].value !== null && value[0].value.trim() !== ''
          && value[1] !== null && value[1].value !== null && value[1].value.trim() !== ''
          && value[2] !== null && value[2].value !== null && value[2].value.trim() !== ''
        ){
          // update category1 with value and reset category2 and category3
          setCurrentCategory1( value[0] );
          setCurrentCategory2( value[1] );
          setCurrentCategory3( value[2] );

          // reset new categories
          setNewCategory1( null );
          setNewCategory2( null );
          setNewCategory3( null );

          // update DB        
          updateCategoryDB( [value[0].value, value[1].value, value[2].value] );
        }
        break;
    }

    // display or hide new categories fields
    if( 
      (
        value instanceof Array
        && value.filter( value => 
          value instanceof Object
          && value?.value !== undefined
          && (
            value.value === null 
            || (
              typeof value.value === 'string'
              && value.value.trim() === ''
            )
          )
        ).length > 0
      ) || (
        value instanceof Object
        && value?.value !== undefined
        && (
          value.value === null 
          || (
            typeof value.value === 'string'
            && value.value.trim() === ''
          )
        )
      )
    )
      setHideClassName( '' );
    else
      setHideClassName( 'hide' );
  }

  /**
   * Update DB with new categories for keywords in all locations/devices
   * @param {Array} categories 
   */
  const updateCategoryDB = ( categories ) =>
  {
    callWebservice(
      'global',
      'updateCategories-' + id,
      'update-keywords',
      dispatch,
      selectedInstance,
      {
        keywords: [keyword],
        categories: categories,
        where: {
          locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
          devices: [selectedFilters.devLoc.deviceValue]
        }
      },
      { 
        function: 'updateCategories'
      },
      'Mise à jour des catégories pour le mot clé ' + keyword
    );
  }

  /** Get and update categories */
  useEffect( () => 
  {
    // set current categories
    if( filtersDatas.categories )
    {
      // get categories 1
      setCurrentCategories1([
        ...[{ label: 'Nouvelle Catégorie', value: null }], 
        ...Object.keys( filtersDatas.categories ).sort().map( cat => ({ label: cat, value: cat }) ) 
      ]);

      // get categories 2
      if( 
        currentCategory1 !== null 
        && filtersDatas.categories[currentCategory1.label]?.cat2 !== undefined
      )
        setCurrentCategories2([
          ...[{ label: 'Nouvelle Catégorie', value: null }], 
          ...Object.keys( filtersDatas.categories[currentCategory1.label].cat2 ).sort().map( cat => ({ label: cat, value: cat }) ) 
        ]);
      else
        setCurrentCategories2([ { label: 'Nouvelle Catégorie', value: null } ])

      // get categories 3
      if( 
        currentCategory1 !== null 
        && filtersDatas.categories[currentCategory1.label]?.cat2 !== undefined
        && currentCategory2 !== null
        && filtersDatas.categories[currentCategory1.label].cat2[currentCategory2.label]?.cat3 !== undefined
      )
        setCurrentCategories3([
          ...[{ label: 'Nouvelle Catégorie', value: null }], 
          ...Object.keys( filtersDatas.categories[currentCategory1.label].cat2[currentCategory2.label].cat3 ).sort().map( cat => ({ label: cat, value: cat }) ) 
        ]);   
      else
        setCurrentCategories3([ { label: 'Nouvelle Catégorie', value: null } ])   
    }
  }, [
    currentCategory1,
    currentCategory2,
    currentCategory3,
    filtersDatas.categories
  ])

  /** Update disabled select based on actionID  */
  useEffect( () => 
  {
    if( datas.filterResult && appPathName === 'gsc-keywords' )
    {
      // get current item
      const currentRow = datas.filterResult.filter( data => data.label === keyword );

      if( currentRow.length === 1 )
      {
        // current item
        const actionID = (
          currentRow[0]?.action?.updatedActionID !== undefined
          && currentRow[0].action.updatedActionID !== null
        ) ? 
          currentRow[0].action.updatedActionID
        : (
          currentRow[0]?.action?.automaticActionID !== undefined
          && currentRow[0].action.automaticActionID !== null
        ) ?
          currentRow[0].action.automaticActionID
        : null;

        // disable field if keyword add OR follow in GSC status
        if( actionID === null || actionID === 9 )
          setDisabledCategories( [1, 2, 3] );
        else
          setDisabledCategories( [] );
      }
    }
  }, [
    datas.filterResult ? JSON.stringify( datas.filterResult.map( data => [ data.action ] ) ) : null
  ]);

  /** Update current categories if changed */
  useEffect( () => 
  {
    setCurrentCategory1( { label: category1, value: category1 }  );
    setCurrentCategory2( { label: category2, value: category2 }  );
    setCurrentCategory3( { label: category3, value: category3 }  );
  },[
    category1,
    category2,
    category3
  ])

  return (
    <div className="categories-editor-container">
      <div className='categories-editor'>
        <div className='category-editor'>
          <Select 
            className="react-select"
            classNamePrefix="react-select"
            options={ currentCategories1.length > 0 ? currentCategories1 : [] }
            styles={getStylesSelectMenu()}
            placeholder={ 'Categorie 1' }
            value={ currentCategory1 }
            components={{
              IndicatorSeparator: () => null
            }}
            onChange={ value => updateCategory( value, 1 ) }
            isDisabled={ disabledCategories.includes( 1 ) === false ? false : true }
          />
          <input 
            type='text'
            id={ 'cat1-new-' + id }
            className={ 'free-field ' + hideClassName }
            onChange={ e => setNewCategory1( {label: e.target.value, value: e.target.value } )} 
            value={ newCategory1 !== null ? newCategory1.value : currentCategory1?.value !== undefined && currentCategory1.value !== null ? currentCategory1.value : '' } 
          />        
        </div>
        <div className='category-editor'>
          <Select 
            className="react-select"
            classNamePrefix="react-select"
            options={ currentCategories2.length > 0 ? currentCategories2 : [] }
            styles={getStylesSelectMenu()}
            placeholder={ 'Categorie 2' }
            value={ currentCategory2 }
            components={{
              IndicatorSeparator: () => null
            }}
            onChange={ value => updateCategory( value, 2 ) }
            isDisabled={ disabledCategories.includes( 2 ) === false ? false : true }
          />
          <input 
            type='text' 
            id={ 'cat2-new-' + id }
            className={ 'free-field ' + hideClassName }
            onChange={ e => setNewCategory2( {label: e.target.value, value: e.target.value } )} 
            value={ newCategory2 !== null ? newCategory2.value : currentCategory2?.value !== undefined && currentCategory2.value !== null ? currentCategory2.value : '' } 
          />
        </div>
        <div className='category-editor'>
          <Select 
            className="react-select"
            classNamePrefix="react-select"
            options={ currentCategories3.length > 0 ? currentCategories3 : [] }
            styles={getStylesSelectMenu()}
            placeholder={ 'Categorie 3' }
            value={ currentCategory3 }
            components={{
              IndicatorSeparator: () => null
            }}
            onChange={ value => updateCategory( value, 3 ) }
            isDisabled={ disabledCategories.includes( 3 ) === false ? false : true }
          />
          <input 
            type='text' 
            id={ 'cat3-new-' + id }
            className={ 'free-field ' + hideClassName }
            onChange={ e => setNewCategory3( {label: e.target.value, value: e.target.value } )} 
            value={ newCategory3 !== null ? newCategory3.value : currentCategory3?.value !== undefined && currentCategory3.value !== null ? currentCategory3.value : '' } 
          />        
        </div>
      </div>
      <input 
        type='button' 
        className={ hideClassName }
        disabled={ disabledCategories.length < 3 ? false : true } 
        onClick={ () => updateCategory( [ newCategory1 || currentCategory1, newCategory2 || currentCategory2, newCategory3 || currentCategory3], 0 ) } 
        value='Ajouter les nouvelles catégories' 
      />
    </div>  
  );
}

export default CategoriesEditor;