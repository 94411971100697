/** Dependencies **/
import moment from 'moment';

/** Components **/
import TableCellEventType from './../../../components/Table/TableCells/EventType';

export const EventStartDateColumn = widthColumns => 
{
  return {
    accessor: "startDate",
    width: widthColumns['startDate'],
    title: "date",
    className: "col-label",
    disableSortBy: true,
    disableFilters: true,
    Header: props => "date",
    Cell: props => 
      <TableCellEventType 
        {...props.row.getToggleRowSelectedProps()} 
        label={ moment( props.value.toString() ).format( 'DD/MM/YYYY' ) }
      />
  }
} 