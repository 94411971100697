/** Dependencies **/
import React from 'react';
import { 
  VictoryChart, 
  VictoryAxis, 
  VictoryStack,
  VictoryBar,
  VictoryGroup,
  VictoryLabel
} from 'victory';

/** Redux **/
import { useSelector } from 'react-redux';

/** Helpers **/
import { rewriteTrendObjectData } from './../../../../helpers/barometer/charts.js';
import { orderObjectKeysWithPattern } from './../../../../helpers/functions.js';

/** Components **/
import CustomLabelPerfPos from './../CustomLabelPerfPos';

function DataVizPerfPos( props ) 
{
  /** Get props **/
  const {
    grpDomainDisplay,
    pageRangesDisplay,
    compareDisplay    
  } = props;

  let {
    currentPerfTrend,
    comparePerfTrend
  } = props;

  // deep copy
  currentPerfTrend = JSON.parse( JSON.stringify( currentPerfTrend ) );
  comparePerfTrend = JSON.parse( JSON.stringify( comparePerfTrend ) );

  /** Get state from redux store **/
  const datas = useSelector( state => state.datas.value );
  const appPathName = useSelector( state => state.appPathName.value );  
  const instanceConfig = useSelector( state => state.config.value );  
  const togglers = useSelector( state => state.togglers.value ); 
  
  /** Get config values **/
  const grpDomains = instanceConfig.groups;
  const grpDomainsValues = instanceConfig.groups.map( grp => grp.value );

  /** Init datas for chart **/
  let currentPositionsTrend = {};
  let comparePositionsTrend = {};

  /** Get count of selected group **/
  const selectedGrp = Object.keys( grpDomainDisplay ).filter( grp => grpDomainDisplay[grp] );

  /** Set ranked keywords AND total keywords key with context */
  let nbRankedKey = 'nb_ranked_keywords';
  let nbKeywordsKey = 'keywords';
  if( appPathName === 'urls' )
  {
    nbRankedKey = 'nb_assigned_ranked_keywords';
    nbKeywordsKey = 'keywords-assigned';
  }

  /** Get count of keywords in dataset OR assigned keywords if url context */
  let totalKeywords = 0;
  if( datas.filterResult )
    totalKeywords = [...new Set( datas.filterResult.map( result => result[nbKeywordsKey] ).flat() )].length;

  /** Rewrite array datas **/
  // selected grp is single
  if( selectedGrp.length === 1 )
  {
    // set current Details Perf Trend datas
    if( currentPerfTrend?.positionsGrpDomains[selectedGrp[0]] !== undefined )
    {
      // add unranked keywords
      if( currentPerfTrend.positionsGrpDomains[selectedGrp[0]][nbRankedKey] )
        currentPerfTrend.positionsGrpDomains[selectedGrp[0]].nb_unranked_keywords = currentPerfTrend.positionsGrpDomains[selectedGrp[0]][nbRankedKey].map( item => 
          ({
            x: parseInt( item.x ),
            y: item.y === null ? 0 : totalKeywords - item.y
          })
        );

      // rewrite data for trend
      currentPositionsTrend = rewriteTrendObjectData( 
        currentPerfTrend.positionsGrpDomains[selectedGrp[0]],
        togglers['radio-view-option'] ? 'month' : 'week'
      );

      // remove ranked AND assigned ranked keywords
      delete currentPositionsTrend['nb_ranked_keywords'];
      delete currentPositionsTrend['nb_unranked_keywords'];
      delete currentPositionsTrend['nb_assigned_ranked_keywords'];      

      // set compare Details Perf Trend datas
      if( comparePerfTrend?.positionsGrpDomains[selectedGrp[0]] !== undefined ) 
      {        
        // add unranked keywords
        if( comparePerfTrend.positionsGrpDomains[selectedGrp[0]][nbRankedKey] )
          comparePerfTrend.positionsGrpDomains[selectedGrp[0]].nb_unranked_keywords = comparePerfTrend.positionsGrpDomains[selectedGrp[0]][nbRankedKey].map( item => 
            ({
              x: parseInt( item.x ),
              y: item.y === null ? 0 : totalKeywords - item.y
            })
          );

        // rewrite data for trend
        comparePositionsTrend = rewriteTrendObjectData( 
          comparePerfTrend.positionsGrpDomains[selectedGrp[0]],
          togglers['radio-view-option'] ? 'month' : 'week'
        );  
        
        // remove ranked AND assigned ranked keywords
        delete comparePositionsTrend['nb_ranked_keywords'];
        delete comparePositionsTrend['nb_unranked_keywords'];
        delete comparePositionsTrend['nb_assigned_ranked_keywords'];        
      }

      // remove values if not displayed
      Object.keys( pageRangesDisplay ).forEach( range => {
        if( currentPositionsTrend[range] && pageRangesDisplay[range] === false )
          delete currentPositionsTrend[range];
        
        if( comparePositionsTrend[range] && pageRangesDisplay[range] === false )
          delete comparePositionsTrend[range];
      });
    }

  // selected grp is multiple
  } else {
  
    // set current Perf Trend datas
    if( 
      currentPerfTrend
      && currentPerfTrend.top10GrpDomains
      && Object.keys( currentPerfTrend.top10GrpDomains ).length > 0
    ){
      // Rewrite Current Perf Trend datas with top 10
      currentPositionsTrend = rewriteTrendObjectData( 
        currentPerfTrend.top10GrpDomains,
        togglers['radio-view-option'] ? 'month' : 'week'
      );

      if( 
        comparePerfTrend
        && comparePerfTrend.top10GrpDomains
        && Object.keys( comparePerfTrend.top10GrpDomains ).length > 0
      )
        // Rewrite Compare Perf Trend datas
        comparePositionsTrend = rewriteTrendObjectData( 
          comparePerfTrend.top10GrpDomains,
          togglers['radio-view-option'] ? 'month' : 'week'
        );
      
      // remove values if not displayed
      Object.keys( grpDomainDisplay ).forEach( grp => {
        if( currentPositionsTrend[grp] && grpDomainDisplay[grp] === false )
          delete currentPositionsTrend[grp];
        
        if( comparePositionsTrend[grp] && grpDomainDisplay[grp] === false )
          delete comparePositionsTrend[grp];
      });

      // reorder data object with grp domain order
      currentPositionsTrend = orderObjectKeysWithPattern( currentPositionsTrend, grpDomainsValues );
      comparePositionsTrend = orderObjectKeysWithPattern( comparePositionsTrend, grpDomainsValues );
    }
  };

  /** Define width of chart and bars **/
  let barWidth = 0;
  if( Object.keys( currentPositionsTrend ).length > 0 )
  {
    const nbMonth = currentPositionsTrend[Object.keys( currentPositionsTrend )[0]].length;
    barWidth = 1300 / nbMonth * 0.5;
    if( compareDisplay === true && Object.keys( comparePositionsTrend ).length > 0 )
      barWidth = 1300 / nbMonth * 0.75 - ( (1300 / nbMonth * 0.75) / 2 );
  }

  /** Get if all displayed values = 0 to set default dependant axis values */
  // get current sum values
  let valuesSumDisplay = Object.keys( grpDomainDisplay ).filter( grp => grpDomainDisplay[grp] ).map( grp => 
    currentPositionsTrend[grp] ? currentPositionsTrend[grp].reduce( ( a, b ) => a + b.y, 0 ) : 0 
  ).reduce( (a, b) => a + b, 0 );

  // get compare sum values
  valuesSumDisplay += Object.keys( grpDomainDisplay ).filter( grp => grpDomainDisplay[grp] ).map( grp => 
    comparePositionsTrend[grp] ? comparePositionsTrend[grp].reduce( ( a, b ) => a + b.y, 0 ) : 0
  ).reduce( (a, b) => a + b, 0 );

  return (
    <React.Fragment>
      {( 
        Object.keys( currentPositionsTrend ).length > 0 
        && grpDomains.length > 0 
      ) ?
        <VictoryChart
          width={1300}
          height={250}
          padding={{ top: 10, bottom: 30, left: 50, right: 20 }}
          domainPadding={{x: barWidth}}
        >
          <VictoryLabel 
            text={selectedGrp.length > 1 ? 'Nombre de mots clés positionnés en top 10 par groupe de sites' : ''} 
            style={[
              {
                fontSize: 14,
                fontFamily: "Roboto",                
                fill: "#626262"
              }
            ]}
            x={490} 
            y={0}
          />
          <VictoryAxis 
            tickValues={currentPositionsTrend[Object.keys( currentPositionsTrend )[0]].map( elem => elem.tickValue )}
            style={{
              tickLabels: {
                fontSize: 6.5,
                fontFamily: "Roboto",
                fontWeight: "bold",
                fill: "#626262",
                angle: -45
              }
            }}
          />
          <VictoryAxis dependentAxis 
            offsetX={50}
            tickValues={valuesSumDisplay === 0 ? [0] : []}
            tickFormat={ t => new Intl.NumberFormat( 'fr-FR' ).format( t ) }
            style={{
              tickLabels: {
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: "300",
                fill: "#707070"
              },
              grid: {
                stroke: '#D8D8D8', 
                strokeWidth: 0.5
              }
            }}
          />
          <VictoryGroup
            offset={compareDisplay === true ? barWidth / 2 : 0}
          >
            <VictoryStack>
              {Object.keys( currentPositionsTrend ).map( ( key, index ) => 
                <VictoryBar
                  key={index}
                  data={currentPositionsTrend[key]}
                  labels={ () => ' ' }
                  style={{ 
                    data: {
                      width: barWidth,
                      fill: () => {
                        if( selectedGrp.length === 1 )
                          return grpDomains.filter( grp => grp.value === selectedGrp[0] )[0].currentColor;
                        else
                          return grpDomains.filter( grp => grp.value === key ).length > 0 ? grpDomains.filter( grp => grp.value === key )[0].currentColor : null
                      },
                      strokeWidth: 1, 
                      opacity: selectedGrp.length === 1 ? 1 / ( index + 1 ) * 1.6 : 1,
                      cursor: 'pointer'
                    }
                  }}
                  labelComponent={
                    <CustomLabelPerfPos 
                      {...props} 
                      compareDisplay={compareDisplay} 
                      barWidth={barWidth} 
                      selectedGrp={selectedGrp}
                    />
                  }
                  cornerRadius={ ( {datum} ) => {
                    if( datum._stack === Object.keys( currentPositionsTrend ).length ){
                      if( datum.y > 10 )
                        return barWidth < 70 ? barWidth * 0.1 : 10;
                      else 
                        return 0;
                    } else
                      return 0;
                  }}
                />
              )}
            </VictoryStack>
            {Object.keys( comparePositionsTrend ).length > 0 && compareDisplay === true ?
              <VictoryStack>
                {Object.keys( comparePositionsTrend ).map( ( key, index ) => 
                  <VictoryBar
                    key={index}
                    data={comparePositionsTrend[key]}
                    style={{ 
                      data: {
                        width: barWidth,
                        fill: "transparent", 
                        stroke: () => {
                          if( selectedGrp.length === 1 )
                            return grpDomains.filter( grp => grp.value === selectedGrp[0] )[0].compareColor;
                          else
                            return grpDomains.filter( grp => grp.value === key ).length > 0 ? grpDomains.filter( grp => grp.value === key )[0].compareColor : null;
                        },
                        strokeWidth: 1, 
                        strokeDasharray:"2"
                      }
                    }}
                    cornerRadius={ ( {datum} ) => {
                      if( datum._stack === Object.keys( currentPositionsTrend ).length ){
                        if( datum.y > 10 )
                          return barWidth < 70 ? barWidth * 0.1 : 10;
                        else 
                          return 0;
                      } else
                        return 0;
                    }}
                  />
                )}
              </VictoryStack>
            : false
          }
          </VictoryGroup>
        </VictoryChart>
        : false
      }
    </React.Fragment>
	);
}

export default DataVizPerfPos;