/** Dependencies **/
import React, {useEffect, useState} from "react";
import moment from 'moment';

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedHeaderFilters } from './../../../../reducers/selectedHeaderFilters';

/** Helpers */
import { getCustomDateOptionsList } from './../../../../helpers/ui';

/**
 * Define UI for Date Range Select Filter
 * @param {object} props
 * @returns ReactDOM with Button filter
 */
function DateFilter ( props )
{
  /** Get props **/
  const {
    id,
    title,
    fieldName,
    filterType
  } = props;  

  /** Instance dispatch object **/
  const dispatch = useDispatch();

  /** Get state from redux store **/
  const selectedHeaderFilters = useSelector( state => state.selectedHeaderFilters.value );
  
  /** Init state **/
  const [startDateValue, setStartDateValue] = useState( null );
  const [endDateValue, setEndDateValue] = useState( null );

  /**
	 * Set start and end date values and update reduc selected filters
	 * @param {DOMElement} elem select
	 * @param {string} type string value if start or end type
	 */
	const handleChangeSelectCustomDate = ( elem, type ) => 
  {
    // get value
    let value = null;
    if( elem.currentTarget.value !== '' )
      value = moment( elem.currentTarget.value, 'YYYYMM' );

    // set start date value
    if( type === 'startDate' )
      setStartDateValue( value );

    // set start date value
    else if( type === 'endDate' )
      setEndDateValue( value );

    // subtrack value if inferior to new one
    if( value !== null )
    {
      if( type === 'startDate' && endDateValue !== null && endDateValue.diff( value ) < 0 )
        setEndDateValue( value );
      else if( type === 'endDate' && startDateValue !== null && value.diff( startDateValue ) < 0 )
        setStartDateValue( value );
    }
	}

  // reset selected values if range date filter is cancelled
  useEffect( () => 
  {
    if( 
      (
        selectedHeaderFilters['gsc.identifierDate'] === undefined 
        && id === 'gsc.identifierDate'
      ) || (
        selectedHeaderFilters['createdDate'] === undefined 
        && id === 'createdDate'
      )
    ){
      setStartDateValue( null );
      setEndDateValue( null );
    }

  }, [selectedHeaderFilters]);

  /** Dispatch filter when values updated */
  useEffect( () =>
  {
    if( 
      startDateValue === null
      && endDateValue === null
    )
      dispatch( updateSelectedHeaderFilters( { filterName: id, value: null } ) );
    else 
    {
      dispatch( updateSelectedHeaderFilters( { filterName: id, value: {
        value: {
          result: [ 
            startDateValue ? startDateValue.format( 'YYYYMM' ) : null, 
            endDateValue ? endDateValue.format( 'YYYYMM' ) : null
          ], 
          type: filterType,
          field: fieldName
        }, 
        label: (
          (
            startDateValue !== null 
            && endDateValue !== null
            && startDateValue.format( 'MM/YY' ) === endDateValue.format( 'MM/YY' ) 
          ) ? 
            startDateValue.format( 'MM/YY' )
            :
            ( startDateValue !== null ? startDateValue.format( 'MM/YY' ) : '' )
            + ' ▶︎ ' +
            ( endDateValue !== null ? endDateValue.format( 'MM/YY' ) : '' )
        ), 
        type: 'text'
      }}));
    }

  }, [startDateValue, endDateValue] );

  return (
    <React.Fragment>
      
      { title !== '' ? <h5>{ title }</h5> : '' }

      <div className='date-select'>
        <select 
          value={ startDateValue ? startDateValue.format( 'YYYYMM' ) : '' }
          onChange={ elem => handleChangeSelectCustomDate( elem, 'startDate', 'YYYYMM' ) }
        >
          <React.Fragment>
            <option value=''>-</option>
            {getCustomDateOptionsList( 36, false, 'YYYYMM' ).map( ( option, index ) => 
              <option key={index} value={option.value}>{option.label}</option>
            )}
          </React.Fragment>
        </select>
        <select 
          value={ endDateValue ? endDateValue.format( 'YYYYMM' ) : '' }
          onChange={ elem => handleChangeSelectCustomDate( elem, 'endDate' ) }
        >                  
          <React.Fragment>
            <option value=''>-</option>
            {getCustomDateOptionsList( 36, false, 'YYYYMM' ).map( ( option, index ) => 
              <option key={index} value={option.value}>{option.label}</option>
            )}
          </React.Fragment>
        </select>
      </div>
    </React.Fragment>
  )
}

export default DateFilter;