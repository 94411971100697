/** Dependencies **/
import React from "react";

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedDataVizTab } from "./../../../reducers/selectedDataVizTab";

/** Helpers **/
import { getPicto } from './../../../helpers/pictos';

function GSCTab ( props )
{
  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get current selected tab **/
  const selectedDataVizTab = useSelector( state => state.selectedDataVizTab.value );
  const classON = selectedDataVizTab === 'gsc' ? 'on' : '';

  // get GSC datas from props
  const datas = props.currentGSCTrend;

  // get total impressions
  let impressions = 0;
  if( datas.impressions && datas.impressions.length > 0 )
    impressions = datas.impressions.reduce( (a, b) => a + parseInt( b.y ), 0 );

  // get total clicks
  let clicks = 0;
  if( datas.clicks && datas.clicks.length > 0 )
    clicks = datas.clicks.reduce( (a, b) => a + parseInt( b.y ), 0 );

  // get average position
  let position = 0;
  if( datas.position && datas.position.length > 0 )
    position = datas.position.reduce( (a, b) => a + parseFloat( b.y ), 0 ) / datas.position.length;

  // set ctr
  let ctr = 0;
  if( impressions > 0 )
    ctr = clicks / impressions * 100;

  return (
    <div className={'dataviz-tab gsc ' + classON} onClick={ () => dispatch( updateSelectedDataVizTab( 'gsc' ) ) }>
      <div className="title"><h4>ma google search console</h4></div>
      <div className="values-container">
        <div className="values-parts-container">
          <div className="values-part top">
            {getPicto( 'Snippets', {"size": "1.1rem"} )}<h5>impressions</h5>
          </div>
          <div className="values-part bottom">
            <div className="value">{new Intl.NumberFormat('fr-FR').format( impressions )}</div>
          </div>
        </div>
        <div className="values-parts-container">
          <div className="values-part top">
            {getPicto( 'Snippets', {"size": "1.1rem"} )}<h5>clics</h5>
          </div>
          <div className="values-part bottom">
            <div className="value">{new Intl.NumberFormat('fr-FR').format( clicks )}</div>
          </div>
        </div>
        <div className="values-parts-container">
          <div className="values-part top">
            {getPicto( 'Snippets', {"size": "1.1rem"} )}<h5>ctr</h5>
          </div>
          <div className="values-part bottom">
            <div className="value">{new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1 }).format( ctr )}%</div>
          </div>
        </div>
        <div className="values-parts-container">
          <div className="values-part top">
            {getPicto( 'Snippets', {"size": "1.1rem"} )}<h5>pos.</h5>
          </div>
          <div className="values-part bottom">
            <div className="value">{new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1 }).format( position )}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GSCTab;