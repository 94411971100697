/** Dependencies **/
import React from 'react';

function EventType( props ) 
{
  const {
    label
  } = props;

  return (
    <div className="keyword-container">{label}</div>
  );
}

export default EventType;