/** Dependencies **/
import React from "react";
import ReactTooltip from 'react-tooltip';

/** Redux **/
import { useSelector } from 'react-redux';

/** Components */
import SelectFilter from './Filters/SelectFilter';
import AutoSuggestFilter from './Filters/AutoSuggestFilter';

/** Helpers */
import { 
  stringURLExpectedCustomFilter
} from './../../../helpers/table/customFilters';

/** JSON */
import globalConfig from './../../../assets/json/config.json';

/**
 * Define a specific UI for Actions filtering
 * @param {object} {column: { filterValue, preFilteredRows, setFilter }...}
 * @returns ReactDOM with input
 */
function ActionsFilterToolTip ( props )
{
  /** Get props */
  const id = props.column.id;
  const filterTitle = props.column.filterTitle;

  /** Get state from redux store **/
  const appPathName = useSelector( state => state.appPathName.value );

  /** Get Actions from config **/
  const actions = globalConfig.barometer.action[appPathName];

  /** Calculate the options for filtering **/
  const selectStatesValues = React.useMemo(() => 
  {
    const options = new Set();

    // add undefined value
    options.add( { value: '', label: 'Non défini' } );

    // add actions from config file values
    actions.sort( ( a, b ) => 
    {
      if (a.label < b.label)
        return -1;

      if (a.label > b.label)
        return 1;

      return 0;

    }).forEach( value => 
    {
      options.add({ 
        value: value.value, 
        label: value.label 
      });
    });
    
    return [...options.values()];
  }, [actions]);

  /** Set Type expectedUrl for select filtre */
  const selectExpTypeURLValues = [
    {
      value: 'newurl',
      label: 'Nouvelles pages à créer'
    },{
      value: 'nonAssigned',
      label: 'Non assignée'
    },{
      value: 'automaticAssigned',
      label: 'Assignée auto.'
    },{
      value: 'nonAutomaticAssigned',
      label: 'Non assignée auto.'
    },{
      value: 'updatedAssigned',
      label: 'Assignée humain'
    }
  ];

  return (
    <ReactTooltip 
      id={'filter-' + id}
      className="tooltip red"
      effect='solid'
      delayHide={100}
      delayShow={100}
      delayUpdate={500}
      globalEventOff='click'
      arrowColor="#e43e21"
      offset={{top: 0, left: 400}}
      isCapture={true}
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        // get width of tooltip
        const width = node.offsetWidth;

        // set offset
        let offsetX = -269;
        if( appPathName === 'urls' )
          offsetX = -92;
        else if( 
          appPathName === 'gsc-keywords' 
          || appPathName === 'admin-add-keywords'
        )
          offsetX = 0;
        
        return {
          left: currentTarget.offsetLeft - (width / 2) + offsetX,
          top: 17
        };
      }}
    >
      <div className="filter-container decisions">
        <div className="filters">
          <div className="title">{ filterTitle !== '' ? <h4>{ filterTitle }</h4> : '' }</div>
            <div className='filters-container'>
              <div className="filter select">   
                <SelectFilter
                  column={{ id: id }}
                  title="Par actions" 
                  fieldName="actions"
                  typeFilter={ 'select-' + id }
                  optionsValues={selectStatesValues} 
                />
              </div>
              { 
                appPathName === 'keywords' 
                || appPathName === 'gsc-keywords' ?
                <div className="filter select">
                  <SelectFilter
                    column={{ id: id }}
                    title="Par type d'url" 
                    fieldName="type-url"
                    typeFilter='select-type-expected-url'
                    optionsValues={selectExpTypeURLValues} 
                  />
                </div>
                : false
              }
            </div>
          </div>

          { 
            appPathName === 'keywords'
            || appPathName === 'gsc-keywords' ?
            <div className="url">
              <div className="filter text"> 
                <div className="title"><h4>Chercher un domaine ou une URL</h4></div>
                <AutoSuggestFilter 
                  {...props}                 
                  filterFct={stringURLExpectedCustomFilter}
                  filterType='string-url-expected'              
                />
              </div>
            </div>
            : false
          }
      </div>
    </ReactTooltip>
  )
}

export default ActionsFilterToolTip;