/** Components **/
import TableHeaderTitleWithPicto from './../../../components/Table/TableHeaders/TitleWithPicto';
import TableCellCategoriesEditor from './../../../components/Table/TableCells/CategoriesEditor';

export const CategoriesEditorColumn = ( widthColumns ) =>
{
  return {
    accessor: "categoriesEditor",
    width: widthColumns["categoriesEditor"],
    title: "Categories",
    className: "col-categories-editor col-center",
    disableFilters: true,
    disableSortBy: true,
    Header: props => 
      <TableHeaderTitleWithPicto 
        id={props.column.id} 
        title={props.column.title} 
      />,
    Total: '',
    Cell: props => 
      <TableCellCategoriesEditor 
        {...props.row.getToggleRowSelectedProps()} 
        id={props.row.id} 
        keyword={props.row.values.label}
        category1={props.row.original.category1} 
        category2={props.row.original.category2} 
        category3={props.row.original.category3} 
      />
  }
}