/** Dependencies **/
import React, {useEffect, useRef, useState} from 'react';
import ReactTooltip from 'react-tooltip';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';
import 'moment/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedFilters } from './../../reducers/selectedFilters';

/** SCSS **/
import '../../assets/css/datepicker/styles.css'; // custom css extract from original otherwise datepickup don't work
import 'antd/lib/date-picker/style/index.css';
import './TopNavPeriod.scss';

/** Helpers **/
import { togglerClassName } from './../../helpers/functions';
import { getScrapDate } from './../../helpers/datas';
import { getCustomDateOptionsList } from './../../helpers/ui';
import { getPicto } from './../../helpers/pictos';
import { getInitDateRange } from './../../helpers/datas';

function TopNavPeriod() {

  const { RangePicker } = DatePicker;

  // set predefined analyse period
  const predefinedAnalysePeriods = [
    { nbmonth: 1, label: 'Le mois dernier' },
    { nbmonth: 3, label: 'Les 3 derniers mois' },
    { nbmonth: 6, label: 'Les 6 derniers mois' },
    { nbmonth: 12, label: 'Les 12 derniers mois' }
  ];

	/** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get state from redux store **/
  const selectedFilters = useSelector( state => state.selectedFilters.value );
  const datas = useSelector( state => state.datas.value );

  /** Init state **/
  const [periodLabel, setPeriodLabel] = useState( null );
  const [dateLabel, setDateLabel] = useState( null );
  const [compareDateLabel, setCompareDateLabel] = useState( null );
  const [valueAnalyse, setValueAnalyse] = useState( null );
  const [valueCompare, setValueCompare] = useState( undefined );
  const [firstScrapDate, setFirstScrapDate] = useState( null );

	/** Init Refs **/
	const contextButtonRef = useRef( null );

  /** Init current and compare default period if not exist **/
  useEffect(() => 
  {
    if( !selectedFilters.currentPeriod && !selectedFilters.comparePeriod )
    {
      dispatch( updateSelectedFilters( { filterName: 'currentPeriod', value: getInitDateRange('currentPeriod') } ) );
      dispatch( updateSelectedFilters( { filterName: 'comparePeriod', value: getInitDateRange('comparePeriod') } ) );
    }
  }, []);
  
	/**
	 * 
	 * @param {Array} values of dataRange
	 * @param {string} id of value in redux store
	 */
	const handleChangeSelectCustomDate = ( elem, type, position ) => 
  {
    // get value
    const value = elem.currentTarget.value;
    const valueMoment = moment( value, 'YYYYMMDD' );

    let currentValue = [...valueAnalyse];
    if( type === 'compare' && valueCompare !== undefined )
      currentValue = [...valueCompare];

    // set new value    
    if( position === 0 )
      currentValue[position] = moment( valueMoment.startOf( 'month' ).format() );
    else
      currentValue[position] = moment( valueMoment.endOf( 'month' ).format() );

    // subtrack value if inferior to new one
    if( position === 0 && currentValue[1].diff( valueMoment, 'month', true ) < 0 )
      currentValue[1] = moment( valueMoment.endOf( 'month' ).format() );
    else if( position === 1 && valueMoment.diff( currentValue[0], 'month', true ) < 0 )
      currentValue[0] = moment( valueMoment.startOf( 'month' ).format() );

    if( type === 'compare' ){
      setValueCompare( currentValue );
      setCompareDateLabel( formatDateLabel( currentValue ) );
    } else {
      setValueAnalyse( currentValue );
      setDateLabel( formatDateLabel( currentValue ) );
    }
	}

  /**
   * update value / update periode label / update date label
   * @param {array} value [Moment(), Moment()]
   * @param {string} label label of date predefined
   * @param {string} type analyse or compare
   */
  const handleClickPredefineDate = ( value, label, type) => 
  {
    if( type === 'analyse' )
    {
      // update value analyse
      if( value !== null )
      {
        setValueAnalyse( value );
        setDateLabel( formatDateLabel( value ) );
      }

      // update period label
      setPeriodLabel( label );

    } else {

      // update value analyse
      if( value !== null )
      {
        setValueCompare( value );
        setCompareDateLabel( formatDateLabel( value ) );
      }
    }    
  }

  /**
   * Build and return label for date
   * @param {array} value [Moment, Moment]
   * @returns string
   */
  const formatDateLabel = ( value ) => 
  {
    let dateLabel = '';

    if( value !== undefined && value[0] && value[1] )
    {
      // update date label
      let startValue = moment( value[0] ).format('MMM YYYY');
      let endValue = moment( value[1] ).format('MMM YYYY')
      
      dateLabel = startValue + ' > ' + endValue;

      if( startValue === endValue )
        dateLabel = startValue;
    }

    return dateLabel;
  }

  /**
   * Return array with 2 Moment objects, start and end range [Moment(), Moment()]
   * @param {number} nb how many months previous
   * @returns array
   */
  const getLastMonths = ( nb, includeCurrent = false ) => 
  {
    let nbMonth = 1;
    if( typeof nb === 'number' )
      nbMonth = nb;

    // subtract 1 month if current is included
    if( includeCurrent === true )
      nbMonth--;

    // init current month
    let result = [
      moment().subtract( nbMonth, 'month' ).startOf( 'month' ),
      includeCurrent === true ? 
        moment().endOf( 'month' ) 
        : moment().subtract( 1, 'month' ).endOf( 'month' )
    ];

    return result;
  }

  /**
   * 
   * @param {array} valueAnalyse array of start and end range Moment
   * @param {number} duration duration of previous month
   * @returns 
   */
  const getPreviousRange = ( valueAnalyse, duration ) => 
  {
    let result = [];
    if( Array.isArray( valueAnalyse ) && valueAnalyse.length === 2 )
    {
      let startRange = moment( valueAnalyse[0].format('YYYYMMDD') );
      let endRange = moment( valueAnalyse[1].format('YYYYMMDD') );

      if( duration === null )
        duration = endRange.diff( startRange, 'month' ) + 1;

      if( startRange && endRange )
      {
        result = [
          startRange.subtract( duration, 'month' ).startOf( 'month' ),
          endRange.subtract( duration, 'month' ).endOf( 'month' )
        ];        
      }
    }

    return result;
  }

  /**
   * Return moment with disabled month after last month
   * @param {moment} current 
   * @returns moment
   */
   const disabledDate = ( current ) => 
   {
     return current && current >= moment().endOf('month');
   };

  /**
   * set period filter
   */
	const setFilter = () => 
  {
    let analyseResult = {};
    if( Array.isArray( valueAnalyse ) && valueAnalyse.length === 2 )
    {
      analyseResult.startdate = moment( valueAnalyse[0] ).format( 'YYYYMMDD' );
      analyseResult.enddate = moment( valueAnalyse[1] ).format( 'YYYYMMDD' );
    }
    
    let compareResult = {};
    if( Array.isArray( valueCompare ) && valueCompare.length === 2 )
    {
      compareResult.startdate = moment( valueCompare[0] ).format( 'YYYYMMDD' );
      compareResult.enddate = moment( valueCompare[1] ).format( 'YYYYMMDD' );
    } 
    else if( valueCompare === undefined ) 
    {
      compareResult.startdate = undefined;
      compareResult.enddate = undefined;
    }

    if( JSON.stringify( selectedFilters.currentPeriod ) !== JSON.stringify( analyseResult ) )
      dispatch( updateSelectedFilters( { filterName: 'currentPeriod', value: analyseResult } ) );

    if( JSON.stringify( selectedFilters.comparePeriod ) !== JSON.stringify( compareResult ) )
      dispatch( updateSelectedFilters( { filterName: 'comparePeriod', value: compareResult } ) );
  };

  /** Init calendar **/
  useEffect(() => 
  {
    if( selectedFilters.currentPeriod && selectedFilters.comparePeriod )
    {
      // set date label
      if(  selectedFilters.currentPeriod.startdate === selectedFilters.currentPeriod.enddate )
        setDateLabel(
          moment( selectedFilters.currentPeriod.startdate ).format( 'MMM YYYY' )
        );
      else
        setDateLabel(
          moment( selectedFilters.currentPeriod.startdate ).format( 'MMM YYYY' ) + ' > ' + moment( selectedFilters.currentPeriod.enddate ).format( 'MMM YYYY' )
        );

      // set analyse value
      setValueAnalyse(
        [moment( selectedFilters.currentPeriod.startdate ), moment( selectedFilters.currentPeriod.enddate )]
      );

      // set compare value and date label
      if( selectedFilters.comparePeriod.startdate !== undefined )
      {
        // set compare date label
        if(  selectedFilters.comparePeriod.startdate === selectedFilters.comparePeriod.enddate )
          setCompareDateLabel(
            moment( selectedFilters.comparePeriod.startdate ).format( 'MMM YYYY' )
          );
        else
          setCompareDateLabel(
            moment( selectedFilters.comparePeriod.startdate ).format( 'MMM YYYY' ) + ' > ' + moment( selectedFilters.comparePeriod.enddate ).format( 'MMM YYYY' )
          );

        // set compare value
        setValueCompare(
          [moment( selectedFilters.comparePeriod.startdate ), moment( selectedFilters.comparePeriod.enddate )]
        );
      } else {
        setValueCompare( undefined );
        setCompareDateLabel( null );
      }

      // set period label
      let selectedCurrentPeriod = [
        moment( selectedFilters.currentPeriod.startdate ).format('YYYYMMDD'), 
        moment( selectedFilters.currentPeriod.enddate ).format('YYYYMMDD')
      ];

      predefinedAnalysePeriods.every( predefinedAnalysePeriod => 
      {
        let lastMonth = getLastMonths( 
          predefinedAnalysePeriod.nbmonth,
          predefinedAnalysePeriod.nbmonth === 1 ? false : true
        ).map( period => period.format('YYYYMMDD'));

        if( 
          lastMonth[0] === selectedCurrentPeriod[0] 
          && lastMonth[1] === selectedCurrentPeriod[1] 
        ){
          setPeriodLabel( predefinedAnalysePeriod.label );
          return false;
        } else {
          setPeriodLabel( 'Personnalisée' );
          return true;
        }
      });
    }
  }, [selectedFilters.currentPeriod, selectedFilters.comparePeriod]);

  /** Display First scrap date */
  useEffect(() => 
  {
    if( datas.currentSERPTrend )
    {
      /** Get first SERP scrap date */
      const firstScrapDate = getScrapDate( datas, 'first' );

      if( firstScrapDate !== null )
      {
        // get next month after lastdate to get last updated date
        setFirstScrapDate(
          moment( firstScrapDate.toString() ).format( 'YYYYMM' )
        );
      }
    }

  }, [ datas.currentSERPTrend ]);
		
	return (
		<React.Fragment>
			<div className="filter-container period">
        <h4>période</h4>
        <button 
          className="filter" 
          data-for={'filter-period'} 
          data-tip
          data-event='click'
          onClick={(e) => !e.currentTarget.classList.contains('open')?togglerClassName( e.currentTarget, 'open' ):''}
          ref={contextButtonRef}
        >
          <span className="title">{periodLabel}</span>
          <span className="value">{dateLabel}</span>
          <span className="compare-value">{compareDateLabel}</span>
          <span className="arrow">{getPicto('KeyboardArrowDown', {size:"1.2rem", viewBox:"0 3 22 22"})}</span>
        </button>
        <ReactTooltip 
          id={'filter-period'}
          className="tooltip red"
          effect='solid'
          delayHide={100}
          delayShow={100}
          delayUpdate={500}
          globalEventOff='click'
          isCapture={true}
          arrowColor="transparent"
          overridePosition={({ left, top }, currentEvent, currentTarget,node, place, desiredPlace, effect, offset) => 
          {
            left = currentTarget.offsetLeft - (node.offsetWidth - currentTarget.offsetWidth);
            top = currentTarget.offsetTop + currentTarget.offsetHeight;
            
            return { top, left };
          }}
          afterHide={(e) => togglerClassName( contextButtonRef.current, 'open' )}
        >
          <div className="calendar-container" id="current-period-rangepicker">
            <RangePicker 
              picker="month"
              format="MMM YYYY"
              value={valueAnalyse}
              disabledDate={disabledDate}
              allowClear={false}
              inputReadOnly={true}
              open={true}
              getPopupContainer={trigger => trigger}              
              locale={locale}
              separator={<div>&gt;</div>}
              monthCellRender={ ( date, locale ) =>
              {
                const style = {};
                if ( date.format( 'YYYYMM' ) === firstScrapDate ) {
                  style.backgroundColor = '#999999';
                }

                return (
                  <div className="ant-picker-cell" style={style}>
                    {date.format( 'MMM' )}
                  </div>
                );
              }}
              onChange={ (moment) => {
                handleClickPredefineDate( moment, 'Personnalisée', 'analyse' );
              }}
            />
            <div className='period-filters'>
              <h4>période d'analyse</h4>
              <ul className='preselect'>
                {predefinedAnalysePeriods.map( ( predefinedAnalysePeriod, index ) => 
                  <li key={index}>
                    <button 
                      onClick={() => 
                        handleClickPredefineDate( 
                          getLastMonths( 
                            predefinedAnalysePeriod.nbmonth,
                            predefinedAnalysePeriod.nbmonth === 1 ? false : true
                          ), 
                          predefinedAnalysePeriod.label, 
                          'analyse' 
                        )
                      }
                      className={predefinedAnalysePeriod.label === periodLabel ? 'on' : ''}
                    >{predefinedAnalysePeriod.label}</button>
                  </li>
                )}
              </ul>
              <div className='date-select'>
                <select 
                  value={valueAnalyse ? valueAnalyse[0].format('YYYYMMDD') : ''}
                  onChange={elem => handleChangeSelectCustomDate( elem, 'analyse', 0 )}
                >
                  {getCustomDateOptionsList( 36 ).map((option, index) => 
                    <option key={index} value={option.value}>{option.label}</option>
                  )}
                </select>
                <select 
                  value={valueAnalyse ? valueAnalyse[1].format('YYYYMMDD') : ''}
                  onChange={elem => handleChangeSelectCustomDate( elem, 'analyse', 1 )}
                >                  
                  {getCustomDateOptionsList( 36, true ).map((option, index) => 
                    <option key={index} value={option.value}>{option.label}</option>
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className="calendar-container" id="compare-period-rangepicker">
            <RangePicker 
              picker="month"
              format="MMM YYYY"
              value={valueCompare ? valueCompare : ""}
              disabledDate={disabledDate}
              allowClear={false}
              inputReadOnly={true}
              open={true}
              getPopupContainer={trigger => trigger}              
              locale={locale}
              separator={<div>&gt;</div>}
              monthCellRender={ ( date, locale ) =>
              {
                const style = {};
                if ( date.format( 'YYYYMM' ) === firstScrapDate ) {
                  style.backgroundColor = '#999999';
                }

                return (
                  <div className="ant-picker-cell" style={style}>
                    {date.format( 'MMM' )}
                  </div>
                );
              }}
              onChange={ (moment ) => {
                handleClickPredefineDate( moment, 'Personnalisée', 'compare');
              }}
            />
            <div className='period-filters'>
              <h4>période de comparaison</h4>
              <ul className='preselect'>
                <li>
                  <button 
                    onClick={() => 
                      handleClickPredefineDate( 
                        undefined, 
                        'Aucune',
                        'compare' 
                      )
                    }
                    className={valueCompare === undefined ? 'on' : ''}
                  >Aucune</button>
                </li>
                <li>
                  <button 
                    onClick={() => 
                      handleClickPredefineDate( 
                        getPreviousRange( valueAnalyse, null ), 
                        'Période précédente',
                        'compare' 
                      )
                    }
                    className={formatDateLabel( valueCompare ) === formatDateLabel( getPreviousRange( valueAnalyse, null ) ) ? 'on' : ''}
                  >Période précédente</button>
                </li>
                <li>
                  <button 
                    onClick={() => 
                      handleClickPredefineDate( 
                        getPreviousRange( valueAnalyse, 12 ), 
                        'Année précédente',
                        'compare' 
                      )
                    }
                    className={formatDateLabel( valueCompare ) === formatDateLabel( getPreviousRange( valueAnalyse, 12 ) ) ? 'on' : ''}
                  >Année précédente</button>
                </li>
              </ul>
              <div className='date-select'>
                <select 
                  value={valueCompare ? valueCompare[0].format('YYYYMMDD') : ''}
                  onChange={elem => handleChangeSelectCustomDate( elem, 'compare', 0 )}
                >
                  <option value={undefined}>----------</option>
                  {getCustomDateOptionsList( 36 ).map((option, index) => 
                    <option key={index} value={option.value}>{option.label}</option>
                  )}
                </select>
                <select 
                  value={valueCompare ? valueCompare[1].format('YYYYMMDD') : ''}
                  onChange={elem => handleChangeSelectCustomDate( elem, 'compare', 1 )}
                >
                  <option value={undefined}>----------</option>
                  {getCustomDateOptionsList( 36, true ).map((option, index) => 
                    <option key={index} value={option.value}>{option.label}</option>
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className="confirm-container">
            <button className="confirm" onClick={ e => setFilter() }>APPLIQUER</button>
          </div>
        </ReactTooltip>
      </div>
		</React.Fragment>
	);
}

export default TopNavPeriod;