/** Dependencies **/
import React from 'react';

/** Redux **/
import { useSelector } from 'react-redux';

/** Components **/
import MyPerfDetails from './../TableTooltips/MyPerfDetails';
import MyPerfDetailsUrls from './../TableTooltips/MyPerfDetailsUrls';
import MyPerfDetailsCategory from './../TableTooltips/MyPerfDetailsCategory';

/** Helpers **/
import { getPicto } from './../../../helpers/pictos';
import { getUpdateOrAutoValue } from './../../../helpers/datas';

function Perf( props ) 
{
  /** Get state from redux store **/
  const appPathName = useSelector( state => state.appPathName.value );
  const snippetsDetails = useSelector( state => state.datas.value.currentSnippetsDetails );
  
  // Get props
  const id = props.id;
  const accessor = props.accessor;
  const label = props.label;
  const keywords = props.keywords;
  const assignedKeywords = props.assignedKeywords;
  const category1 = props.category1;
  const category2 = props.category2;
  const category3 = props.category3;
  const expectedUrl = props.expectedUrl;

  // get tx occupation (%)
  let percent = null;
  if( props?.values?.value !== undefined )
    percent = props.values.value;

  // get variation tx occupation (pt)
  let variation = null;
  if( props?.values?.variation !== undefined )
    variation = props.values.variation;

  // get positions or averages positions
  let positions = null;
  if( props?.values?.positions !== undefined )
    positions = props.values.positions;

  // split positions values for current and compare positions values
  let currentPositions = [];
  let comparePositions = [];
  if( 
    positions !== null 
    && positions instanceof Array 
    && positions.length > 0 
  ){
    currentPositions = positions.map( position => 
      parseFloat( position.substr( position.indexOf( '|' ) + 1, position.length ) )
    )

    comparePositions = positions.map( position => 
      position.substr( 0, position.indexOf( '|' )) === 'null' ? 
        null 
        : parseFloat( position.substr( 0, position.indexOf( '|' ) ) )
    ).filter( position => position !== null )
  }

  // get avg position
  let avgPosition = null;
  if( props?.values?.avgPosition !== undefined )
    avgPosition = props.values.avgPosition;

  // split avg position for current and compare values
  let currentAvgPosition = null;
  let compareAvgPosition = null;
  if( 
    avgPosition !== null 
    && typeof avgPosition === 'string' 
    && avgPosition !== ''
  ){
    const splitValue = avgPosition.split( '|' );

    if( splitValue[1] !== 'null' )
      currentAvgPosition = splitValue[1];

    if( splitValue[0] !== 'null' )
      compareAvgPosition = splitValue[0];
  }

  // get current expected url
  const currentExpectedUrl = getUpdateOrAutoValue( expectedUrl, 'expectedUrl' );

  // get types snippets except SEO because exist everytime |above fold snippets
  let currentTypesSnippets = null;  
  let abovefoldSnippets = null;
  if( 
    props?.values?.currentTypesSnippets !== undefined
    && props.values.currentTypesSnippets instanceof Array 
    && props.values.currentTypesSnippets.length > 0 
  ){
    currentTypesSnippets = props.values.currentTypesSnippets.filter( typeSnippet => !typeSnippet.endsWith( 'SEO' ) );
    abovefoldSnippets = props.values.currentTypesSnippets.filter( typeSnippet => typeSnippet.includes( '1-SEO' ) ); 
  }

  let compareTypesSnippets = null;
  if( 
    props?.values?.compareTypesSnippets !== undefined
    && props.values.compareTypesSnippets instanceof Array 
    && props.values.compareTypesSnippets.length > 0 
  )
    compareTypesSnippets = props.values.compareTypesSnippets.filter( typeSnippet => !typeSnippet.endsWith( 'SEO' ) );

  // set positiv and negativ width for hr design
  const positiveWidth = percent;
  const negativeWidth = 100 - percent;

  return (
    <div 
      className="perfs-container pointer"
      data-tip={'perfs-' + props.id} 
      data-for={'perfs-' + props.id} 
      data-arrow-color="#e43e21"         
      data-place="bottom"
    >
      <div className='hover-area'>&nbsp;</div>
      
      { percent !== null && percent > 0 ?
        <div className="line-container">
          <hr className={( positiveWidth === 0 ? 'none' : '' ) + ' positive-line' + ( abovefoldSnippets.length > 0 ? ' me' : '' )} style={{ width: positiveWidth + "%" }} />       
          <hr className={( negativeWidth === 0 ? 'none' : '' ) + ' negative-line'} style={{ width: negativeWidth + "%" }} />
        </div>
      : false }
      
      <div className='data-container'>

        {/* Positions */}
        { appPathName === 'keywords' ?
            <React.Fragment>
              { currentPositions.length > 0 ?
                <ul className='positions'>
                  {currentPositions.slice(0, 2).map( (pos, index) => 
                    <li key={index}>
                      <span className={
                        accessor === 'me' 
                        && pos !== 101
                        && index === 0 // only first position can be red
                        && snippetsDetails !== undefined
                        && snippetsDetails.filter( snippet => 
                          snippet.label === label
                          && snippet.who === accessor
                          && (
                            snippet.typeSnippet === 'SEO'
                            || snippet.typeSnippet === 'Featured Snippet'
                          )
                          && parseInt( snippet.position ) === pos
                          && snippet.url === currentExpectedUrl
                        ).length === 1 ? 
                          'current-positions' 
                          : accessor === 'me' ? 'current-positions nomatch' : ''
                      }>{ pos === 101 ? 'S' : pos }</span>
                      <span className='compare-positions'>
                        {
                          comparePositions[index] !== undefined && comparePositions[index] === 101 ? 
                            '( E )'
                          : comparePositions[index] !== undefined && comparePositions[index] !== 101 && comparePositions[index] === pos ?
                            '( = )'
                          : comparePositions[index] !== undefined && comparePositions[index] !== 101 && comparePositions[index] !== pos && pos !== 101 ?
                            '( ' + new Intl.NumberFormat("fr-FR", { style: "decimal",  signDisplay: 'always' }).format( ( comparePositions[index] - pos ) ) + ' )'
                          : comparePositions[index] !== undefined && comparePositions[index] !== 101 && comparePositions[index] !== pos && pos === 101 ?
                            '( ' + new Intl.NumberFormat("fr-FR", { style: "decimal",  signDisplay: 'always' }).format( ( comparePositions[index] - 101 ) ) + ' )'
                          : ''
                        }
                      </span>
                      {currentPositions.length > 2 && index === 1 ? <span className='etc-symbol'>...</span> : ''}
                    </li> 
                  )}                    
                </ul>
              : 
                <ul className='positions'>
                  <li><span className='current-positions'>NP</span></li>
                </ul>
              }
            </React.Fragment>

          : ( 
              appPathName === 'categories' 
              || appPathName === 'urls'
            ) ?
            <React.Fragment>
              { currentAvgPosition !== null ?
                <ul className='positions'>
                  <li>
                    {new Intl.NumberFormat("fr-FR", { maximumFractionDigits: 1 }).format( currentAvgPosition )}
                    {                      
                      compareAvgPosition !== null ?
                        <span className='compare-positions'>
                          {
                            compareAvgPosition === currentAvgPosition ?
                              '( = )'
                            : compareAvgPosition !== currentAvgPosition ?
                              '( ' + new Intl.NumberFormat("fr-FR", { maximumFractionDigits: 1, style: "decimal",  signDisplay: 'always' }).format( compareAvgPosition - currentAvgPosition ) + ' )'
                            : ''
                          }
                        </span>
                      : false
                    }
                  </li>
                </ul>
              : 
                <ul className='positions'>
                  <li><span className='current-positions'>NP</span></li>
                </ul>
              }
            </React.Fragment>
          : false
        }

        { (
            ( 
              appPathName === 'keywords' 
              || appPathName === 'categories' 
            )
            && 
            (
              percent !== null 
              || variation !== null
            )
          ) ?          
            <p className='area'>
              { percent !== null && percent > 0 ? new Intl.NumberFormat('fr-FR').format( percent ) + '%' : '' }
              { variation !== null && variation !== 0 ? '(' + new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1, style: "decimal",  signDisplay: 'always' }).format( variation ) + ')' : '' }
            </p>

          : appPathName === 'urls' && keywords.length > 0 ?
            <p className='area'>
              { currentPositions.filter( position => position !== 101 ).length }
            </p>
            
          : false 
        }

      </div>
      
      { 
        ( 
          ( currentTypesSnippets !== null && currentTypesSnippets.length > 0 )
          || 
          ( compareTypesSnippets !== null && compareTypesSnippets.length > 0 )
        ) ?
          <ul className='types-snippets'>
            { currentTypesSnippets !== null && currentTypesSnippets.length > 0 ?
                currentTypesSnippets.map( ( snippet, index ) => 
                  <li key={index} title={snippet.split( '-' )[1]}>
                    { getPicto( 
                        snippet.split( '-' )[1], 
                        { 
                          "size": "1rem", 
                          "fill": snippet.split( '-' )[0] === '1' ? '#71B8F2' : 'black' 
                        }
                      )
                    }
                    { 
                      ( 
                        compareTypesSnippets !== null 
                        && !compareTypesSnippets.includes( snippet ) 
                      ) ? 
                        <span>( E )</span> 
                        : compareTypesSnippets !== null ? <span>( = )</span> 
                        : ''
                    }
                  </li>
                ) 
              : false
            }
            { compareTypesSnippets !== null && compareTypesSnippets.length > 0 ?
                compareTypesSnippets.map( ( snippet, index ) => 
                  currentTypesSnippets !== null
                  && !currentTypesSnippets.includes( snippet ) ?
                    <li key={index} title={snippet.split( '-' )[1]}>
                      { getPicto( 
                          snippet.split( '-' )[1], 
                          { 
                            "size": "1rem", 
                            "fill": snippet.split( '-' )[0] === '1' ? '#71B8F2' : 'black' 
                          }
                        )
                      }
                      <span>( S )</span>
                    </li>
                  : false
                )
              : false
            }
          </ul>
          : false 
      }

      { appPathName === 'categories' ?
          <MyPerfDetailsCategory 
            id={id} 
            accessor={accessor} 
            label={label} 
            keywords={keywords} 
            category1={category1}
            category2={category2}
            category3={category3}
          />
        : appPathName === 'urls' ?
          <MyPerfDetailsUrls
            id={id} 
            accessor={accessor} 
            label={label}
            keywords={keywords} 
            assignedKeywords={assignedKeywords} 
          />
        : <MyPerfDetails
            id={id} 
            accessor={accessor} 
            label={label}
            expectedUrl={expectedUrl}
          />
      }

    </div>
  );
}

export default Perf;