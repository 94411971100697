/** Dependencies **/
import React from "react";

/** Helpers **/
import { getPicto } from './../../../helpers/pictos';

function SortFilterRemove ( props )
{
  /** Get from props **/
  let label = props.label;
  const isSortedDesc = props.isSortedDesc;
  const clearSortBy = props.clearSortBy;
  
  /** Set options for sort picto **/
  let options = { size:"1rem" };
  if( isSortedDesc === false )
    options = { size:"1rem", transform:"rotate(180)" };

  return (
    <div className='column-header-reminder'>
      <div className="label">{getPicto( 'Sort', options )}{ label }</div>
      <button onClick={() => clearSortBy() }>{getPicto( 'closer', { size:"1rem" } )}</button>
    </div>
  )
}

export default SortFilterRemove;