/** Dependencies **/
import React from 'react';

/** Redux **/
import { useSelector } from 'react-redux';

/** Helpers **/
import { getIsIgnore } from './../../../helpers/datas';

function GSCCTRTotalCell( props ) 
{
  /** Get state from redux store **/
  const datas = useSelector( state => state.datas.value );

  // filter datas with non ignore value
  let nonIgnoreDatas = null;
  if( datas.filterResult )
    nonIgnoreDatas = datas.filterResult.filter( item => !getIsIgnore( item ) );

  // get impressions summary
  let sumImpr = null;
  if( nonIgnoreDatas )
    sumImpr = nonIgnoreDatas.filter( item => 
      item.gsc.impressions instanceof Object
    ).map( item => 
      item.gsc.impressions.value 
    ).reduce( (a, b) => a + parseInt( b ), 0 );

  // get clicks summary
  let sumClicks = null;
  if( nonIgnoreDatas )
    sumClicks = nonIgnoreDatas.filter( item => 
      item.gsc.clicks instanceof Object
    ).map( item => 
      item.gsc.clicks.value 
    ).reduce( (a, b) => a + parseInt( b ), 0 );

  // set ctr
  const sumCTR = sumClicks / sumImpr * 100;

  // get impressions summary
  let varImpr = null;
  if( nonIgnoreDatas )
    varImpr = nonIgnoreDatas.filter( item => 
      item.gsc.impressions instanceof Object
    ).map( item => 
      item.gsc.impressions.variation 
    ).reduce( (a, b) => a + parseInt( b ), 0 );

  // get clicks summary
  let varClicks = null;
  if( nonIgnoreDatas )
    varClicks = nonIgnoreDatas.filter( item => 
      item.gsc.clicks instanceof Object
    ).map( item => 
      item.gsc.clicks.variation 
    ).reduce( (a, b) => a + parseInt( b ), 0 );

  // set ctr
  const varCTR = sumCTR - (sumClicks - varClicks) / (sumImpr - varImpr) * 100;
  
  return (
    <React.Fragment>
      {(
        sumCTR !== null
        && !isNaN( sumCTR )
        && sumCTR > 0
      ) ? 
        <div>{ new Intl.NumberFormat( 'fr-FR', { maximumFractionDigits: 1 } ).format( sumCTR ) }%</div>
        : false
      }
      {(
        varCTR !== null
        && !isNaN( varCTR )
        && varCTR !== 0
      ) ? 
        <div className='variation'>{ new Intl.NumberFormat('fr-FR', { signDisplay: 'always', maximumFractionDigits: 1 }).format( varCTR ) }</div>
        : false
      }
    </React.Fragment>
  );
}

export default GSCCTRTotalCell;