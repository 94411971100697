/** Components **/
import DefaultRangeFilterToolTip from './../../../components/Table/FilterToolTips/DefaultRangeFilterToolTip';
import TableHeaderTitleWithPicto from './../../../components/Table/TableHeaders/TitleWithPicto';
import DefaultSumTotalCell from './../../../components/Table/TableTotalCells/DefaultSumTotalCell';
import TableCellGSC from './../../../components/Table/TableCells/GSC';
import GSCFilterToolTip from './../../../components/Table/FilterToolTips/GSCFilterToolTip';

export const GscImpressionsColumn = ( appPathName, widthColumns ) => 
{
  return {
    accessor: "gsc.impressions",
    width: widthColumns["impressions"],
    title: "impressions",
    className: "col-gsc",          
    Filter: (
      appPathName === 'keywords' 
      || appPathName === 'gsc-keywords'
    ) ? 
      GSCFilterToolTip 
      : DefaultRangeFilterToolTip,
    filter: 'multiFieldsCustomFilter',
    filterTitle: 'volume d\'impression',
    sortDescFirst: true,          
    sortType: 'valueSort',
    Header: props => 
      <TableHeaderTitleWithPicto id={props.column.id} title={props.column.title} />,
    Total: props => 
      <DefaultSumTotalCell id={props.column.id} />,
    Cell: props => 
      <TableCellGSC 
        id={props.cell.row.id}
        values={props.value} 
        accessor={props.column.id} 
        label={props.cell.row.values.label}
        keywords={props.cell.row.original.keywords}
      />
  }
}