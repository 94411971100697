import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from './../store';

const initialState = {
  value: null
};

export const appPathNameSlice = createSlice({
  name: 'appPathName',
  initialState,
  reducers: {
    updateAppPathName: ( state, action ) => 
		{
      state.value = action.payload;
    }
  },
  extraReducers: builder => builder.addCase( revertAll, () => initialState )
})

export const { updateAppPathName } = appPathNameSlice.actions

export default appPathNameSlice.reducer