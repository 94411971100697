/** Dependencies **/
import React from 'react';

/** Redux **/
import { useSelector } from 'react-redux';

/** Helpers **/
import { getPicto } from './../../../helpers/pictos.js';

/** JSON */
import globalConfig from './../../../assets/json/config.json';

function MyPerf( props ) {

  /** Get props **/
  let id = props.id;

  /** Get state from redux store */
  const appPathName = useSelector( state => state.appPathName.value );  

  /** Get picto name **/
  let name = globalConfig.barometer.picto_with_titles[id].name;
  let options = globalConfig.barometer.picto_with_titles[id].options;

  return (
    <React.Fragment>
      { getPicto( name, options ) }
      <h4>positions&nbsp;|&nbsp;</h4>
      <h4>{ appPathName === 'urls' ? 'nb. mots' : 'tx occup.' }</h4>
    </React.Fragment>
  );
}

export default MyPerf;