/** Dependencies **/
import React from 'react';

/** Redux **/
import { useSelector } from 'react-redux';

/** Helpers **/
import { getIsIgnore } from './../../../helpers/datas';

function GSCPositionTotalCell( props ) 
{
  /** Get state from redux store **/
  const datas = useSelector( state => state.datas.value );

  // filter datas with non ignore value
  let nonIgnoreDatas = null;
  if( datas.filterResult )
    nonIgnoreDatas = datas.filterResult.filter( item => !getIsIgnore( item ) );

  /** Get current and compare average position **/
  let currentAveragePosition = null;
  let compareAveragePosition = null;
  let variation  = null;
  if( nonIgnoreDatas )
  {
    // get current average position
    let currentPositionElements = nonIgnoreDatas.filter( item => 
      item.gsc.position
    )
    
    let currentSumPos = currentPositionElements.map( item => 
      item.gsc.position.value
    ).reduce( (a, b) => b !== null ? a + parseFloat( b ):a, 0 );

    if( !isNaN( currentSumPos ) )
      currentAveragePosition = currentSumPos / currentPositionElements.length;
    // get compare average position
    let comparePositionElements = nonIgnoreDatas.filter( item => 
      item.gsc.position
    )
    
    let compareSumPos = comparePositionElements.map( item => 
      item.gsc.position.value - item.gsc.position.variation
    ).reduce( (a, b) => b !== null ? a + parseFloat( b ):a, 0 );
    
    if( !isNaN( compareSumPos ) )
      compareAveragePosition = compareSumPos / comparePositionElements.length;
    
      // get variation of avergae position
    if( !isNaN( currentAveragePosition ) && !isNaN( compareAveragePosition ) )
      variation = currentAveragePosition - compareAveragePosition;
  }
  
  return (
    <React.Fragment>
      {(
        currentAveragePosition !== null 
        && !isNaN( currentAveragePosition )
        && currentAveragePosition > 0
      ) ? 
        <div>{new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1 }).format( currentAveragePosition )}</div>
        : false
      }
      {(
        variation !== null 
        && !isNaN( variation )
        && variation !== 0
      ) ? 
        <div className='variation'>{new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1, signDisplay: 'always' }).format( variation )}</div>
        : false
      }
    </React.Fragment>
  );
}

export default GSCPositionTotalCell;