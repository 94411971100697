/** Dependencies **/
import React from 'react';

/** Redux **/
import { useSelector } from 'react-redux';

/** Helpers **/
import { getIsIgnore } from './../../../helpers/datas';

function AreaTotalCell( props ) 
{
  /** Get props **/
  const id = props.id;

  /** Get state from redux store **/
  const datas = useSelector( state => state.datas.value );
  
  // filter datas with non ignore value
  let nonIgnoreDatas = null;
  if( datas.filterResult )
    nonIgnoreDatas = datas.filterResult.filter( item => !getIsIgnore( item ) );

  /** Set starts key of reference value to get total sum **/
  let startKeyRefValue = 'snippets';
  
  let currentPercentValue = null;
  let comparePercentValue = null
  let variation = null;

  if( nonIgnoreDatas )
  {
    /** Get percent for current period **/
    // get total summary value for current periode
    let currentTotalSumValue = nonIgnoreDatas.map( item => 
      Object.keys( item ).filter( key => 
        key.startsWith( startKeyRefValue ) 
        && !getIsIgnore( item )
      ).map( key => 
        item[key].currentArea
      ).reduce( (a, b) => b ? a + parseInt( b ):a, 0 )
    ).reduce( (a, b) => a + parseInt( b ), 0 );

    // // get columns snippets summary value
    let currentGrpSumValue = nonIgnoreDatas.filter( item => 
      item[id] 
    ).map( item => 
      item[id].currentArea 
    ).reduce( (a, b) => b ? a + parseInt( b ):a, 0 );
      
    // get current percent value for current period
    if( currentTotalSumValue !== null && currentGrpSumValue !== null )
      currentPercentValue =  Math.round( currentGrpSumValue / currentTotalSumValue * 100 );

    /** Get percent for compare period **/
    // get total summary value for compare periode
    let compareTotalSumValue = nonIgnoreDatas.map( item => 
      Object.keys( item ).filter( key => 
        key.startsWith( startKeyRefValue ) 
        && !getIsIgnore( item )
      ).map( key => 
        item[key].compareArea
      ).reduce( (a, b) => b ? a + parseInt( b ):a, 0 )
    ).reduce( (a, b) => a + parseInt( b ), 0 );

    // get columns snippets summary value for compare period
    let compareGrpSumValue = nonIgnoreDatas.filter( item => 
      item[id] 
    ).map( item => 
      item[id].compareArea 
    ).reduce( (a, b) => b ? a + parseInt( b ):a, 0 );
    
    // get compare percent value    
    if( compareTotalSumValue !== null && compareGrpSumValue !== null )
      comparePercentValue =  Math.round( compareGrpSumValue / compareTotalSumValue * 100 );

    // /** Get variation of percent for 2 periods **/ 
    if( !isNaN( currentPercentValue ) && !isNaN( comparePercentValue ) )
      variation = currentPercentValue - comparePercentValue;
  }

  return (
    <React.Fragment>
      {(
        currentPercentValue !== null
        && !isNaN( currentPercentValue )
        && currentPercentValue > 0
      ) ? 
        <div>{currentPercentValue}<span className="percent">%</span></div>
        :
        false
      }
      {(
        variation !== null
        && !isNaN( variation )
        && variation !== 0
      ) ? 
        <div className='variation'>{new Intl.NumberFormat("fr-FR", { style: "decimal",  signDisplay: 'always' }).format( variation )}</div>
        : false
      }
    </React.Fragment>
  );
}

export default AreaTotalCell;