/** Dependencies **/
import React, {useState, useRef, useEffect} from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { useNavigate } from "react-router-dom";

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';

/** Components **/
import DataVizChartPerfsPositionsTrend from './DataVizCharts/PerfsPositionsTrend';
import Loader from './../../Loader/Loader';

import { 
	getPicto
} from './../../../helpers/pictos';

/** Helpers **/
import { callWebservice } from './../../../helpers/webservice/webserviceCaller';
import { removeDomain } from './../../../helpers/functions';
import { filterOnValueToContext } from './../../../helpers/barometer/barometer';
import { getUpdateOrAutoValue } from './../../../helpers/datas';

/** JSON */
import globalConfig from './../../../assets/json/config.json';

function MyPerfDetails( props ) 
{
	// Get props
  const id = props.id;
  const snippetID = 'perfs-' + id;
  const accessor = props.accessor;
  const label = props.label;
  const expectedUrl = props.expectedUrl;

  // get current expected url
  const currentExpectedUrl = getUpdateOrAutoValue( expectedUrl, 'expectedUrl' );
    
  // Define ref
	const tooltipSnippet = useRef( null );

  /** Init state **/
  const [popinContent, setPopinContent] = useState( null );
  const [positionsTrends, setPositionsTrends] = useState( null );
  const [pastCurrentURLs, setPastCurrentURLs] = useState( null );

  /** Get state from redux store **/
  const globalResult = useSelector( state => state.datas.value.globalResult );
  const selectedFilters = useSelector( state => state.selectedFilters.value );
  const currentSnippetsDetails = useSelector( state => state.datas.value.currentSnippetsDetails );
  const compareSnippetsDetails = useSelector( state => state.datas.value.compareSnippetsDetails );
  const selectedInstance = useSelector( state => state.selectedInstance.value );

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Instance navigate object */
  const navigate = useNavigate();

  /** Set groups of snippets from config file **/
  let snippets = globalConfig.barometer.snippets;

  /** Set mini url Trends period */
  const miniTrendStartDate = moment( selectedFilters.currentPeriod.enddate ).subtract( 5, 'month' ).startOf( 'month' );
  const miniTrendEndDate = moment( selectedFilters.currentPeriod.enddate ).endOf( 'month' );

  /** Set empty trend position array in period */
  const periodDuration = moment.duration( miniTrendEndDate.diff( miniTrendStartDate ) ).months();

  let emptyTrend = [];
  for( let i = 0 ; i <= periodDuration ; i++ )
  {
    emptyTrend = [...emptyTrend, {
      x: moment( miniTrendStartDate.clone().add( i, 'months' ) ).format('YYYYMM'),
      y: 101
    }];
  }

  /**
	 * Load content of popin :: Perf positions Trend || ranked urls
	 */
	const loadContent = () => 
	{
		if( label !== '' && accessor !== '' )
		{
      // positions trend
      callWebservice(
				snippetID,
				snippetID + '-positionsTrend',
				'perf-positions-trend',
				dispatch,
        selectedInstance,
				{
					period: {
            startdate: miniTrendStartDate.format( 'YYYYMMDD' ),
            enddate: miniTrendEndDate.format( 'YYYYMMDD' )
          },          
					where: {
            keywords: [label],
            who: accessor,
            locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
            devices: [selectedFilters.devLoc.deviceValue]
          }
				},
				{ function: 'setPositionsTrends' }
			);

      // ranked urls in current period except current month
      if( 
        selectedFilters.currentPeriod 
        && selectedFilters.currentPeriod.startdate !== selectedFilters.currentPeriod.enddate
      ){
        // Snippets details
        callWebservice(
          snippetID,
          snippetID + '-pastCurrentURLs',
          'snippet-details',
          dispatch,
          selectedInstance,
          {
            cols: ["keyword, keyword as label", "url", "position", "typeSnippet", "grpSnippet", "who", "area"],
            period: {
              startdate: selectedFilters.currentPeriod.startdate,
              enddate: moment( selectedFilters.currentPeriod.enddate ).subtract( 1, 'month' ).format( 'YYYYMMDD' )
            },
            where: {
              who: [accessor],
              keywords: [label],
              locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
              devices: [selectedFilters.devLoc.deviceValue]
            },
            sort: [{"orderby": "CAST( position AS DECIMAL )", "sort": "ASC"}]
          },
          { function: 'setPastCurrentURLs' }
        );

      } else
        setPastCurrentURLs( [] );
		}
	}

	/**
   * Build popin content with current and compare loaded datas of snippets details
   */
  const buildPopinContent = () => 
  {
    let content = [];

    // get current datas
    const currentDatas = currentSnippetsDetails.filter( detail => 
      detail.label === label && detail.who === accessor 
    );

    // get compare datas
    let compareDatas = null;
    if( compareSnippetsDetails.length > 0 )
      compareDatas = compareSnippetsDetails.filter( detail => 
        detail.label === label && detail.who === accessor 
      );

    // init disappeared snippets
    let disappearedDatas = null;
    if( compareDatas !== null )
      disappearedDatas = compareDatas.map( compareData => 
        currentDatas.filter( currentData => 
          compareData.url === currentData.url 
          && compareData.typeSnippet === currentData.typeSnippet ? true : false
        ).length === 0 ? compareData : false
      ).filter( data => data !== false );

    // get total snippet area for current keyword
    const totalArea = currentSnippetsDetails.filter( detail => 
      detail.label === label 
      && parseInt( detail.position ) < 11
      && detail.typeSnippet !== "Ads" 
      && detail.typeSnippet !== "Shopping"
    ).map( snippet => snippet.area ).reduce( ( a, b ) => a + b, 0 );

    // filter on SEO and Other
    const currentDatasSEO = currentDatas.filter( snippet => 
      snippet.typeSnippet === 'SEO' 
      || snippet.typeSnippet === 'Featured Snippet' 
    );

    let compareDatasSEO = null;
    if( compareDatas !== null )
      compareDatasSEO = compareDatas.filter( snippet => 
        snippet.typeSnippet === 'SEO' 
        || snippet.typeSnippet === 'Featured Snippet' 
      );

    const currentDatasOther = currentDatas.filter( snippet => 
      snippet.typeSnippet !== 'SEO' 
      && snippet.typeSnippet !== 'Featured Snippet' 
      && snippet.typeSnippet !== 'Ads' 
      && snippet.typeSnippet !== 'Shopping' 
    );

    let compareDatasOther = null;
    if( compareDatas !== null )
      compareDatasOther = compareDatas.filter( snippet => 
        snippet.typeSnippet !== 'SEO' 
        && snippet.typeSnippet !== 'Featured Snippet' 
        && snippet.typeSnippet !== 'Ads' 
        && snippet.typeSnippet !== 'Shopping' 
      );

    let disappearedDatasSEO = [];
    let disappearedDatasOther = [];
    if( disappearedDatas !== null )
    {
      disappearedDatasSEO = disappearedDatas.filter( snippet => 
        snippet.typeSnippet === 'SEO'
        || snippet.typeSnippet === 'Featured Snippet'
      );
      
      disappearedDatasOther = disappearedDatas.filter( snippet => 
        snippet.typeSnippet !== 'SEO' 
        && snippet.typeSnippet !== 'Featured Snippet' 
        && snippet.typeSnippet !== 'Ads' 
        && snippet.typeSnippet !== 'Shopping' 
      );
    }

    let pastCurrentURLsSEO = [];
    let pastCurrentURLsOther = [];
    if( pastCurrentURLs !== null && pastCurrentURLs.length > 0 )
    {
      pastCurrentURLsSEO = [...new Set(
        pastCurrentURLs.filter( url => 
          (
            url.typeSnippet === 'SEO'
            || url.typeSnippet === 'Featured Snippet'
          )
          && !currentDatasSEO.map( data => data.url ).includes( url.url )
          && (
            compareDatasSEO === null
            ||
            (
              compareDatasSEO !== null
              && !compareDatasSEO.map( data => data.url ).includes( url.url )
            )
          )
        ).map( url => url.url )
      )];

      pastCurrentURLsOther = [...new Set(
        pastCurrentURLs.filter( url => 
          (
            url.typeSnippet !== 'SEO' 
            && url.typeSnippet !== 'Featured Snippet' 
            && url.typeSnippet !== 'Ads' 
            && url.typeSnippet !== 'Shopping' 
          )
          && !currentDatasOther.map( data => data.url ).includes( url.url )
          && (
            compareDatasOther === null
            ||
            (
              compareDatasOther !== null
              && !compareDatasOther.map( data => data.url ).includes( url.url )
            )
          )
        ).map( url => url.url )
      )];
    }

    /** Add SEO */
    // SEO expected url
    if( accessor === 'me' )
    {
      content = [...content, 
        <table className='expected-url' key='expected-url-block-1'>
          <thead>
            <tr>
              <th colSpan="2">
                <h4>URL attendue</h4>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='link'>
                { currentExpectedUrl === null ? 'URL à renseigner' : currentExpectedUrl }
              </td>
              <td>
                <button 
                  type="button" 
                  onClick={ () => 
                  {
                    let element = document.getElementById( 'expectedUrl-' + id.replace( 'me-', '' ) );
                    if( element )
                      ReactTooltip.show( element.parentElement );
                  }}
                >{ currentExpectedUrl === null ? 'Renseigner' : 'Modifier' }</button>
              </td>
            </tr>
          </tbody>
        </table>
      ];
    }

    /** Add no url ranked */
    if( 
      currentDatasSEO.length === 0
      && disappearedDatasSEO.length === 0
      && pastCurrentURLsSEO.length === 0
      && currentDatasOther.length === 0
      && disappearedDatasOther.length === 0
      && pastCurrentURLsOther.length === 0
    ){
      content = [...content, 
        <table key="table-noranked">
          <thead className='header'>
            <tr>
              <th>
                <h4>SEO</h4>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Aucune url positionnées sur cette période d'analyse</td>
            </tr>
          </tbody>
        </table>
      ];

    } else {

      // SEO title
      let headerContent = [];
      if( currentDatasSEO.length > 0 || pastCurrentURLsSEO.length > 0 || disappearedDatasSEO.length > 0 )
        headerContent = [...headerContent,         
          <tr key="title-seo">
            <th colSpan="4">
              <h4>SEO</h4>
            </th>
          </tr>
        ];

      // SEO current content
      let bodyContent = [];
      if( currentDatasSEO.length > 0 )
        bodyContent = [...bodyContent, <React.Fragment key={'tbody-seo'}>
            {currentDatasSEO.sort( ( a, b ) => a.position - b.position ).map( ( snippet, index ) => 
              <tr key={'content-seo-' + index}>
                <td className="type-snippet">

                  {/* Display positions or E or = */}
                  <span
                    className={ 
                      accessor === 'me' 
                      && index === 0 // only first position can be red
                      && currentExpectedUrl === snippet.url ? 
                        '' 
                      : accessor === 'me' ? 'nomatch' : '' 
                    }
                  >
                    {snippet.position}&nbsp;
                  </span>

                  {/* Display variation or E */}
                  {
                    compareDatasSEO !== null ?
                      getCompareDatasByURL( snippet, compareDatasSEO ).length === 0 ?
                        '( E )'
                      : getCompareDatasByURL( snippet, compareDatasSEO )[0].position === snippet.position ?
                        '( = )'
                      : getCompareDatasByURL( snippet, compareDatasSEO )[0].position !== snippet.position  ? 
                        '( '
                        +  
                          new Intl.NumberFormat( "fr-FR", { style: "decimal",  signDisplay: 'always' }).format( 
                            getCompareDatasByURL( snippet, compareDatasSEO )[0].position - snippet.position 
                          )                
                        + 
                        ' )'
                      : false
                    : false
                  }

                  {/* Display % (taux occupation) */}
                  {
                    parseInt( snippet.position ) < 11 ? 
                      ' | ' + Math.round( snippet.area / totalArea * 100 ) + '%' 
                      : false 
                  }
                </td>
                <td>
                  <button className='internal' onClick={ () => filterOnValueToContext( snippet.url, 'label', 'urls', navigate ) } title={snippet.url}>{removeDomain( snippet.url )}</button>
                </td>
                <td>
                  <a className='external' href={snippet.url} target="_blank" rel="noreferrer" title={snippet.url}>{getPicto( 'ExternalLinkOutline', { size: "1rem" })}</a>
                </td>
                <td className='perf-positions-trend'>
                  <DataVizChartPerfsPositionsTrend 
                    datas={ positionsTrends[snippet.url] ? positionsTrends[snippet.url] : emptyTrend }
                  />
                </td>
              </tr>
            )}
          </React.Fragment>  
        ];

      // SEO disappeared content
      if( disappearedDatasSEO.length > 0 )
        bodyContent = [...bodyContent, <React.Fragment key={'tbody-seo-disappeared'}>
          {disappearedDatasSEO.map( ( snippet, index ) => 
            <tr key={index}>
              <td className="type-snippet">
                <span
                  className={ 
                    accessor === 'me' 
                    && index === 0 // only first position can be red
                    && currentExpectedUrl === snippet.url ? 
                      '' 
                    : accessor === 'me' ? 'nomatch' : '' 
                  }
                >S&nbsp;</span>
                {
                  '( ' + 
                  ( 
                    new Intl.NumberFormat( "fr-FR", { style: "decimal",  signDisplay: 'always' }).format( 
                      snippet.position - 101
                    )
                  )
                  + ' )'
                }
              </td>
              <td>
                <button className='internal' onClick={ () => filterOnValueToContext( snippet.url, 'label', 'urls', navigate ) } title={snippet.url}>{removeDomain( snippet.url )}</button>
              </td>
              <td>
                <a className='external' href={snippet.url} target="_blank" rel="noreferrer" title={snippet.url}>{getPicto( 'ExternalLinkOutline', { size: "1rem" })}</a>
              </td>
              <td className='perf-positions-trend'>
                <DataVizChartPerfsPositionsTrend 
                  datas={ positionsTrends[snippet.url] ? positionsTrends[snippet.url] : emptyTrend }
                />
              </td>
            </tr>
          )}
        </React.Fragment>];

      // SEO past current period content
      if( pastCurrentURLsSEO.length > 0 )
        bodyContent = [...bodyContent, <React.Fragment key={'tbody-seo-past'}>
          {pastCurrentURLsSEO.map( ( url, index ) => 
            <tr key={index}>        
              <td className="type-snippet">
                <span>NP&nbsp;</span>
              </td>
              <td>
                <button className='internal' onClick={ () => filterOnValueToContext( url, 'label', 'urls', navigate ) } title={url}>{removeDomain( url )}</button>
              </td>
              <td>
                <a className='external' href={url} target="_blank" rel="noreferrer" title={url}>{getPicto( 'ExternalLinkOutline', { size: "1rem" })}</a>
              </td>
              <td className='perf-positions-trend'>
                <DataVizChartPerfsPositionsTrend 
                  datas={ positionsTrends[url] ? positionsTrends[url] : emptyTrend }
                />
              </td>
            </tr>
          )}
        </React.Fragment>];

      // Merge SEO content
      content = [...content, 
        <table key="table-seo" className="seo-part">
          <thead className="header">
            { headerContent }
          </thead>
          <tbody>
            { bodyContent }
          </tbody>
        </table>
      ];

      /** Add snippets */
      // snippets title
      headerContent = [];
      if( currentDatasOther.length > 0 || disappearedDatasOther.length > 0 )
        headerContent = [...headerContent,         
          <tr key="title-other">
            <th colSpan="3">
              <h4>Types de résultats</h4>
            </th>
          </tr>
        ];

      // snippets current content
      bodyContent = [];
      if( currentDatasOther.length > 0 )
        bodyContent = [...bodyContent, <React.Fragment key={'tbody-other'}>
          {currentDatasOther.sort( ( a, b ) => a.position - b.position ).map( ( snippet, index ) => 
            <tr key={'content-other-' + index}>            
              <td className="type-snippet">

                {/* Snippet label */}
                {snippets[snippet.typeSnippet].label}&nbsp;

                {/* Display variation or E */}
                {         
                  compareDatasOther !== null ?
                    getCompareDatasByURL( snippet, compareDatasOther ).length === 0 ?
                      '( E )'
                    : 
                      '( = )'
                  : false
                }

                {/* Display % (taux occupation) */}
                { 
                  parseInt( snippet.position ) < 11 ? 
                    ' | ' + new Intl.NumberFormat( "fr-FR", { maximumFractionDigits: 0, style: "percent" }).format(
                      Math.round( snippet.area / totalArea )
                    )
                    : false 
                }
              </td>
              <td>
                <button className='internal' onClick={ () => filterOnValueToContext( snippet.url, 'label', 'urls', navigate ) } title={snippet.url}>{removeDomain( snippet.url )}</button>
              </td>
              <td>
                <a className='external' href={snippet.url} target="_blank" rel="noreferrer" title={snippet.url}>{getPicto( 'ExternalLinkOutline', { size: "1rem" })}</a>
              </td>
            </tr>
          )}
        </React.Fragment>];

      // snippets disappeared content
      if( disappearedDatasOther.length > 0 )
        bodyContent = [...bodyContent, <React.Fragment key={'tbody-other-disappeared'}>
          {disappearedDatasOther.map( ( snippet, index ) => 
            <tr key={index}>
              <td className="type-snippet">
                {snippets[snippet.typeSnippet].label}&nbsp;( NP )
              </td>
              <td>
                <button className='internal' onClick={ () => filterOnValueToContext( snippet.url, 'label', 'urls', navigate ) } title={snippet.url}>{removeDomain( snippet.url )}</button>
              </td>
              <td>
                <a className='external' href={snippet.url} target="_blank" rel="noreferrer" title={snippet.url}>{getPicto( 'ExternalLinkOutline', { size: "1rem" })}</a>
              </td>
            </tr>
          )}
        </React.Fragment>];

      // Merge SNIPPETS content
      content = [...content, 
        <table key="table-other" className='other-part'>
          <thead className="header">
            { headerContent }
          </thead>
          <tbody>
            { bodyContent }
          </tbody>
        </table>
      ];
    }

    // Set popin content
    setPopinContent( content.length > 0 ? content : null );
  }
  
  /**
   * Return compareDatas filtered on current snippet url and type SEO or Featured
   * @param {Object} snippet 
   * @param {Array} compareDatas 
   * @returns Array
   */
  const getCompareDatasByURL = ( snippet, compareDatas ) =>
  {
    return compareDatas.filter( data => 
      data.url === snippet.url 
      && data.grpSnippet === snippet.grpSnippet
    );
  }

  /**
   * Build content when datas are loaded
   */
  useEffect( () => 
  {
    if( positionsTrends !== null && pastCurrentURLs !== null )
      buildPopinContent();
  }, [ 
    positionsTrends, 
    pastCurrentURLs,
    currentSnippetsDetails, 
    compareSnippetsDetails, 
    globalResult, 
    currentExpectedUrl 
  ]);

	return (
		<ReactTooltip 
			id={snippetID}
			ref={tooltipSnippet}
			className="tooltip grey"
			effect='solid'
			delayHide={100}
			delayShow={0}
			delayUpdate={100}
			afterHide={ () => {
				tooltipSnippet.current.tooltipRef.style.left = null;
				tooltipSnippet.current.tooltipRef.style.top = null;
			}}
      afterShow={loadContent}
			globalEventOff={'click'}
      isCapture={true}
      overridePosition={ (
        position,
        currentEvent,
        currentTarget,
        node
      ) => {
        // get width of tooltip
        let width = node.offsetWidth;
        
        return {
          left: - width / 2 - 20,
          top: 50
        };
      }}
		>
      <React.Fragment>
        <Loader 
          loaderID={snippetID} 
          loaderStyle={{width:'15', stroke:'#e43e21'}} 
          callBackFcts={{
            setPositionsTrends: setPositionsTrends,
            setPastCurrentURLs: setPastCurrentURLs
          }}
          globalCallBack={undefined} 
        />
        {popinContent !== null ?
          <div className='tooltip-container'>
            {popinContent}
          </div>
          : false
        }
      </React.Fragment>      
		</ReactTooltip>			
	);
}

export default MyPerfDetails;