/** Dependencies **/
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLoading, Oval } from '@agney/react-loading';

/** Redux **/
import { removeLoader } from './../../reducers/loaders';

/** SCSS **/
import './Loader.scss';

function Loader( props ) 
{
  /** init props **/
  const {
    loaderID,
    loaderStyle,
    globalCallBack,
    resultCallBack,
    callBackFcts
  } = props;  

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Init status loading **/
  const [isActiveLoader, setIsActiveLoader] = useState( false );
  const [error, setError] = useState( null );
  const [messageLoader, setMessageLoader] = useState([]);
  const [currentRequestsEnqueue, setCurrentRequestsEnqueue] = useState( [] );
  const [abortController, setAbortController] = useState( new AbortController() );  

  /** Get state from redux store **/
  const loaders = useSelector( state => state.loaders.value );

  // instance of loading indicator
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    loaderProps: loaderStyle,
    indicator: <Oval />
  });

  /**
   * load function for all url in request var
   * 
   * @param {object} request 
   * {
      id: 'filters',
      method: 'POST',
      url: urlRequestFiltersCategory.url,
      data: urlRequestFiltersCategory.data,
      callback: callbackFiltersLoader,
      message: 'Mise à jour des catégories'
    }
   * @returns 
   */
  async function load( request ) 
  { 
    // add message loader
    if( messageLoader.filter( message => message.id === request.id && message.message === request.message ).length === 0 )
      setMessageLoader( messageLoader => [...messageLoader, {id: request.id, message: request.message}] );    

    // set fetch options
    let options = {};
    if( request.method === 'POST' )
      options = {
        signal: abortController.signal,
        method: request.method,
        body: JSON.stringify( request.data )
      }
    else
      options = { 
        signal: abortController.signal,
        method: request.method        
      }

    // call request url
    const response = await fetch( request.url, options );
    
    // return response
    const result = await response.json();

    // remove message loader    
    setMessageLoader( messageLoader => messageLoader.filter( messages => messages.id !== request.id ) );

    return result;
  }

  /** Update local request pool when loaders updated **/
  useEffect( () => 
  { 
    if( loaders[loaderID] instanceof Array )
    {
      if( loaders[loaderID].length > 0 )
      {
        // set active loader
        setIsActiveLoader( true );

        // get common values between loaders and currentRequestsEnqueue
        const commonValues = loaders[loaderID].map( loader => 
          loader.id 
        ).filter( value => 
          currentRequestsEnqueue.map( request => request.id ).includes( value ) 
        );

        // abort current fetch request if new loader replace it
        if( commonValues.length > 0 )
        {          
          abortController.abort();
          setAbortController( new AbortController() );
        }

        // set current requests enqueue values
        let newRequests = [];        
        loaders[loaderID].forEach( request => 
        {
          // add request to new requests array
          newRequests = [...newRequests, request];

          // remove request from currentRequest   
          dispatch( removeLoader( { loaderID: loaderID, request: request.id } ) );
        });

        setCurrentRequestsEnqueue( newRequests );
      }
    }
  }, [ loaders ]);

  /** Launch new requests from local request pool **/
  useEffect( () => 
  {
    /** Set status loading **/
  	setError( null );

    if( currentRequestsEnqueue.length > 0 )
    {
      Promise.all( 
        currentRequestsEnqueue.map( request => 
          load( request ).then( 
            results => 
            { 
              // callback function if passed 
              if( request?.callback?.function !== undefined && callBackFcts[request.callback.function] )
                callBackFcts[request.callback.function].apply( 
                  this, 
                  request.callback.params instanceof Array ? [ results, ...request.callback.params ] : [ results ] 
                )

              return results;
            },
            error => {
              throw new Error( error );
            }
          )
        )
      ).then( values => {

        // set inactive loader
        setIsActiveLoader( false );

        // callback global function if exist
        if( globalCallBack ){
          globalCallBack.apply( 
            this, 
            [ values ]
          );
        }
          
      }).catch( error => 
        setError( error )
      );
    }    
  }, [ currentRequestsEnqueue ]);

  useEffect( () => 
  {
    if( error !== null )
      console.error( error.message );

  }, [error] );
  
  return (
    <div {...containerProps} id={'loader-' + loaderID} className={ isActiveLoader ? 'loader-container' : 'loader-container hide' }>
      <ul>
        <li className='indicator'>{indicatorEl}</li>        
        {messageLoader.map( (messages, index) => 
          <li key={index}>{messages.message}</li>
        )}
      </ul>
      <div className="loader"></div>
    </div>
  );
}

export default Loader;