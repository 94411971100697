/** Dependencies **/
import React, { useEffect, useState, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Sticky from 'react-sticky-el'
import { useSearchParams } from "react-router-dom";
import moment from 'moment';

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { updateFiltersDatas } from './../../../reducers/filtersDatas';
import { updateDatas } from './../../../reducers/datas';
import { updateSelectedHeaderFilters } from './../../../reducers/selectedHeaderFilters';
import { updateLoadersTriggers } from './../../../reducers/loadersTriggers';

/** Helpers **/
import { callWebservice } from './../../../helpers/webservice/webserviceCaller';
import { getPicto } from './../../../helpers/pictos';
import { getScrapDate } from './../../../helpers/datas';
import { updateColumnsFilters } from './../../../helpers/barometer/barometer';

import { 
  exportDataToXLSX,
  updateCategoriesState,
  updateActionState
} from './../../../helpers/barometer/table';

import { 
  exportGlobalTrend2PDF
} from './../../../helpers/barometer/charts';

/** SCSS **/
import './Barometer.scss';

/** Components **/
import Table from './../../Table/Table';
import DataViz from './../../DataViz/DataViz';
import Loader from './../../Loader/Loader';
import FilterColumns from './../../Table/FilterColumns';
import TopNavCategory from './../../NavFilters/TopNavCategory';
import TopNavPeriod from './../../NavFilters/TopNavPeriod';
import TopNavDevLoc from '../../NavFilters/TopNavDevLoc';

function Barometer( props ) 
{
  /** Get props */
  const context = props.context;

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get state from redux store **/
  const selectedFilters = useSelector( state => state.selectedFilters.value );
  const datas = useSelector( state => state.datas.value );
  const levelCategory = useSelector( state => state.levelCategory.value );
  const snippetsDetails = useSelector( state => state.datas.value.currentSnippetsDetails );
  const selectedInstance = useSelector( state => state.selectedInstance.value );
  const loadersTriggers = useSelector( state => state.loadersTriggers.value );  
  
  /** Init state **/
  const [table, setTable] = useState( null );
  const [dataViz, setDataViz] = useState( null );  
  const [paramsDownload, setParamsDownload] = useState( { filter: false } );
  const [lastSnippetsUpdateDate, setLastSnippetsUpdateDate] = useState( null );
  const [nextSnippetsUpdateDate, setNextSnippetsUpdateDate] = useState( null );
  const [firstScrapDate, setFirstScrapDate] = useState( null );  
  const [resetTrigger, setResetTrigger] = useState( false );
  
  /** Init Search Params */
  const [searchParams, setSearchParams] = useSearchParams();

  /** Define FullScreenHandle **/
  const chartFullScreenHandle = useFullScreenHandle();
  const tableFullScreenHandle = useFullScreenHandle();
  
  /** Use ref to trig click on download link**/
  const globalTrend = useRef( null );
  
  /** set callback function for each loader **/
  const callbackResultsLoader = ( result, id ) => dispatch( updateDatas( { [id]: result } ) );

  /** set callback function to dispatch result of filters nav data request **/
  const callbackFiltersLoader = ( result ) => dispatch( updateFiltersDatas( result ) );

  /** set callback function to dispatch result of categories **/
  const callbackUpdateCategories = ( result ) => 
  {
    dispatch( updateDatas( { 'globalResult': updateCategoriesState( result, datas.globalResult ) } ) )
    dispatch( updateLoadersTriggers( 'catFilter' ) );
  };

  /** Set callback function to dispatch result for mass action */
  const callbackUpdateActionState = ( result ) =>  dispatch( updateDatas( { 'globalResult': updateActionState( result, datas.globalResult ) } ) );

  /** Load and update category menu current period */  
  useEffect(() => 
  {
    if( 
      selectedFilters.currentPeriod !== undefined
      && selectedFilters.devLoc !== undefined
      && selectedInstance !== undefined
    ){
      // Categories of menu
      callWebservice(
        'global',
        'filters',
        'filters',
        dispatch,
        selectedInstance,
        {
          period: selectedFilters.currentPeriod,
          where: {
            locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
            devices: [selectedFilters.devLoc.deviceValue]
          },
          context: context
        },
        { function: 'filtersLoader' },
        'Mise à jour des catégories'
      );
    }
  }, [ 
    selectedFilters.currentPeriod, 
    selectedFilters.devLoc,
    context,
    loadersTriggers['catFilter']
  ]);

  /** Load and update detail snippets for current period **/  
  useEffect(() => 
  {
    if( 
      selectedFilters?.currentPeriod?.enddate !== undefined 
      && selectedFilters.devLoc !== undefined
      && selectedInstance !== undefined
    ){
      // Snippets details
      callWebservice(
        'global',
        'currentSnippetsDetails',
        'snippet-details',
        dispatch,
        selectedInstance,
        {
          cols: ["keyword, keyword as label", "url", "position", "typeSnippet", "grpSnippet", "who", "area"],
          period: {
            enddate: selectedFilters.currentPeriod.enddate
          },
          where: {
            locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
            devices: [selectedFilters.devLoc.deviceValue]
          },
          sort: [{
            orderby: "CAST( CONCAT( SUBSTRING_INDEX(position, '.', 2), SUBSTRING_INDEX(position, '.', -1) ) AS DECIMAL( 5,2 ) )", 
            sort: "ASC"
          }],
          levelCategory: context === 'categories' ? levelCategory : null
        },
        { 
          function: 'resultsLoader',
          params: [ 'currentSnippetsDetails' ]
        },
        'Chargement de la SERP (période d\'analyse)'
      );
    }
  }, [ 
    selectedFilters.currentPeriod, 
    selectedFilters.devLoc,
    levelCategory, 
    context
  ]);

  /** Load and update detail snippets for compare period **/  
  useEffect(() => 
  {
    if( 
      selectedFilters?.comparePeriod?.enddate !== undefined 
      && selectedFilters.devLoc !== undefined
      && selectedInstance !== undefined
    ){
      // Snippets details
      callWebservice(
        'global',
        'compareSnippetsDetails',
        'snippet-details',
        dispatch,
        selectedInstance,
        {
          cols: ["keyword, keyword as label", "url", "position", "typeSnippet", "grpSnippet", "who", "area"],
          period: {
            enddate: selectedFilters.comparePeriod.enddate
          },
          where: {
            locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
            devices: [selectedFilters.devLoc.deviceValue]
          },
          sort: [{
            orderby: "CAST( CONCAT( SUBSTRING_INDEX(position, '.', 2), SUBSTRING_INDEX(position, '.', -1) ) AS DECIMAL( 5,2 ) )", 
            sort: "ASC"
          }],
          levelCategory: context === 'categories' ? levelCategory : null
        },
        { 
          function: 'resultsLoader',
          params: [ 'compareSnippetsDetails' ]
        },
        'Chargement de la SERP (période de comparaison)'
      );

    } else {
      callbackResultsLoader( [], 'compareSnippetsDetails' );
    }
  }, [ 
    selectedFilters.comparePeriod, 
    selectedFilters.devLoc,
    levelCategory, 
    context
  ]);

  /** Load and update data table **/
  useEffect(() => 
  { 
    if( 
      selectedFilters.currentPeriod !== undefined
      && selectedFilters.comparePeriod !== undefined
      && selectedFilters.devLoc !== undefined
      && selectedInstance !== undefined
    ){
      /** Global Result **/
      callWebservice(
        'global',
        'globalResult',
        'data-table',
        dispatch,
        selectedInstance,
        {
          period: {
            currentPeriod: selectedFilters.currentPeriod,
            comparePeriod: selectedFilters.comparePeriod
          },
          where: {
            categoriesFilter: selectedFilters.categoriesFilter,
            locations: [selectedFilters.devLoc.countryValue + '|' + selectedFilters.devLoc.cityValue],
            devices: [selectedFilters.devLoc.deviceValue]
          },
          context: context,
          levelCategory: context === 'categories' ? levelCategory : null
        },
        {
          function: 'resultsLoader',
          params: [ 'globalResult' ]
        },
        'Chargement des données'
      );
    }
  }, [ 
    selectedFilters, 
    levelCategory, 
    context
  ]);

  /** Update columns filters */
  useEffect( () => 
  {
    // get search params
    const filtersParams = searchParams.get( 'filters' );

    // update filters
    if( filtersParams && snippetsDetails )
    {
      // update columns filters
      updateColumnsFilters( filtersParams, dispatch, { snippetsDetails: snippetsDetails } );        
    }

  }, [ searchParams, snippetsDetails ]);

  /** Display Table when data loaded */
  useEffect(() =>
  {
    if( datas.globalResult && resetTrigger === true )
    {
      // set table
      setTable( 
        <Table 
          datas={datas.globalResult} 
          sortField="volume" 
          sortDesc={true} 
          displayTotal={true}
          displayAddKeyword={false}
        />
      );

      // set data viz
      setDataViz(
        <DataViz 
          fullscreenhandle={chartFullScreenHandle} 
          ref={globalTrend} 
        />
      );
    }

  }, [ datas.globalResult, resetTrigger ]);

  /** Reset values if context has changed */
  useEffect( () => 
  {
    // reset datas to null if context has changed
    callbackResultsLoader( null, 'globalResult' );
    callbackResultsLoader( null, 'filterResult' );

    // reset table and dataviz component to null if context has changed
    setTable( null );
    setDataViz( null );

    // remove all columns filters
    dispatch( updateSelectedHeaderFilters( { filterName: null, value: null } ) );

    // set reset trigger to on
    setResetTrigger( true );

  }, [ context ]);

  /** Display last and next snippets update date */
  useEffect(() => 
  {
    if( datas.currentSERPTrend )
    {
      /** Set last snippets update date from datas */
      const lastScrapDate = getScrapDate( datas, 'last' );

      if( lastScrapDate !== null )
      {
        // get next month after lastdate to get last updated date
        setLastSnippetsUpdateDate(
          moment( lastScrapDate.toString() ).format( 'DD/MM/YYYY' )
        );        
      }

      /** Set first SERP scrap date */
      const firstScrapDate = getScrapDate( datas, 'first' );

      if( firstScrapDate !== null )
      {
        // get next month after lastdate to get last updated date
        setFirstScrapDate(
          moment( firstScrapDate.toString() ).format( 'DD/MM/YYYY' )
        );
      }

      /** Set next monday date for next extract date */
      setNextSnippetsUpdateDate(
        moment().day( 1 + 7 ).format( 'DD/MM/YYYY' )
      );
    }

  }, [ datas.currentSERPTrend ]);

  return (
    <React.Fragment>
      <Loader 
        loaderID="global" 
        loaderStyle={{
          width:'66', 
          stroke:'#e43e21', 
          viewBox:'-2 -2 42 42'
        }} 
        callBackFcts={{
          filtersLoader: callbackFiltersLoader,
          resultsLoader: callbackResultsLoader,
          updateActionState: callbackUpdateActionState,
          updateCategories: callbackUpdateCategories
        }}
        globalCallBack={undefined}
      />
      <Sticky className="sticky-container">
        <div className="top-docker">
          <div className="top-docker-title">
            <h2>
              { 
                context === 'categories' ?
                  'Analyse par catégories'
                : context === 'urls' ?
                  'Analyse par URL'
                : context === 'gsc-keywords' ?
                  'GSC par mot clés'
                : 'Analyse par mots-clés'
              }
            </h2>
          </div>
          <div className="top-docker-filters">
            <TopNavCategory />
            <TopNavDevLoc />
            <TopNavPeriod />
          </div>
        </div>
      </Sticky>
      <div className="dashboard-container">
        {( datas.filterResult && datas.filterResult.length > 0 ) ?
          <div className="dataviz-chart-container">
            <div className='options-chart-container'>    
              <div className='update-infos'>
                { 
                  context !== 'gsc-keywords'
                  && (
                    ( lastSnippetsUpdateDate !== null && nextSnippetsUpdateDate !== null )
                    || firstScrapDate !== null 
                  )
                ?
                  <p>
                    { firstScrapDate !== null ? 
                        <React.Fragment>
                          <span>Date début du SCRAP : </span><i>{firstScrapDate}</i>
                        </React.Fragment>
                      : null
                    }

                    { lastSnippetsUpdateDate !== null && nextSnippetsUpdateDate !== null && firstScrapDate !== null ? ' - ' : null }

                    { ( lastSnippetsUpdateDate !== null && nextSnippetsUpdateDate !== null ) ?
                        <React.Fragment>
                          <span>Dernière MAJ : </span><i>{lastSnippetsUpdateDate}</i> - <span>Prochaine MAJ : </span><i>{nextSnippetsUpdateDate}</i>
                        </React.Fragment>
                      : null
                    }
                  </p>
                  : null
                }
              </div>                
              <div className="export-options-container">
                <button className="fullscreen" type="button" onClick={chartFullScreenHandle.enter}>
                  {getPicto( 'ArrowsFullscreen', {size:"1.2rem"} )}
                </button>
                <button className="download" type="button" onClick={() => exportGlobalTrend2PDF( globalTrend )}>
                  {getPicto( 'Download', {size:"1.2em"} )}
                </button>
              </div>
            </div>
            <div className="chart-container">
              { dataViz }
            </div>
          </div>
        : false
        }
        <div className="table-container">
          <div className="export-options-container">

            {/** Column Filter **/}
            <button 
              className="column-filter" 
              type="button" 
              data-for={'column-filter'} 
              data-tip
              data-event='click'
              onClick={(event) => {
                let element = document.getElementById( event.currentTarget.getAttribute('data-for') );
                if( element !== undefined )
                  ReactTooltip.show( element );
              }}
            >
              {getPicto('Gear', {size:"1.4rem"})}
            </button>
            <FilterColumns />

            {/** Fullscreen **/}
            <button className="fullscreen" type="button" onClick={tableFullScreenHandle.enter}>
              {getPicto('ArrowsFullscreen', {size:"1.2rem"})}
            </button>

            {/** Download **/}
            {/* <button 
              className="download" 
              type="button" 
              data-for={'download-table'} 
              data-tip
              data-event='click'
              onClick={(event) => {
                let element = document.getElementById( event.currentTarget.getAttribute('data-for') );
                if( element !== undefined )
                  ReactTooltip.show( element );
              }}
            >
              {getPicto( 'Download', {size:"1.2rem"} )}
            </button>          
            <ReactTooltip 
              id={'download-table'}
              className="tooltip red preselect"
              effect='solid'
              place='bottom'
              delayHide={100}
              delayShow={100}
              delayUpdate={500}
              globalEventOff='click'
              arrowColor="transparent"
              isCapture={true}
              overridePosition={ (
                position,
                currentEvent,
                currentTarget,
                node
              ) => {
                // get width of tooltip
                let width = node.offsetWidth;
                return {
                  left: currentTarget.offsetLeft - (width / 2) - 65,
                  top: currentTarget.offsetHeight + 5
                };
              }}
            >
              <button 
                className={paramsDownload.filter===false?"all-results on":"all-results"}
                onClick={() => setParamsDownload({...paramsDownload, filter:false})}
              >Tous les résultats</button>
              <button 
                className={paramsDownload.filter===true?"filter-results on":"filter-results"}
                onClick={() => setParamsDownload({...paramsDownload, filter:true})}
              >Résultats filtrés</button>
              <hr/>
              {
                datas.globalResult ?
                  <button className="data-link-download" 
                    onClick={() => exportDataToXLSX(
                      'tableDatas.xlsx',
                      paramsDownload.filter === true ? datas.filterResult : datas.globalResult,
                      datas.currentSnippetsDetails,
                      context
                    )}>XSLX</button>
                : false
              }
            </ReactTooltip>*/}
          </div> 
          <FullScreen handle={tableFullScreenHandle}>
            {table}
          </FullScreen>
        </div>      
      </div>
    </React.Fragment>
  )
}
  
export default Barometer;