/** Dependencies **/
import React from 'react';
import { Link } from "react-router-dom";

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';

/** Helpers **/
import { handleClickToggler } from './../../helpers/ui'
import { getPicto } from './../../helpers/pictos'

/** SCSS **/
import './LeftNavMenu.scss';

function LeftNavMenu() 
{
	/** Instance dispatch object **/
	const dispatch = useDispatch();

	/** Get state from redux store **/
	const togglerStore = useSelector( state => state.togglers.value );

	return (
		<div 
			className='left-filter-wrapper' 
			onMouseEnter={() => handleClickToggler( togglerStore, 'leftNavMenuToggle', dispatch, false )} 
			onMouseLeave={() => handleClickToggler( togglerStore, 'leftNavMenuToggle', dispatch, true )}
		>
			<div className="toggler" onClick={() => handleClickToggler( togglerStore, 'leftNavMenuToggle', dispatch )}>
				{getPicto( 'BurgerMenu', {size:"2rem", viewBox:"0 0 20 20"} )}
			</div>
			<ul>
				<li>
					<Link to={process.env.PUBLIC_URL + "/"} className="text-link">Accueil</Link>
					<Link to={process.env.PUBLIC_URL + "/"} className="picto-link">{getPicto('Home', {size:"1.4rem"})}</Link>
				</li>
				<li>
					<Link to={process.env.PUBLIC_URL + "/keywords"} className="text-link">Analyse par mots-clés</Link>
					<Link to={process.env.PUBLIC_URL + "/keywords"} className="picto-link">{getPicto('MagnifyingGlass', {size:"1.4rem"})}</Link>
				</li>
				{/* <li>
					<Link to={process.env.PUBLIC_URL + "/categories"} className="text-link">Analyse par catégories</Link>
					<Link to={process.env.PUBLIC_URL + "/categories"} className="picto-link">{getPicto('ArrowTrending', {size:"1.6rem", viewBox:"0 3 22 22"})}</Link>
				</li> */}
				<li>
					<Link to={process.env.PUBLIC_URL + "/urls"} className="text-link">Analyse par urls</Link>
					<Link to={process.env.PUBLIC_URL + "/urls"} className="picto-link">{getPicto('Globe', {size:"1.3rem"})}</Link>
				</li>
				<li>
					<Link to={process.env.PUBLIC_URL + "/gsc-keywords"} className="text-link">GSC par mots-clés</Link>
					<Link to={process.env.PUBLIC_URL + "/gsc-keywords"} className="picto-link">{getPicto('DatabaseSearch', {size:"1.6rem"})}</Link>
				</li>
				<li>
					<Link to={process.env.PUBLIC_URL + "/admin-add-keywords"} className="text-link">Gestion des mots-clés</Link>
					<Link to={process.env.PUBLIC_URL + "/admin-add-keywords"} className="picto-link">{getPicto('AddToList', {size:"1.6rem"})}</Link>
				</li>
				<li>
					<Link to={process.env.PUBLIC_URL + "/admin-add-events"} className="text-link">Gestion des événements</Link>
					<Link to={process.env.PUBLIC_URL + "/admin-add-events"} className="picto-link">{getPicto('CalendarEvent', {size:"1.2rem"})}</Link>
				</li>
				<li>
					<a href="https://www.wrike.com/form/eyJhY2NvdW50SWQiOjc5NzQyMCwidGFza0Zvcm1JZCI6NjY2MDU0fQk0ODAzMjg2NjAxMDQwCTc1YmIzMDQzNDhiMGExZDJmYTFjZWFiMWIyMTE1YjIyYmFkNGQzMjVmYmFhOGMyNmM4MzYxMzhhMWNlYWI1Mjk=" target="_blank" rel="noreferrer" className="text-link">Remarques / Suggestions</a>
					<a href="https://www.wrike.com/form/eyJhY2NvdW50SWQiOjc5NzQyMCwidGFza0Zvcm1JZCI6NjY2MDU0fQk0ODAzMjg2NjAxMDQwCTc1YmIzMDQzNDhiMGExZDJmYTFjZWFiMWIyMTE1YjIyYmFkNGQzMjVmYmFhOGMyNmM4MzYxMzhhMWNlYWI1Mjk=" target="_blank" rel="noreferrer" className="picto-link">{getPicto('Question', {size:"1.8rem"})}</a>
				</li>
			</ul>
		</div>
	);
}

export default LeftNavMenu;