/** Redux **/
import { updateSelectedFilters } from './../../reducers/selectedFilters';
import { updateLevelCategory } from './../../reducers/levelCategory';
import { updateListCategoryDisp } from './../../reducers/listCategoryDisp';
import { updateSelectedHeaderFilters } from './../../reducers/selectedHeaderFilters';

/** Helpers */
import {
  isValidJson
} from './../functions';

import {
  getFilterTypeWithFilterField
} from './../table/customFilters';

/**
 * Update categories filter with new categories
 * @param {Array} links ['Activités neige|Ski|N.C.', ....]
 * @param {int} level 1 or 2 if main category or sub category
 * @param {Array} filtersDatas Array of current filters datas (categories, context, period...)
 * @param {string} redirect url to redirect
 * @param {string} dispatch dispatch object
 * @param {string} navigate navigate object
 */
export const goTo = ( 
  links, 
  level, 
  filtersDatas,
  redirect = null, 
  dispatch, 
  navigate 
) => 
{
  if( links.length > 0 )
  {
    // update list categories disp for category menu
    updateLocalListCategoryDisp( links, filtersDatas, dispatch );

    // update categories filter
    dispatch( updateSelectedFilters( { filterName: 'categoriesFilter', value: links } ) );

    // set level category
    dispatch( updateLevelCategory( level ) );

    // location to keywords section
    if( redirect !== null )
      navigate( redirect );
  }
}

/**
 * Dispatch filter on expected url with current expected url and relocate to url page
 */
export const filterOnValueToContext = ( value, accessor, toContext, navigate ) => 
{
  // apply filter on expected urls
  if( value !== null )
  {
    // set filter param
    const filterParam = {
      [accessor]: {
        [accessor + '-text']: value
      }
    };
    
    // relocate to keywords section
    navigate( process.env.PUBLIC_URL + '/' + toContext + '?filters=' + JSON.stringify( filterParam ) );
  }
}

/**
 * Update redux value for display categories in top categories filter menu
 * @param {Array} links ['Pratique|Météo|N.C.', ...]
 */
export const updateLocalListCategoryDisp = ( links, filtersDatas, dispatch ) => 
{
  if( filtersDatas.categories && Object.keys( filtersDatas.categories ).length > 0 )
  {
    const listCategoryDisp = {
      category1: [],
      category2: [],
      category3: []
    }

    // get categories 1
    let categories1 = [...new Set( links.map( link => link.split( '|' )[0] ) )];
    
    // get categories 2
    let categories2 = [...new Set( links.map( link => link.split( '|' )[0] + '|' + link.split( '|' )[1] ) )];

    // get categories 3
    let categories3 = [...new Set( links.map( link => link.split( '|' )[0] + '|' + link.split( '|' )[1] + '|' + link.split( '|' )[2] ) )];

    // set categories 1 
    listCategoryDisp.category1 = Object.keys( filtersDatas.categories ).sort( ( a, b ) => a.localeCompare( b ) ).map( ( cat1, index ) => 
      ({
        index: index,
        value: cat1,
        checked: categories1.includes( cat1 ) ? true : false,
        totalvolume: filtersDatas.categories[cat1].totalvolume,
        totalkeywords: filtersDatas.categories[cat1].totalkeywords
      }) 
    )

    // set categories 2 
    categories1.forEach( cat1 => 
      listCategoryDisp.category2 = [
        ...listCategoryDisp.category2, 
        ...Object.keys( filtersDatas.categories[cat1].cat2 ).sort( ( a, b ) => a.localeCompare( b ) ).map( ( cat2, index ) => 
        ({
          index: index,
          value: cat1 + '|' + cat2,
          checked: categories2.includes( cat1 + '|' + cat2 ) ? true : false,
          totalvolume: filtersDatas.categories[cat1].cat2[cat2].totalvolume,
          totalkeywords: filtersDatas.categories[cat1].cat2[cat2].totalkeywords
        })
      )]
    )

    // set categories 3 
    let index = 0;
    categories2.forEach( category2 => 
    {
      let cat1 = category2.split( '|' )[0];
      let cat2 = category2.split( '|' )[1];     

      listCategoryDisp.category3 = [
        ...listCategoryDisp.category3, 
        ...Object.keys( filtersDatas.categories[cat1].cat2[cat2].cat3 ).sort( ( a, b ) => a.localeCompare( b ) ).map( cat3 => 
        ({
          index: index++,
          value: cat1 + '|' + cat2 + '|' + cat3,
          checked: categories3.includes( cat1 + '|' + cat2 + '|' + cat3 ) ? true : false,
          totalvolume: filtersDatas.categories[cat1].cat2[cat2].cat3[cat3].totalvolume,
          totalkeywords: filtersDatas.categories[cat1].cat2[cat2].cat3[cat3].totalkeywords
        })
      )]
    })

    // dispatch result to update category top menu
    dispatch( updateListCategoryDisp( listCategoryDisp ) );
  }
}

/**
 * 
 * @param {Object} categoriesDatas Object with categories filters
 * @param {String} category1
 * @param {String} category2 
 * @param {String} category3 
 * @returns Array with all categories tree
 */
export const getCategories = ( 
  categoriesDatas, 
  category1, 
  category2 = null, 
  category3 = null
) =>
{
  let categories = [];

  // 3 known categories
  if( 
    typeof category1 === 'string' && category1.trim() !== ''
    && category2 !== null && typeof category2 === 'string' && category2.trim() !== ''
    && category3 !== null && typeof category3 === 'string' && category3.trim() !== ''
  )
    categories = [...categories, category1 + '|' + category2 + '|' + category3];
  
  // 2 known categories
  else if (
    typeof category1 === 'string' && category1.trim() !== ''
    && category2 !== null && typeof category2 === 'string' && category2.trim() !== ''
    && category3 === null
  )
    Object.keys( categoriesDatas[category1].cat2[category2].cat3 ).forEach( cat3 => 
      categories = [...categories, category1 + '|' + category2 + '|' + cat3]
    )
  
    // 1 known categoriy
  else if (
    typeof category1 === 'string' && category1.trim() !== ''
    && category2 === null
    && category3 === null
  )
    Object.keys( categoriesDatas[category1].cat2 ).forEach( cat2 =>
      categoriesDatas[category1].cat2[cat2] ?
        Object.keys( categoriesDatas[category1].cat2[cat2].cat3 ).forEach( cat3 => 
          categories = [...categories, category1 + '|' + cat2 + '|' + cat3]
        )
      : false
    )
  
  return categories;
}

export const updateColumnsFilters = ( params, dispatch, extraParams=null ) => 
{
  const jsonParams = isValidJson( params );

  if( jsonParams !== false )
  {
    // foreach column
    Object.keys( jsonParams ).forEach( columnName => 
    {
      // get filter type and value
      const filterField = Object.keys( jsonParams[columnName] );

      filterField.forEach( field => 
      {
        const value = jsonParams[columnName][field];
  
        dispatch( 
          updateSelectedHeaderFilters( { 
            filterName: columnName, 
            value: {
              type: 'text',
              label: value,
              option: 'exactly',
              usefor: 'filter',
              value: {
                field: field,
                type: getFilterTypeWithFilterField( field ),
                result: value
              },
              params: extraParams
            }
          }) 
        ); 
      })      
    });
  }
}