/** Dependencies **/
import React from 'react';

/** Helpers **/
import { getPicto } from './../../../helpers/pictos';
import { truncateString } from './../../../helpers/functions';

function CustomTicketLabelSnippet( props ) 
{
  return (
    <React.Fragment>
      <text 
        x={props.x - 10} 
        y={props.y}
        xHeight={15}
        fontSize={10}
        fontWeight='bold'
      >{truncateString( props.text, 3, '.' )}</text>
      {
        props.text !== 'Total' ? 
          getPicto( 
            props.text, {
              size: 14, 
              x:props.x - 7, 
              y:props.y,
              height: 20
            }
          )
          : false
      }
    </React.Fragment>
	);
}

export default CustomTicketLabelSnippet;