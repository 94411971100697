/** Components **/
import DefaultStringFilterToolTip from './../../../components/Table/FilterToolTips/DefaultStringFilterToolTip';
import TableCellKeywords from './../../../components/Table/TableCells/Keywords';
import TableCellAdminAddKeywords from './../../../components/Table/TableCells/KeywordsAdminAddKeywords';
import TableCellThematics from './../../../components/Table/TableCells/Thematics';
import TableCellUrls from './../../../components/Table/TableCells/Urls';

export const LabelColumn = ( appPathName, widthColumns ) => 
{
  return {
    accessor: "label",
    width: widthColumns['label'],
    title: 
      appPathName === 'categories' ? 
        "thématique"  
      : appPathName === 'urls' ? 
        "url"  
      : "mot clé",
    className: "col-label",
    Filter: DefaultStringFilterToolTip,
    filter: "multiFieldsCustomFilter",
    filterTitle: 
      appPathName === 'categories' ? 
        "Chercher une/des thématique(s)"
      : appPathName === 'urls' ? 
        "Chercher une/des url(s)"
      : "Chercher un/des mot(s) clé(s)" ,
    disableSortBy: true,
    Header: props => props.column.title,
    Total: "TOTAL",
    Cell: props => 
      appPathName === 'categories' ?
        <TableCellThematics 
          {...props.row.getToggleRowSelectedProps()} 
          id={props.row.id} 
          category1={props.row.original.category1} 
          category2={props.row.original.category2} 
          value={props.row.original.label}
          depth={props.row.depth}
          row={props.row}
        />
      : appPathName === 'urls' ?
        <TableCellUrls 
          {...props.row.getToggleRowSelectedProps()} 
          id={props.row.id} 
          category1={props.row.original.category1} 
          category2={props.row.original.category2} 
          value={props.row.original.label} 
        />
      : appPathName === 'admin-add-keywords' ?
        <TableCellAdminAddKeywords 
          {...props.row.getToggleRowSelectedProps()} 
          id={props.row.id} 
          category1={props.row.original.category1} 
          category2={props.row.original.category2} 
          category3={props.row.original.category3} 
          action={props.row.original.action} 
          value={props.row.original.label} 
        />
      : <TableCellKeywords 
          {...props.row.getToggleRowSelectedProps()} 
          id={props.row.id} 
          category1={props.row.original.category1} 
          category2={props.row.original.category2} 
          value={props.row.original.label} 
        />
  }
}