/** From styled-icons **/
import { Images, ArrowsFullscreen, Download, ChevronBarExpand, Twitter, ExclamationTriangleFill, Google, Gear, TrophyFill, Trash, Globe, Question, CalendarEvent } from '@styled-icons/bootstrap';
import { MoreHorizontal, MoreVertical, List } from '@styled-icons/evaicons-solid';
import { ShoppingCartOutline, ExternalLinkOutline } from '@styled-icons/evaicons-outline';
import { Location, MagnifyingGlass, GraduationCap, AddToList } from '@styled-icons/entypo';
import { VideoClip, Rocket, DatabaseSearch } from '@styled-icons/fluentui-system-regular';
import { ArrowTrending, Group, PeopleSearch } from '@styled-icons/fluentui-system-filled';
import { Sort, Stars, Hotel, Flight } from '@styled-icons/material';
import { EmojiEvents, Restaurant, KeyboardArrowDown, Computer } from '@styled-icons/material-rounded';
import { ViewCarousel, AutoStories } from '@styled-icons/material-outlined';
import { Filter, CornerLeftUp } from '@styled-icons/feather';
import { CloseCircle } from '@styled-icons/remix-fill';
import { CreditCard } from '@styled-icons/fa-regular';
import { Questionnaire, Article } from '@styled-icons/remix-line';
import { CommentAdd, MenuAltLeft, Euro, BarChartAlt, Podcast, LineChart, LogOut, HomeAlt } from '@styled-icons/boxicons-regular';
import { EventAvailable } from '@styled-icons/material-sharp';
import { MapMarker } from '@styled-icons/open-iconic';
import { Skull } from '@styled-icons/ionicons-outline';
import { CenterFocusWeak } from '@styled-icons/material-twotone';
import { Results, ArrowUp } from '@styled-icons/foundation';
import { ManWoman } from '@styled-icons/icomoon';

/** From Custom svg **/
import { GoogleViewer } from './../../src/assets/img/pictos/GoogleViewer.js';
import { ReactComponent as MoreVerticalSquare } from './../../src/assets/img/pictos/MoreVerticalSquare.svg';
import { ReactComponent as GenderNeutral } from './../../src/assets/img/pictos/GenderNeutral.svg';

/**
 * @param {string} name 
 * @returns svg picto with name
 */
 export const getPicto = ( name, options={} ) => 
 {
  let svgPicto = null;

  switch ( name ) {
    case "ArrowsFullscreen":
      svgPicto = <ArrowsFullscreen {...options} />;
      break;
    case "Download":
      svgPicto = <Download {...options} />;
      break;
    case "MoreHorizontal":
      svgPicto = <MoreHorizontal {...options} />;
      break;
    case "MoreVertical":
      svgPicto = <MoreVertical {...options} />;
      break;
    case "List":
      svgPicto = <List {...options} />;
      break;
    case "Sort":
      svgPicto = <Sort {...options} />;
      break;
    case "Filter":
      svgPicto = <Filter {...options} />;
      break;
    case "closer":
      svgPicto = <CloseCircle {...options} />;
      break;
    case "expand":
      svgPicto = <ChevronBarExpand {...options} />;        
      break;
    case "BurgerMenu":
      svgPicto = <MenuAltLeft {...options} />;
      break;
    case "ArrowTrending":
      svgPicto = <ArrowTrending {...options} />;
      break;
    case "KeyboardArrowDown":
      svgPicto = <KeyboardArrowDown {...options} />;
      break;
    case "ExclamationTriangleFill":
      svgPicto = <ExclamationTriangleFill {...options} />;
      break;    
    case "Gear":
      svgPicto = <Gear {...options} />;
      break;
    case "Results":
      svgPicto = <Results {...options} />;
      break;
    case "ArrowUp":
      svgPicto = <ArrowUp {...options} />;
      break;
    case "MagnifyingGlass":
      svgPicto = <MagnifyingGlass {...options} />;
      break;
    case "Stars":
      svgPicto = <Stars {...options} />;
      break;
    case "BarChartAlt":
      svgPicto = <BarChartAlt {...options} />;
      break;
    case "CornerLeftUp":
      svgPicto = <CornerLeftUp {...options} />;
      break;
    case "ManWoman":
      svgPicto = <ManWoman {...options} />;
      break;
    case "Computer":
      svgPicto = <Computer {...options} />;
      break;
    case "Trash":
      svgPicto = <Trash {...options} />;
      break;
    case "ExternalLinkOutline":
      svgPicto = <ExternalLinkOutline {...options} />;
      break;
    case "Home":
      svgPicto = <HomeAlt {...options} />;
      break;
    case "Globe":
      svgPicto = <Globe {...options} />;
      break;
    case "Question":
      svgPicto = <Question {...options} />;
      break;
    case "DatabaseSearch":
      svgPicto = <DatabaseSearch {...options} />;
      break;
    case "AddToList":
      svgPicto = <AddToList {...options} />;
      break;
    case "CalendarEvent":
      svgPicto = <CalendarEvent {...options} />;
      break;      

    /** Start Snippets pictos **/
    case "Google":
    case "Answer Box":
      svgPicto = <Google {...options} />;
      break;
    case "Ads":
    case "Ads - Results":
      svgPicto = <Euro {...options} />;
      break;
    case "SEO":
      svgPicto = <Rocket {...options} />;
      break;
    case "Maps":
    case "Map":
    case "Local Results":
      svgPicto = <Location {...options} />;
      break;
    case "Videos":
      svgPicto = <VideoClip {...options} />;
      break;
    case "Images":
      svgPicto = <Images {...options} />;
      break;
    case "P0":      
      svgPicto = <EmojiEvents {...options} />;
      break;
    case "Carousel":
    case "Multi Carousel":
      svgPicto = <ViewCarousel {...options} />;
      break;
    case "Knowledge Panel":
      svgPicto = <GraduationCap {...options} />;
      break;
    case "PAA":
    case "PAA Question":
    case "Questionnaire":
      svgPicto = <Questionnaire {...options} />;
      break;
    case "Related Searches":
      svgPicto = <CommentAdd {...options} />;
      break;
    case "People Also Search":
      svgPicto = <PeopleSearch {...options} />;
      break;
    case "Shopping":
      svgPicto = <ShoppingCartOutline {...options} />;
      break;
    case "Top Stories":
      svgPicto = <AutoStories {...options} />;
      break;
    case "Twitter":
      svgPicto = <Twitter {...options} {...options} />;
      break;
    case "Events":
      svgPicto = <EventAvailable {...options} />;
      break;
    case "Recipes":
    case "Recettes":
      svgPicto = <Restaurant {...options} />;
      break;
    case "Top Sights":
      svgPicto = <MapMarker {...options} />;
      break;
    case "Find Results On":
      svgPicto = <CenterFocusWeak {...options} />;
      break;
    case "Autres":
    case "Snippets":
      svgPicto = <Group {...options} />;
      break;
    case "Featured Snippet":
      svgPicto = <TrophyFill {...options} />;
      break;
    case "Hotels Results":
      svgPicto = <Hotel {...options} />;
      break;
    case "Google Flights":
      svgPicto = <Flight {...options} />;
      break;
    case "Podcasts":
      svgPicto = <Podcast {...options} />;
      break;      
    case "Stocks Box":
      svgPicto = <LineChart {...options} />;
      break;      
    case "LogOut":
      svgPicto = <LogOut {...options} />;
      break;
    case "Scholar":
      svgPicto = <Article {...options} />;
      break;      
    /** End Snippets pictos **/
    
    /** Picto Header table columns **/
    case "CreditCard":
      svgPicto = <CreditCard {...options} />;
      break;

    /** Custom **/
    case "Google Viewer":
      svgPicto = <GoogleViewer {...options} />;
      break;
    case "MoreVerticalSquare":
      svgPicto = <MoreVerticalSquare {...options} />;
      break;
    case "GenderNeutral":
      svgPicto = <GenderNeutral {...options} />;
      break;
    default:
      svgPicto = <Skull {...options} />;
      break;
  }

  return svgPicto;
}